import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import _ from "lodash";
import { GET_SYNCED_PRODUCT } from "services/marketplace.service";
import { Box, Button, Collapse, IconButton } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import Loading from "components/loading";
import Check from "@material-ui/icons/Check";

const headCells = [
  { id: "id", label: "No" },
  { id: "name", label: "Nama Produk" },
  { id: "channel", label: "Nama Toko" },
  { id: "channel_name", label: "Marketplace" },
  { id: "sku_total", label: "Jumlah Sku" },
  { id: "stok_total", label: "Jumlah Stok" },
  { id: "action", label: "Integrasi Produk" },
  {},
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  photo: {
    marginRight: 10,
    borderRadius: "50%",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
}));

const useRowStyles = makeStyles({
  root: {
    cursor: "pointer",
  },
});

function TableHeader() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const { row, index, onConnect } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  let number = index;

  const handleConnect = (event) => {
    event.stopPropagation();
    onConnect();
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        tabIndex={-1}
        className={classes.root}
        onClick={() => setOpen(!open)}
      >
        <TableCell>{number++}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.channel.shop_name}</TableCell>
        <TableCell>{row.channel.channel.toUpperCase()}</TableCell>
        <TableCell>{row.skus.length}</TableCell>
        <TableCell>
          {row.skus.reduce((acc, curr) => acc + curr.stock, 0)}
        </TableCell>
        <TableCell>
          {row.inventory ? (
            <Button
              variant="contained"
              onClick={(event) => handleConnect(event)}
              style={{ background: "#2196f3", color: "#fff" }}
            >
              <Check />
              {row.inventory.name}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => handleConnect(event)}
            >
              Hubungkan
            </Button>
          )}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nama Sku</TableCell>
                    <TableCell>Stok</TableCell>
                    <TableCell>Sku Idejualan</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.skus.map((sku) => (
                    <TableRow key={sku.id}>
                      <TableCell component="th" scope="row">
                        {sku.sku}
                      </TableCell>
                      <TableCell>{sku.stock}</TableCell>
                      <TableCell>
                        {sku.inventory ? (
                          `${sku.inventory.properties.material}, ${sku.inventory.properties.color}, ${sku.inventory.properties.size}`
                        ) : (
                          <span style={{ color: "red" }}>
                            Tidak ada sku terhubung
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function TableContent(props) {
  const classes = useStyles();
  const { search, channel, onConnectProduct, updateTable } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [meta, setMeta] = React.useState({
    total: 1,
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const cancelTokenRef = React.useRef();

  const getData = useCallback(
    _.debounce(({ rowsPerPage, page, search, channel, getData }) => {
      if (cancelTokenRef.current) cancelTokenRef.current.cancel("canceled");

      const cancelToken = axios.CancelToken.source();
      cancelTokenRef.current = cancelToken;

      GET_SYNCED_PRODUCT(
        {
          per_page: rowsPerPage,
          page: page + 1,
          name: search,
          channel,
        },
        {
          cancelToken: cancelToken.token,
        }
      )
        .then(({ data, total, from }) => {
          setRows(data);
          setMeta({ total, from });
          setIsLoading(false);
          cancelTokenRef.current = null;
        })
        .catch((err) => {});
    }, 500),
    []
  );

  React.useEffect(() => {
    setPage(0);
  }, [search, channel]);

  React.useEffect(() => {
    setIsLoading(true);
    getData({ rowsPerPage, page, search, channel, getData });
  }, [rowsPerPage, page, search, getData, channel, updateTable]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHeader />

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell align="center" colSpan={headCells.length}>
                  <Loading style={{ height: 400 }} />
                </TableCell>
              </TableRow>
            ) : rows.length > 0 ? (
              rows.map((row, index) => (
                <Row
                  key={row.id}
                  index={index + meta.from}
                  row={row}
                  onConnect={() => onConnectProduct(row)}
                />
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={headCells.length}>
                  <Box
                    display="flex"
                    height={400}
                    justifyContent="center"
                    alignItems="center"
                  >
                    Tidak ada data
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={meta.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
