import React, { Fragment, useState, useEffect } from "react";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { ReactComponent as PesananIcon } from "../../../../assets/icons/shopping-bag.svg";
import { ReactComponent as PemasukanIcon } from "../../../../assets/icons/money-income.svg";
import { ReactComponent as TruckIcon } from "../../../../assets/icons/truck.svg";
import { ReactComponent as ProductIcon } from "../../../../assets/icons/product.svg";
import { getReportSummary } from "../../../../services/axios";
import CurrencyFormat from 'react-currency-format';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import { GET_REPORTS_SUMMARY_FILTER } from "services/reports.service";

const SummaryPenjualan = withStyles(styles)((props) => {
  const { classes } = props;
  const [reportSummary, setReportSummary] = useState({})
  const [isOpenDate, setIsOpenDate] = useState(false)
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      key: 'selection'
    }
  ])

  const onApplyFilter = async () => {
    setIsOpenDate(!isOpenDate);
    const report = await GET_REPORTS_SUMMARY_FILTER(
      moment(selectionRange[0].startDate).format("YYYY-MM-DD"),
      moment(selectionRange[0].endDate).format("YYYY-MM-DD")
    );
    setReportSummary(report);
  };

  useEffect(() => {
    const fetch = async () => {
      const report = await getReportSummary();
      setReportSummary(report)
    };
    fetch();
  }, []);

  const data = [
    {
      icon: <PesananIcon />,
      text: "Pesanan",
      count: reportSummary.order_count ? reportSummary.order_count:0 ,
    },
    {
      icon: <PemasukanIcon />,
      text: "Pemasukan",
      count: reportSummary.supplier_income_idr ? reportSummary.supplier_income_idr : 0,
    },
    {
      icon: <TruckIcon />,
      text: "Ongkir",
      count: reportSummary.supplier_shipping_idr ? reportSummary.supplier_shipping_idr : 0 ,
    },
    {
      icon: <ProductIcon />,
      text: "Produk",
      count: reportSummary.product_count ?reportSummary.product_count :0 ,
    }
  ]
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <div className={classes.title} style={{float: 'left'}}>
          <b>Summary Penjualan</b>
          </div>
          <div style={{position: 'relative', top: 22, left: 16}}>
            <Button
            className={classes.filter}
            onClick={() => setIsOpenDate(!isOpenDate)}
          >
            <Typography>Filter</Typography>
            <ArrowDropDownIcon />
          </Button></div>
        </Grid>
        <Grid item sm={12}>
        {
          isOpenDate && (
            <div className={classes.calendar}>
              <DateRangePicker
                onChange={item => setSelectionRange([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={selectionRange}
                direction="vertical"
              />
              <Button
            className={classes.filterApply}
            onClick={() => onApplyFilter()}
          >
            <Typography>Apply</Typography>
          </Button>
            </div>
          )
        }
        </Grid>
      </Grid>
      <Grid container spacing={3}>
      {data.map((res, i)=> (
          <Grid item sm={12} lg={3} xl={3} key={i}>
            <Paper className={classes.wrapper}>
              <div className={classes.bgIcon}>{res.icon}</div>
              <div style={{ paddingLeft: 16 }}>
                <Typography variant="caption" className={classes.caption}>{res.text}</Typography>
                <Typography variant="h5"><CurrencyFormat value={res.count} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></Typography>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
});

export default SummaryPenjualan;
