const style = (theme) => ({
  containerContent: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    height: "100vh",
  },
  textField: {
    width: "100%",
    marginBottom: 20,
  },
  inputGroup: {
    height: "100vh",
    padding: "10px 20px",
    overflow: "auto",
    width: "100%",
  },
  checkboxRememberMe: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  forgotPassword: {
    justifyContent: "flex-end",
    display: "flex",
  },
  loginButton: {
    marginBottom: 20,
    fontWeight: "bold",
    backgroundColor: theme.palette.yellow.primary,
    textTransform: "capitalize",
  },
  nextButton: {
    marginBottom: 20,
    fontWeight: "bold",
    backgroundColor: theme.palette.green.main,
    textTransform: "capitalize",
  },
  backButton: {
    marginBottom: 20,
    fontWeight: "bold",
    backgroundColor: theme.palette.red.main,
    textTransform: "capitalize",
  },
  icon: {
    height: 20,
  },
  haventAccount: {
    textAlign: "center",
    color: "#999999",
    marginBottom: 15,
  },
  grid: {
    display: "flex",
    justifyContent: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  container: {
    height: "100vh",
    overflow: "hidden",
  },
  containerImg: {
    height: "100vh",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background:
      "linear-gradient(90deg, rgba(13,106,255,0) 0%, rgba(241,91,204,0) 51%, rgba(247,210,221,1) 58%)",
  },
  vector: {
    height: 300,
    position: "absolute",
    marginLeft: 130,
  },
  inputLabel: {
    paddingLeft: 14,
  },
  avatar: {
    border: `1px solid ${theme.palette.green.main}`,
    height: 180,
    width: 180,
    borderRadius: 5,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    marginBottom: 20,
    position: "relative",
  },
  buttonAvatar: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "flex-end",
  },
  buttonAvatarText: {
    paddingBottom: 5,
    width: "100%",
    textAlign: "center",
    backgroundColor: "#afafaf5c",
    cursor: "pointer",
    position: "relative",
  },
  inputAvatar: {
    opacity: 0.0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  logo: {
    position: "absolute",
    marginLeft: 55,
    marginTop: 22,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      margin: 10,
    },
  },
});

export default style;
