import styled from "styled-components"

export const WrapEditRekening = styled.div`
  position: relative;
 
  p{margin:0;padding:0}
  p{
    font-size: 20px;
    color: #8C8484;
  }
  input, select{
    width: fill;
    width: -webkit-fill-available;
    background: #F7F7F7;
    padding: 12px 19px;
    font-size: 20px;
    color: #000;
    border: none;
    border-radius: 10px;
    outline: none;
    :focus {
      background: #FFF8E5;
      border: 1px solid #FDCA40;
    }
  }
  .MuiGrid-container {
    align-items: center;
  }
  button {
    background: #FDCA40;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    padding: 16px 95px;
    border: none;
    outline: none;
    margin-top: 56px;
  }

  .title {
    margin-bottom: 24px;
    h1 {
      color: #050505;
      font-size: 20px;
    }
  }

  .boxForm {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 24px;
  }
`