import React from "react";
import { useLocation, useState } from "react-router-dom";

import {
  Grid,
  Typography,
  TextField,
  Backdrop,
  IconButton,
  Button,
  Box,
  Hidden,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  Select, 
  FormControl,
  InputLabel,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import style from "./style";
import { withStyles } from "@material-ui/core/styles";
import Brandlogo from "../../assets/images/Brand.svg";
import IlustrationDesktop from "../../assets/images/ilustrasi.svg";
import BackgoundDesktop from "../../assets/images/background.svg";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import swal from "sweetalert";
import { signupSupplier } from "../../services/axios";
import {
  getProvinces,
  getCitiesByProvinceId,
  getDistrictsByCityId,
  getSubDistrictsByDisctrictId,
} from "../../services/axios";

const steps = ['Informasi Dasar', 'Foto-foto', 'Sosial Media'];

const RegisterPage = withStyles(style)((props) => {
  const { classes } = props;

  const search = useLocation().search;

  const [isLoading, setIsLoading] = React.useState(false);
  const [payload, setPayload] = React.useState({
    affiliate_code: new URLSearchParams(search).get("ref"),
    product_examples: [""],
    brand_name: "",
    fullname: "",
    email: "",
    password: "",
    description: "",
    province_id: "",
    city_id: "",
    district_id: "",
    village_id: "",
    address: "",
    whatsapp: "",
    facebook: "",
    instagram: "",
    tiktok: "",
    shopee: "",
    tokopedia: "",
    lazada: "",
    website: "",
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [provinces, setProvinces] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [districts, setDisctricts] = React.useState([]);
  const [subDistricts, setSubDistricts] = React.useState([]);

  const handleNext = () => {
    let newSkipped = skipped;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      payload.affiliate_code &&
        formData.append("affiliate_code", payload.affiliate_code);

      payload.product_examples.forEach((product) => {
        if (product) {
          formData.append("product_examples[]", product);
        }
      });

      if(payload.avatar) {
        formData.append("avatar", payload.avatar);
      }
      payload.brand_name && formData.append("brand_name", payload.brand_name);
      payload.fullname && formData.append("fullname", payload.fullname);
      payload.email && formData.append("email", payload.email);
      payload.password && formData.append("password", payload.password);
      payload.description && formData.append("description", payload.description);
      payload.province_id && formData.append("province_id", payload.province_id);
      payload.city_id && formData.append("city_id", payload.city_id);
      payload.district_id && formData.append("district_id", payload.district_id);
      payload.village_id &&
        formData.append("village_id", payload.village_id);
      payload.address && formData.append("address", payload.address);
      payload.postal_code && formData.append("postal_code", payload.postal_code);
      payload.whatsapp && formData.append("whatsapp", payload.whatsapp);
      payload.facebook && formData.append("facebook", payload.facebook);
      payload.instagram && formData.append("instagram", payload.instagram);
      payload.tiktok && formData.append("tiktok", payload.tiktok);
      payload.shopee && formData.append("shopee", payload.shopee);
      payload.tokopedia && formData.append("tokopedia", payload.tokopedia);
      payload.lazada && formData.append("lazada", payload.lazada);
      payload.website && formData.append("website", payload.website);

      await signupSupplier(formData);
      swal(
        "Success",
        "Selamat, Data berhasil diunggah, silahkan tunggu 1 - 7 hari. Admin akan menghubungi kamu",
        "success"
      );
      handleResetState();
    } catch (error) {
      if (error.response && error.response.data) {
        return swal("Oops!", error.response.data.message, "warning");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetState = () => {
    setPayload({
      affiliate_code: new URLSearchParams(search).get("ref"),
      product_examples: [""],
      brand_name: "",
      fullname: "",
      email: "",
      password: "",
      description: "",
      province_id: "",
      city_id: "",
      district_id: "",
      village_id: "",
      address: "",
      postal_code: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
      tiktok: "",
      shopee: "",
      tokopedia: "",
      lazada: "",
      website: "",
    });
  };

  const handleChangePayload = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const handleChangeSupplierAvatar = async (event) => {
    if (
      event.target &&
      event.target.files.length &&
      (event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/png")
    ) {
      setPayload({ ...payload, avatar: event.target.files[0]});
    } else
      swal("Oops!", "Gunakan File dengan exstensi JPG/JPEG/PNG", "warning");
  };

  const handleChangeProductExamples = async (event, i) => {
    if (
      event.target &&
      event.target.files.length &&
      (event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/png")
    ) {
      const newProductPayload = [...payload.product_examples];
      newProductPayload[i] = event.target.files[0];
      setPayload({ ...payload, product_examples: newProductPayload });
    } else
      swal("Oops!", "Gunakan File dengan exstensi JPG/JPEG/PNG", "warning");
  };

  const handleAddProductExamples = () => {
    const newProductPayload = [...payload.product_examples];
    newProductPayload.push("");
    setPayload({ ...payload, product_examples: newProductPayload });
  };

  const handleRemoveProductExamples = (index) => {
    const newProductPayload = [...payload.product_examples];
    newProductPayload.splice(index, 1);
    setPayload({ ...payload, product_examples: newProductPayload });
  };

  React.useEffect(() => {
    handleGetProvinces();

    if (payload.city_id) {
      handleGetCities(payload.province_id);
    }

    if (payload.district_id) {
      handleGetDistricts(payload.city_id);
    }

    if (payload.village_id) {
      handleGetSubDistricts(payload.district_id);
    }
  }, []);

  const handleChangeProvince = (e) => {
    setPayload({
      ...payload,
      province_id: e.target.value,
      city_id: null,
      district_id: null,
      village_id: null,
      postal_code: null,
    });
    setDisctricts([]);
    setSubDistricts([]);
    handleGetCities(e.target.value);
  };

  const handleChangeCity = (e) => {
    setPayload({
      ...payload,
      city_id: e.target.value,
      district_id: null,
      village_id: null,
      postal_code: null,
    });
    setSubDistricts([]);
    handleGetDistricts(e.target.value);
  };

  const handleChangeDistrict = (e) => {
    setPayload({
      ...payload,
      district_id: e.target.value,
      village_id: null,
      postal_code: null,
    });
    handleGetSubDistricts(e.target.value);
  };

  const handleChangeSubDistrict = (e) => {
    const value = e.target.value;

    const matchedSubDistrict = subDistricts.find((sub) => sub.id === value);

    setPayload({
      ...payload,
      village_id: value,
      // postal_code: matchedSubDistrict ? matchedSubDistrict.kodepos : null,
    });
  };

  const handleGetProvinces = async () => {
    const response = await getProvinces();

    if (response) {
      setProvinces(response.data);
    }
  };

  const handleGetCities = async (id) => {
    const response = await getCitiesByProvinceId(id);

    if (response) {
      setCities(response.data);
    }
  };

  const handleGetDistricts = async (id) => {
    const response = await getDistrictsByCityId(id);

    if (response) {
      setDisctricts(response.data);
    }
  };

  const handleGetSubDistricts = async (id) => {
    const response = await getSubDistrictsByDisctrictId(id);

    if (response) {
      setSubDistricts(response.data);
    }
  };

  return (
    <div className={classes.container}>
      <Hidden smDown>
        <img src={Brandlogo} alt="idejualan" className={classes.logo} />
      </Hidden>

      <Grid container>
        <Hidden smDown>
          <Grid item xs={6}>
            <div className={classes.containerImg}>
              <img
                src={BackgoundDesktop}
                alt="idejualan"
                style={{ height: "100%" }}
              />
              <img src={IlustrationDesktop} alt="" className={classes.vector} />
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={6}>
          <div className={classes.containerContent}>
            <div className={classes.inputGroup}>
              <Hidden mdUp>
                <img src={Brandlogo} alt="idejualan" className={classes.logo} />
              </Hidden>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", marginBottom: 20 }}
              >
                Daftar Jadi Supplier
              </Typography>

              {/* Stepper */}
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                  <React.Fragment>
                    {activeStep === 0 && (
                      <div className={classes.containerForm}>
                         <TextField
                            className={classes.textField}
                            label="Nama Brand"
                            value={payload.brand_name}
                            variant="outlined"
                            name="brand_name"
                            onChange={handleChangePayload}
                          />
                          <TextField
                            className={classes.textField}
                            label="Nama Lengkap"
                            value={payload.fullname}
                            variant="outlined"
                            name="fullname"
                            onChange={handleChangePayload}
                          />
                          <TextField
                            className={classes.textField}
                            label="Email"
                            value={payload.email}
                            variant="outlined"
                            name="email"
                            onChange={handleChangePayload}
                          />
                          <TextField
                            className={classes.textField}
                            label="Password"
                            value={payload.password}
                            variant="outlined"
                            name="password"
                            type="password"
                            onChange={handleChangePayload}
                          />
                          <TextField
                            className={classes.textField}
                            label="Kode Referral (Optional)"
                            value={payload.affiliate_code}
                            variant="outlined"
                            name="affiliate_code"
                            onChange={handleChangePayload}
                          />
                          <TextField
                            className={classes.textField}
                            label="Deskripsi"
                            value={payload.description}
                            variant="outlined"
                            name="description"
                            onChange={handleChangePayload}
                          />
                          <FormControl className={classes.textField} >
                            <InputLabel id="demo0"
                              className={classes.inputLabel}
                              >Provinsi</InputLabel>
                            <Select
                              fullWidth
                              variant="outlined"
                              value={payload.province_id}
                              onChange={handleChangeProvince}
                              label="Pilih provinsi"
                            >
                              <MenuItem disabled value={null}>
                                --Pilih provinsi--
                              </MenuItem>
                              {provinces.map((province) => (
                                <MenuItem key={province.id} value={province.id}>
                                  {province.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl className={classes.textField} >
                            <InputLabel id="demo1"
                              className={classes.inputLabel}
                              >Kota</InputLabel>
                              <Select
                                fullWidth
                                variant="outlined"
                                value={payload.city_id}
                                onChange={handleChangeCity}
                                label="Pilih kota / kabupaten"
                              >
                            <MenuItem disabled value={null}>
                              --Pilih kota / kabupaten--
                            </MenuItem>
                            {cities.map((city) => (
                              <MenuItem key={city.id} value={city.id}>
                                {city.name}
                              </MenuItem>
                            ))}
                          </Select>
                          </FormControl>
                          <FormControl className={classes.textField} >
                            <InputLabel id="demo1"
                              className={classes.inputLabel}
                              >Kecamatan</InputLabel>
                              <Select
                            fullWidth
                            variant="outlined"
                            value={payload.district_id}
                            onChange={handleChangeDistrict}
                            label="Pilih kecamatan"
                          >
                            <MenuItem disabled value={null}>
                              --Pilih kecamatan--
                            </MenuItem>
                            {districts.map((district) => (
                              <MenuItem key={district.id} value={district.id}>
                                {district.name}
                              </MenuItem>
                            ))}
                          </Select>
                          </FormControl>
                          <FormControl className={classes.textField} >
                            <InputLabel id="demo2"
                              className={classes.inputLabel}
                              >Kelurahan</InputLabel>
                            <Select
                            fullWidth
                            variant="outlined"
                            value={payload.village_id}
                            onChange={handleChangeSubDistrict}
                            label="Pilih kelurahan"
                          >
                            <MenuItem disabled value={null}>
                              --Pilih kelurahan--
                            </MenuItem>
                            {subDistricts.map((subDistrict) => (
                              <MenuItem key={subDistrict.id} value={subDistrict.id}>
                                {subDistrict.name}
                              </MenuItem>
                            ))}
                          </Select>
                          </FormControl>
                          <TextField
                            className={classes.textField}
                            label="Alamat Lengkap"
                            value={payload.address}
                            variant="outlined"
                            name="address"
                            onChange={handleChangePayload}
                          />
                          <TextField
                            className={classes.textField}
                            label="Kode Pos"
                            value={payload.postal_code}
                            variant="outlined"
                            name="postal_code"
                            onChange={handleChangePayload}
                          />
                      </div>
                    )}
                    {activeStep === 1 && (
                      <div>  
                        <Box my={2}>
                          <Box mb={2}>
                            <Typography>Foto Profil Supplier</Typography>
                          </Box>
                          <Grid container spacing={2} alignItems="center">

                              <Grid item>
                                <div
                                  className={classes.avatar}
                                  style={{
                                    backgroundImage: `url(${payload.avatar ? URL.createObjectURL(payload.avatar) : ""
                                      })`,
                                  }}
                                >
                                  <div className={classes.buttonAvatar}>
                                    <Typography className={classes.buttonAvatarText}>
                                      Upload foto profil
                                      <input
                                        type="file"
                                        className={classes.inputAvatar}
                                        onChange={(event) =>
                                          handleChangeSupplierAvatar(event)
                                        }
                                      />
                                    </Typography>
                                  </div>
                                </div>
                              </Grid>

                          </Grid>
                                  </Box>
                        <Box my={2}>
                          <Box mb={2}>
                            <Typography>Foto Produk</Typography>
                          </Box>
                          <Grid container spacing={2} alignItems="center">
                            {payload.product_examples.map((product, i) => (
                              <Grid item key={i}>
                                <div
                                  className={classes.avatar}
                                  style={{
                                    backgroundImage: `url(${product ? URL.createObjectURL(product) : ""
                                      })`,
                                  }}
                                >
                                  <div className={classes.buttonAvatar}>
                                    <Typography className={classes.buttonAvatarText}>
                                      Upload foto produk
                                      <input
                                        type="file"
                                        className={classes.inputAvatar}
                                        onChange={(event) =>
                                          handleChangeProductExamples(event, i)
                                        }
                                      />
                                    </Typography>
                                    {i > 0 && (
                                      <IconButton
                                        onClick={() => handleRemoveProductExamples(i)}
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          right: 0,
                                          padding: 0,
                                          background: "red",
                                        }}
                                      >
                                        <HighlightOffIcon style={{ color: "#fff" }} />
                                      </IconButton>
                                    )}
                                  </div>
                                </div>
                              </Grid>
                            ))}
                            <Grid item>
                              <IconButton onClick={handleAddProductExamples}>
                                <AddCircleIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    )}
                    {activeStep === 2 && (
                      <div>
                        <TextField
                          className={classes.textField}
                          label="No Whatsapp (Ex: 08xxxx)"
                          value={payload.whatsapp}
                          variant="outlined"
                          name="whatsapp"
                          onChange={handleChangePayload}
                        />
                        <TextField
                          className={classes.textField}
                          label="Link Facebook (Optional)"
                          value={payload.facebook}
                          variant="outlined"
                          name="facebook"
                          onChange={handleChangePayload}
                        />
                        <TextField
                          className={classes.textField}
                          label="Link Instagram (Optional)"
                          value={payload.instagram}
                          variant="outlined"
                          name="instagram"
                          onChange={handleChangePayload}
                        />
                        <TextField
                          className={classes.textField}
                          label="Link TikTok (Optional)"
                          value={payload.tiktok}
                          variant="outlined"
                          name="tiktok"
                          onChange={handleChangePayload}
                        />
                        <TextField
                          className={classes.textField}
                          label="Link Shopee (Optional)"
                          value={payload.shopee}
                          variant="outlined"
                          name="shopee"
                          onChange={handleChangePayload}
                        />
                        <TextField
                          className={classes.textField}
                          label="Link Tokopedia (Optional)"
                          value={payload.tokopedia}
                          variant="outlined"
                          name="tokopedia"
                          onChange={handleChangePayload}
                        />
                        <TextField
                          className={classes.textField}
                          label="Link Lazada (Optional)"
                          value={payload.lazada}
                          variant="outlined"
                          name="lazada"
                          onChange={handleChangePayload}
                        />
                        <TextField
                          className={classes.textField}
                          label="Link website (Optional)"
                          value={payload.website}
                          variant="outlined"
                          name="website"
                          onChange={handleChangePayload}
                        />
                      </div>
                    )}
                    <Box sx={{ display: 'flex', pt: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                      <Button
                        fullWidth
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        className={classes.backButton}
                      >
                        Sebelumnya
                      </Button>
                      </Grid>
                      <Grid item xs={6}>

                      <Button 
                        fullWidth
                        className={classes.nextButton}
                        onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}>
                        {activeStep === steps.length - 1 ? 'Daftar' : 'Selanjutnya'}
                      </Button>
                      </Grid>
                    </Grid>
                    </Box>
                  </React.Fragment>
                {/* )} */}
              </Box>
              {/* End of Stepper */}

              <Backdrop open={isLoading} className={classes.backdrop}>
                <CircularProgress color="primary" />
              </Backdrop>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
});

export default RegisterPage;
