const style = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    border: `1px solid ${theme.palette.green.main}`,
    height: 175,
    width: 280,
    borderRadius: 5,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  buttonLogo: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "flex-end",
  },
  buttonLogoText: {
    paddingBottom: 5,
    width: "100%",
    textAlign: "center",
    backgroundColor: "#afafaf5c",
    color: theme.palette.yellow.primary,
    cursor: 'pointer',
    position: 'relative'
  },
  inputStyle: {
    border: `1px solid ${theme.palette.green.main}`,
    borderRadius: 30,
    paddingLeft: 10,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexCenterHorizontal: {
    display: "flex",
    justifyContent: "center",
  },
  buttonGreen: {
    backgroundColor: theme.palette.green.main,
    color: '#ffffff',
    textTransform: 'capitalize',
    padding: '6px 40px',
    '&:hover': {
      backgroundColor: '#42b382',
    }
  },
  inputGambar: {
    opacity: 0.0,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
});

export default style;
