import React, { Fragment, useState } from "react";
import {
  Paper,
  Input,
  Typography,
  Button,
  Switch,
  TextField,
  Fade,
  MenuItem,
  Fab,
  Menu,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Backdrop,
  Select
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import CardOrder from "./components/CardOrder";
import Pagination from "@material-ui/lab/Pagination";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import GetAppIcon from "@material-ui/icons/GetApp";
import { getAllOrders, exportOrderXLS, getAllCourier, updateOrder } from "../../services/axios";
import swal from "sweetalert"

const Content = withStyles(style)((props) => {
  const { classes, handleUbahPesanan, order, filteredOrder } = props;
  const slug = JSON.parse(localStorage.getItem("DATA")).slug;
  const DateNow = `${new Date().getFullYear()}-${
    "0" + (new Date().getMonth() + 1)
  }-${"0" + new Date().getDate()}`;
  const [filter, setFilter] = useState({
    open: false,
    cod: 0,
    not_cod: 0,
    waiting: 0,
    processes: 0,
    delivery: 0,
    done: 0,
    canceled: 0,
    forwarded: 0,
    date_start: DateNow,
    date_end: "",
    courier_id: "",
    page: 1,
    search: "",
    loadingSearch: false,
    delivered: 0,
  });
  const [dataOrder, setDataOrder] = useState({
    order: order,
    filteredOrder: filteredOrder,
  });
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [currentFilterIndex, setCurrentFilterIndex] = useState(0);
  const filterList = [
    { key: "order_id", name: "Order ID" },
    { key: "reseller_name", name: "Pemesan" },
    { key: "customer_name", name: "Customer" },
    { key: "resi", name: "Resi" },
  ];
  const [downloadQueue, setDownloadQueue] = useState([]);
  const [selectAllLabel, setSelectAllLabel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataCouries, setdataCouries] = useState()

  React.useEffect(() => {
    getAllCourier().then((res) => setdataCouries(res.data))
  }, []);

  const handleToggleAllLabel = () => {
    setSelectAllLabel((value) => {
      if (value === false) {
        dataOrder.filteredOrder.forEach((item) => {
          if (item.is_label_downloaded === 0) {
            toggleDownloadQueue(item.id);
          }
        });
      } else {
        setDownloadQueue([]);
      }
      return !value;
    });
  };

  const handleDownloadAllLabel = () => {
    let list = []
    dataOrder.filteredOrder.forEach((item) => {
      list = [...list];
        if (list.indexOf(item.id) === -1) {
          list.push(item.id);
        } else {
          list.splice(list.indexOf(item.id), 1);
        }
    });
    setTimeout(() => {
      downloadMultipleLabel(list);
    }, 500);
};

  const toggleDownloadQueue = (id) => {
    setDownloadQueue((value) => {
      const list = [...value];
      if (list.indexOf(id) === -1) {
        list.push(id);
      } else {
        list.splice(list.indexOf(id), 1);
      }
      return list;
    });
  };

  const prosesAllPesanan = async (filteredOrder) => {
    const slug = JSON.parse(localStorage.getItem("DATA")).slug;
    swal({
      title: "Apakah kamu yakin memproses semua pesanan di halaman ini?",
      text: "Merubah status pesanan dari 'Forwarded' menjadi 'Processed'",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        try {
        filteredOrder
          .filter((item) => item.status_history[item.status_history.length - 1].status === 20)
          .map((item) => {
            const formData = new FormData();
            formData.append("_method", "PATCH");
            formData.append("is_processed", 1);
            updateOrder(slug, item.id, formData);
          });
            swal("Success!", "Pesanan berhasil diupdate!", "success").then(handleGunakanFilter)
          } catch (error) {
            if (error.response && error.response.data) {
              swal("Oops!", error.response.data.message, "warning")
            }
          }
      }
    });
  }

  const handleChangeFilter = (event, index) => {
    setAnchorElFilter(null);
    setCurrentFilterIndex(index);
  };

  const handleResetFilter = async () => {
    const response = await getAllOrders(slug);
    setDataOrder({
      ...dataOrder,
      order: response,
      filteredOrder: response.data,
    });
  };

  const handleFilter = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };

  const handleChangePage = async (e, value) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setFilter({ ...filter, page: value });
    const response = await getAllOrders(slug, `?page=${value}`);
    setDataOrder({
      ...dataOrder,
      order: response,
      filteredOrder: response.data,
    });
  };

  const handleGunakanFilter = () => {
    const fetch = async () => {
      const response = await getAllOrders(
        slug,
        `?page=${
          filter.page +
          (filter.waiting !== 0 ? "&filter[waiting]=" + filter.waiting : "") +
          (filter.processes !== 0
            ? "&filter[processed]=" + filter.processes
            : "") +
          (filter.delivery !== 0
            ? "&filter[delivery]=" + filter.delivery
            : "") +
          (filter.done !== 0 ? "&filter[done]=" + filter.done : "") +
          (filter.forwarded !== 0
            ? "&filter[forwarded]=" + filter.forwarded
            : "") +
          (filter.canceled !== 0
            ? "&filter[canceled]=" + filter.canceled
            : "") +
          (filter.date_end.length !== 0
            ? "&filter[date_between]=" +
              filter.date_start +
              "," +
              filter.date_end
            : "") +
          (filter.cod !== 0
            ? "&filter[is_cod]=" + filter.cod
            : "") +
          (filter.not_cod !== 0
            ? "&filter[not_cod]=" + filter.not_cod
            : "") +
          (filter.courier_id !== 0
            ? "&filter[courier_id]=" + filter.courier_id
            : "") +
          (filter.delivered !== 0
            ? "&filter[delivered]=" + filter.delivered
            : "")
        }`
      );
      setDataOrder({
        ...dataOrder,
        order: response,
        filteredOrder: response.data,
      });
    };
    fetch();
  };

  const handleSearch = (keyword) => {
    setFilter({ ...filter, search: keyword });
  };

  const handleCari = async () => {
    const currentFilter = filterList[currentFilterIndex].key;
    setFilter({ ...filter, page: 1, loadingSearch: true });
    const response = await getAllOrders(
      slug,
      `?page=${
        filter.page +
        (filter.waiting !== 0 ? "&filter[waiting]=" + filter.waiting : "") +
        (filter.processes !== 0
          ? "&filter[processed]=" + filter.processes
          : "") +
        (filter.delivery !== 0 ? "&filter[delivery]=" + filter.delivery : "") +
        (filter.done !== 0 ? "&filter[done]=" + filter.done : "") +
        (filter.canceled !== 0 ? "&filter[canceled]=" + filter.canceled : "") +
        (filter.date_end.length !== 0
          ? "&filter[date_between]=" + filter.date_start + "," + filter.date_end
          : "") +
        (filter.cod !== 0
          ? "&filter[is_cod]=" + filter.cod
          : "") +
        (filter.not_cod !== 0
          ? "&filter[not_cod]=" + filter.not_cod
          : "") +
        (filter.courier_id !== 0
          ? "&filter[courier_id]=" + filter.courier_id
          : "") +
        (filter.delivered !== 0
          ? "&filter[delivered]=" + filter.delivered
          : "")
      }&filter[${currentFilter}]=${filter.search}`
    );
    setDataOrder({
      ...dataOrder,
      order: response,
      filteredOrder: response.data,
      loadingSearch: false,
    });
  };

  const downloadMultipleLabel = async (listDownload = null) => {
    setIsLoading(true);
    let url = "https://api.idejualan.com/printlabel/preview/"
    if(listDownload == null) {
      url = url + downloadQueue.join(",");
    } else {
      url = url + listDownload.join(",");
    }
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", `_blank`);
    document.body.appendChild(link);
    link.click();

    // Update order
    setDataOrder((order) => {
      return {
        ...order,
        filteredOrder: order.filteredOrder.map((item) => {
          if (downloadQueue.indexOf(item.id) !== -1) {
            item = { ...item, is_label_downloaded: 1 };
          }
          return item;
        }),
      };
    });
    setIsLoading(false);
  };

  const handleExportExcel = async () => {
    const res = await exportOrderXLS({
      "filter[waiting_payment]": filter.waiting,
      "filter[forwarded]": filter.forwarded,
      "filter[processed]": filter.processes,
      "filter[delivery]": filter.delivery,
      "filter[done]": filter.done,
      "filter[canceled]": filter.canceled,
      "filter[date_between]":
        filter.date_end.length !== 0
          ? "&filter[date_between]=" + filter.date_start + "," + filter.date_end
          : "",
      "filter[courier_id]": filter.courier_id,
      "filter[customer_name]": currentFilterIndex === 2 ? filter.search : "",
      "filter[reseller_name]": currentFilterIndex === 1 ? filter.search : "",
      "filter[order_id]": currentFilterIndex === 0 ? filter.search : "",
      "filter[is_cod]": currentFilterIndex === 0 ? filter.cod : "",
      "filter[not_cod]": currentFilterIndex === 0 ? filter.not_cod : "",
      "filter[delivered]": currentFilterIndex === 0 ? filter.delivered : "",
    });

    const url = window.URL.createObjectURL(
      new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Excel Order`);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Fragment>
      <Paper style={{ padding: 10 }}>
        <div style={{ display: "flex" }}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event) => setAnchorElFilter(event.currentTarget)}
          >
            {filterList[currentFilterIndex].name}
            <ArrowDropDownIcon />
          </Button>
          <Menu
            id="anchorFilter"
            anchorEl={anchorElFilter}
            keepMounted
            open={Boolean(anchorElFilter)}
            onClose={() => setAnchorElFilter(null)}
          >
            {filterList.map((option, index) => (
              <MenuItem
                key={index}
                onClick={(event) => handleChangeFilter(event, index)}
              >
                {filterList[index].name}
              </MenuItem>
            ))}
          </Menu>
          <div className={classes.searchWrapper}>
            <SearchIcon className={classes.SearchIcon} />
            <Input
              disableUnderline={true}
              placeholder="Pencarian..."
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleCari()}
            style={{ borderRadius: 30, marginLeft: 10 }}
          >
            Cari
          </Button>
          <Button
            className={classes.filter}
            onClick={() => handleFilter("open", !filter.open)}
          >
            <Typography>Filter</Typography>
            <ArrowDropDownIcon />
          </Button>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectAllLabel}
                onChange={handleToggleAllLabel}
                name="selectAllLabel"
              />
            }
            label="Pilih semua"
          />
          <Button onClick={handleExportExcel} size="small" variant="contained">
            Download excel
          </Button>
          <Button onClick={handleDownloadAllLabel} className={classes.downloadAllBtn } size="small" variant="outlined">
            Download All Label ({dataOrder.filteredOrder.length})
          </Button>
        </div>

        {filter.open && (
          <Fade in={filter.open}>
            <div className={classes.filterOrderWrapper}>
              <Typography variant="h6">
                <b>Filter Order</b>
              </Typography>
              <div className={classes.flexBetween} style={{ paddingTop: 10 }}>
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>Done</Typography>
                  <Switch
                    checked={filter.done === 1}
                    onChange={() =>
                      handleFilter("done", filter.done === 1 ? 0 : 1)
                    }
                    name="done"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>Canceled</Typography>
                  <Switch
                    checked={filter.canceled === 1}
                    onChange={() =>
                      handleFilter("canceled", filter.canceled === 1 ? 0 : 1)
                    }
                    name="canceled"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>Waiting</Typography>
                  <Switch
                    checked={filter.waiting === 1}
                    onChange={() =>
                      handleFilter("waiting", filter.waiting === 1 ? 0 : 1)
                    }
                    name="waiting"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>Processes</Typography>
                  <Switch
                    checked={filter.processes === 1}
                    onChange={() =>
                      handleFilter("processes", filter.processes === 1 ? 0 : 1)
                    }
                    name="processes"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>Delivery</Typography>
                  <Switch
                    checked={filter.delivery === 1}
                    onChange={() =>
                      handleFilter("delivery", filter.delivery === 1 ? 0 : 1)
                    }
                    name="delivery"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>

                {/* forwarded */}
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>Forwarded</Typography>
                  <Switch
                    checked={filter.forwarded === 1}
                    onChange={() =>
                      handleFilter("forwarded", filter.forwarded === 1 ? 0 : 1)
                    }
                    name="forwarded"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>

                {/* cod */}
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>COD</Typography>
                  <Switch
                    checked={filter.cod === 1}
                    onChange={() =>
                      handleFilter("cod", filter.cod === 1 ? 0 : 1)
                    }
                    name="cod"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>

                {/* not cod */}
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>Not COD</Typography>
                  <Switch
                    checked={filter.not_cod === 1}
                    onChange={() =>
                      handleFilter("not_cod", filter.not_cod === 1 ? 0 : 1)
                    }
                    name="not_cod"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>

                {/* delivered */}
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>Delivered</Typography>
                  <Switch
                    checked={filter.delivered === 1}
                    onChange={() =>
                      handleFilter("delivered", filter.delivered === 1 ? 0 : 1)
                    }
                    name="delivered"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
              </div>
              <div style={{ paddingTop: 20 }}>
                <Typography>Tanggal</Typography>

                <div className={classes.filterTanggal}>
                  <div
                    className={classes.filterDiv}
                    style={{ width: 150, paddingRight: 10 }}
                  >
                    <TextField
                      id="date"
                      type="date"
                      className={classes.textField}
                      defaultValue={DateNow}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ disableUnderline: true }}
                      onChange={(e) =>
                        handleFilter("date_start", e.target.value)
                      }
                    />
                  </div>
                  <div
                    className={classes.filterDiv}
                    style={{ width: 150, paddingRight: 10, marginLeft: 20 }}
                  >
                    <TextField
                      id="date"
                      type="date"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ disableUnderline: true }}
                      onChange={(e) => handleFilter("date_end", e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {/* filter expedisi */}
              <div style={{ paddingTop: 20 }}>
                <Typography>Filter Expedisi</Typography>
                <Select
                  value={filter.courier_id}
                  onChange={(e) =>
                    setFilter({ ...filter, courier_id: e.target.value })
                  }
                  displayEmpty
                  variant="outlined"
                >
                  <MenuItem value="">--Pilih Expedisi--</MenuItem>
                  {dataCouries.map((courier, index) => (
                    <MenuItem key={index + courier.name} value={courier.id}>
                      {courier.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: 20,
                }}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  style={{
                    textTransform: "capitalize",
                    borderRadius: 30,
                    marginRight: 10,
                  }}
                  onClick={handleResetFilter}
                >
                  Reset
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ textTransform: "capitalize", borderRadius: 30 }}
                  onClick={handleGunakanFilter}
                >
                  Gunakan Filter
                </Button>
              </div>
            </div>
          </Fade>
        )}

        {dataOrder.filteredOrder &&
          dataOrder.filteredOrder.map((res, index) => (
            <div key={res.id} style={{ paddingTop: 20 }}>
              <CardOrder
                data={res}
                index={
                  dataOrder.order.meta.total -
                  (dataOrder.order.meta.from + (index - 1))
                }
                handleUbahPesanan={handleUbahPesanan}
                isLabelChecked={downloadQueue.indexOf(res.id) !== -1}
                onLoading={(value) => {
                  setIsLoading(value);
                }}
                onToggleDownloadQueue={(id) => toggleDownloadQueue(id)}
              />
            </div>
          ))}

        {dataOrder.filteredOrder.length === 0 && (
          <div className={classes.notFound}>
            <div className={classes.notFound2}>
              <Typography variant="h5">
                <b>Oops!</b>
              </Typography>
              <Typography>Data tidak ditemukan</Typography>
            </div>
          </div>
        )}

        {dataOrder.order && dataOrder.order.meta.last_page !== 1 && (
          <div className={classes.Pagination}>
            <Pagination
              count={dataOrder.order.meta.last_page}
              variant="outlined"
              onChange={handleChangePage}
              color="secondary"
            />
          </div>
        )}
      </Paper>
      {filter.forwarded !== 0 && (
      <Fab
        color="primary"
        className={classes.fab}
        variant="extended"
        style={{zIndex: 10, bottom: 140, backgroundColor: '#FDCA40'}}
        onClick={() => prosesAllPesanan(filteredOrder)}
      >
        Proses All Pesanan
      </Fab>
      )}
      {downloadQueue.length !== 0 && (
        <Fab
          color="primary"
          className={classes.fab}
          variant="extended"
          onClick={() => downloadMultipleLabel(null)}
        >
          <GetAppIcon />
          Download Label ({downloadQueue.length})
        </Fab>
      )}
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
});

export default Content;
