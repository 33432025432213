import React from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { ReactComponent as HamburgerMenu } from "../../assets/icons/burger-menu.svg";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";

const Navbar = withStyles(style)((props) => {
  const { classes, location } = props;
  return (
    <div className={classes.container}>
      <HamburgerMenu className={classes.icon} />
      <Typography className={classes.text}>
        Dashboard{" "}
        {location.pathname === "/"
          ? ""
          : "/ " +
            location.pathname.slice(1)[0].toUpperCase() +
            location.pathname.slice(2).replace("/", " / ").replace("-", " ")}
      </Typography>
    </div>
  );
});

export default withRouter(Navbar);
