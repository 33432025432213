import { axiosInstance } from "../config/fetchAxios";

export const GET_AVAILABLE_CHANNEL = async () => {
  const url = `/api/v2/shopchannels/connect`;

  return axiosInstance.get(url);
};

export const GET_CONNECTED_CHANNEL = async () => {
  const url = `/api/v2/shopchannels`;

  return axiosInstance.get(url);
};

export const GET_SYNCED_PRODUCT = async (params) => {
  const url = `/api/v2/shopchannels/products/get`;

  return axiosInstance.get(url, {
    params,
  });
};

export const LINK_PRODUCT = async (data) => {
  const url = `/api/v2/shopchannels/products/link`;

  return axiosInstance.patch(url, data);
};

export const CONNECT_TO_CHANNEL = async (code) => {
  const url = `/api/v2/shopchannels/connect/${code}`;

  return axiosInstance.get(url, { withCredentials: true });
};

export const DISCONNECT_CHANNEL = async (code) => {
  const url = `/api/v2/shopchannels/delete/${code}`;

  return axiosInstance.delete(url);
};

export const SYNC_PRODUCT_CHANNEL = async (code) => {
  const url = `/api/v2/shopchannels/products/datasync/${code}`;

  return axiosInstance.post(url);
};

export const IMPORT_PRODUCTS = async (channel_product_ids) => {
  const url = `/api/v2/shopchannels/products/import`;

  return axiosInstance.post(url, {
    channel_product_ids,
  });
};
