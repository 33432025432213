import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Paper,
  Button,
  InputBase,
  FormControl,
  Select,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Popover,
  MenuItem,
  Tooltip,
  Switch,
  Badge,
  Checkbox,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
  getSupplierCategories,
  getSupplierProducts,
  getAllCategories,
  deleteProducts,
  uploadExcelProduct,
  editProduct,
  upProduct,
} from "../../../services/axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import PublishIcon from "@material-ui/icons/Publish";
import Pagination from "@material-ui/lab/Pagination";
import DetailProduct from "./detail-product";
import TambahProduct from "./tambah-produk";
import EditProduct from "./edit-product";
import swal from "sweetalert";
import numberSeparator from "../../../utils/numberSeparator";
import Loading from "../../../components/loading";
import { ReactComponent as BinIcon } from "../../../assets/icons/bin.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as LihatIcon } from "../../../assets/icons/eye.svg";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { useAuthContext } from "contexts/AuthContextProvider";
import TestimoniProduk from "./testimoni-product";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  handleDeleteSelectedProduct,
  onCheckedAll,
  onCheckedProduct,
} from "./handler";
import _, { isEmpty } from "lodash";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.yellow.tertiary,
    },
  },
}))(TableRow);

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

const Products = withStyles(style)((props) => {
  const { classes } = props;
  const { refetchSupplier } = useAuthContext();
  const slug = JSON.parse(localStorage.getItem("DATA")).slug;
  const [state, setState] = useState({
    filterWaktu: "Berdasarkan tanggal",
    openTambahPengeluaran: false,
    all_category: [],
    category: [],
    products: [],
    selectedCategory: "",
    selectedFilterHarga: "",
    openDetailProduct: false,
    selectedProduct: null,
    openTambahProduct: false,
    openOther: false,
    openEditProduct: false,
    dataEdit: null,
    search: "",
    tabLoading: false,
    openTestimoniProduct: false,
  });
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState({ name: "" });
  const [selectedFilterHarga, setSelectedFilterHarga] = useState("");
  const [filteredProduct, setFilteredProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getColor = (quantity) => {
    if (quantity < 10) return "orange";
    return "";
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangeState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const handleSearch = (keyword) => {
    handleChangeState("search", keyword);
  };

  const handlePressSearch = async (e) => {
    if (e.key === "Enter") {
      handleChangeState("tabLoading", true);
      const fetch = async () => {
        const produk = await getSupplierProducts(
          slug,
          `?filter[name]=${state.search}&filter[show_custom]=1&include=images,videos`
        );
        setState({ ...state, products: produk, tabLoading: false });
        setFilteredProduct(produk.data);
      };
      fetch();
    }
  };

  const handleClickSearch = async () => {
    const produk = await getSupplierProducts(
      slug,
      `?filter[name]=${state.search}&filter[show_custom]=1&include=images,videos`
    );
    setState({ ...state, products: produk });
    setFilteredProduct(produk.data);
  };

  useEffect(() => {
    const fetch = async () => {
      const kategori = await getSupplierCategories(slug);
      const allKategori = await getAllCategories();
      const produk = await getSupplierProducts(
        slug,
        `?filter[from_newest]=1&filter[show_custom]=1&include=images,videos`
      );
      setState((state) => ({
        ...state,
        all_category: allKategori.data,
        category: kategori.data.filter((item) => item),
        products: produk,
      }));
      setFilteredProduct(produk.data);
      if (kategori && produk) setLoading(false);
    };
    fetch();
  }, [slug]);

  const refreshPage = async () => {
    handleChangeState("tabLoading", true);
    const produk = await getSupplierProducts(
      slug,
      `?filter[from_newest]=1&filter[show_custom]=1&include=images,videos`
    );
    setState({ ...state, products: produk, tabLoading: false });
    setFilteredProduct(produk.data);
  };

  useEffect(() => {
    setState((state) => ({ ...state, tablLoading: true }));
    const fetch = async () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      const produk = await getSupplierProducts(
        slug,
        `?page=${page}&filter[category.id]=${
          selectedCategory.id || ""
        }&sort=${selectedFilterHarga}&filter[show_custom]=1&include=images,videos`
      );
      setState((state) => ({ ...state, products: produk, tabLoading: false }));
      setFilteredProduct(produk.data);
    };
    fetch();
  }, [page, selectedCategory, selectedFilterHarga, slug]);

  const handleCloseTambahProduct = async () => {
    const produk = await getSupplierProducts(
      slug,
      `?filter[from_newest]=1&filter[show_custom]=1&include=images,videos`
    );
    setState({ ...state, openTambahProduct: false, products: produk });
    setFilteredProduct(produk.data);
  };

  const handleCloseDetailProduct = async () => {
    const produk = await getSupplierProducts(
      slug,
      `?filter[from_newest]=1&filter[show_custom]=1&include=images,videos`
    );
    setState({ ...state, openDetailProduct: false, products: produk });
    setFilteredProduct(produk.data);
  };

  const handleCloseEditProduct = async () => {
    const produk = await getSupplierProducts(
      slug,
      `?filter[from_newest]=1&filter[show_custom]=1&include=images,videos`
    );
    setState({ ...state, openEditProduct: false, products: produk });
    setFilteredProduct(produk.data);
  };

  const handleDetailProduct = (data) => {
    setState({ ...state, openDetailProduct: true, selectedProduct: data });
  };

  const handleSwitchFeautured = async (res) => {
    const response = await editProduct(res.slug, {
      is_featured_by_supplier: res.is_featured_by_supplier === 1 ? 0 : 1,
    });
    if (response.updated) {
      const produk = await getSupplierProducts(
        slug,
        `?page=${page}&filter[category.id]=${
          selectedCategory.id || ""
        }&sort=${selectedFilterHarga}&filter[show_custom]=1&include=images,videos`
      );
      setState({ ...state, products: produk });
      setFilteredProduct(produk.data);
    }
  };

  const handleDeleteProduct = (slug) => {
    swal({
      title: "Are you sure?",
      text: "Produk akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteProducts(slug);
        swal("Produk dihapus!", {
          icon: "success",
        }).then(() => refreshPage());
      } else {
        swal("Produk batal dihapus");
      }
    });
  };

  function createData(
    checkbox,
    no,
    produk,
    inventori,
    harga_jual,
    kategori,
    feautured,
    kelola
  ) {
    return {
      checkbox,
      no,
      produk,
      inventori,
      harga_jual,
      kategori,
      feautured,
      kelola,
    };
  }

  const handleEditProduct = (product) => {
    setState({
      ...state,
      openDetailProduct: false,
      openEditProduct: true,
      dataEdit: product,
    });
  };

  const handleUpProduct = (slug) => {
    swal({
      title: "Are you sure?",
      text: "Produk akan di Naikkan",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willUp) => {
      if (willUp) {
        upProduct(slug);
        swal("Produk dinaikkan!", {
          icon: "success",
        }).then(() => refreshPage());
      } else {
        swal("Produk batal dinaikkan");
      }
    });
  };

  const Kelola = ({ product }) => {
    return (
      <div style={{ width: 240 }}>
        <Tooltip title="Edit" placement="bottom">
          <StyledButton
            onClick={() =>
              setState({ ...state, openEditProduct: true, dataEdit: product })
            }
          >
            <EditIcon />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Hapus" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10 }}
            onClick={() => handleDeleteProduct(product.slug)}
          >
            <BinIcon />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Lihat Detail" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10, height: 35 }}
            onClick={() =>
              handleDetailProduct(product, handleEditProduct(product))
            }
          >
            <LihatIcon />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Naikkan Produk" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10, height: 35 }}
            onClick={() => handleUpProduct(product.slug)}
          >
            <ArrowUpwardIcon style={{ color: "#fff" }} />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Testimoni produk" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10, height: 35 }}
            onClick={() =>
              setState({
                ...state,
                openTestimoniProduct: true,
                dataEdit: product,
              })
            }
          >
            <ChatBubbleIcon style={{ color: "#fff" }} />
          </StyledButton>
        </Tooltip>
      </div>
    );
  };

  const Produk = ({ image, name, totalVarian, is_approved }) => {
    return (
      <div style={{ display: "flex" }}>
        {
          is_approved === 0 ?
          <Badge 
            color="secondary" 
            badgeContent="In-Review"
            style={{width: 70}}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <img
              src={image}
              alt=""
              height={60}
              width={60}
              style={{ borderRadius: 5 }}
            />
          </Badge>
          :
          <div style={{width: 70}}>
            <img
              src={image}
              alt=""
              height={60}
              width={60}
              style={{ borderRadius: 5 }}
            />
          </div>
        }
        <div style={{ paddingLeft: 10 }}>
          <Typography
            style={{
              textAlign: "start",
              width: 150,
              overflow: "hidden",
              display: "-webkit-box",
              boxOrient: "vertical",
              lineClamp: 2,
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
            style={{ paddingTop: 15, textAlign: "start" }}
          >
            {totalVarian} Varian
          </Typography>
        </div>
      </div>
    );
  };

  const getStock = (data) => {
    let returnData = 0;
    data.forEach((res) => {
      returnData = returnData + res.stock;
    });
    return returnData;
  };

  const dataRows = () => {
    let row = [];
    filteredProduct.forEach((res, index) => {
      row = [
        ...row,
        createData(
          <Checkbox
            onChange={(e) =>
              onCheckedProduct(
                e.target.checked,
                res,
                filteredProduct,
                setFilteredProduct,
                selectedProduct,
                setSelectedProduct
              )
            }
            checked={res.checked}
          />,
          index + 1,
          <Produk
            image={res.image_url}
            name={res.name}
            totalVarian={res.is_custom ? "-" : res.skus.length}
            is_approved={res.is_approved}
          />,
          res.is_custom ? "-" : getStock(res.skus),
          res.skus
            ? res.skus.length !== 0
              ? res.skus[0].price.reseller
              : 0
            : "-",
          res.category.name,
          <Switch
            checked={res.is_featured_by_supplier === 1}
            onChange={() => handleSwitchFeautured(res)}
            name={res.name}
          />,
          <Kelola product={res} />
        ),
      ];
    });
    return row;
  };

  const handleUploadExcel = async (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    if (
      event.target.files[0].type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      const response = await uploadExcelProduct(formData);
      if (response.success) {
        swal("Success", "Berhasil Import Excel!", "success");
      } else {
        swal("Oops!", "Gagal Import Excel!", "warning");
      }
    } else {
      swal("Oops!", "Gunakan File Excel!", "warning");
    }
  };

  function download(filename) {
    fetch(`https://api.idejualan.com/api/v1/export_product`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
      },
    }).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
      });
    });
  }

  const handleDownloadExcel = async () => {
    download("Idejualan Produk Excel.xlsx");
  };

  useEffect(() => {
    const fetch = async () => {
      await refetchSupplier();
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const kategori = await getSupplierCategories(slug);
      const allKategori = await getAllCategories();
      const produk = await getSupplierProducts(
        slug,
        `?filter[from_newest]=1&filter[show_custom]=1&include=images,videos`
      );
      setState((state) => ({
        ...state,
        all_category: allKategori.data,
        category: kategori.data.filter((item) => item),
        products: produk,
      }));
      setFilteredProduct(produk.data);
      if (kategori && produk) setLoading(false);
      setIsRefetch(false)
      setLoading(false)
    };
    fetch();
  }, [isRefetch]);
  
  const handleCloseDialog = () => {
    setIsOpenDialogDelete(!isOpenDialogDelete);
  };

  const DialogDelete = () => (
    <Dialog
      open={isOpenDialogDelete}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Apakah kamu yakin ingin menghapus ?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Data yang dihapus tidak dapat dipulihkan!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Tidak
        </Button>
        <Button
          onClick={() =>
            handleDeleteSelectedProduct(
              selectedProduct,
              setSelectedProduct,
              setFilteredProduct,
              isOpenDialogDelete,
              setIsOpenDialogDelete,
              setLoading,
              setIsRefetch
            )
          }
          color="primary"
          autoFocus
        >
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          {state.openDetailProduct ? (
            <DetailProduct
              data={state.selectedProduct}
              handleEditProduct={handleEditProduct}
              handleClose={handleCloseDetailProduct}
            />
          ) : state.openTambahProduct ? (
            <TambahProduct
              category={state.all_category}
              handleChangeStateParent={handleChangeState}
              onClose={handleCloseTambahProduct}
            />
          ) : state.openEditProduct ? (
            <EditProduct
              category={state.all_category}
              dataApi={state.dataEdit}
              onClose={handleCloseEditProduct}
            />
          ) : state.openTestimoniProduct ? (
            <TestimoniProduk
              onClose={() =>
                setState({ ...state, openTestimoniProduct: false })
              }
              slug={state.dataEdit?.slug}
            />
          ) : (
            <Fragment>
              <div className={classes.header}>
                <Typography variant="h6">
                  <b>Produk</b>
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.tambah}
                    style={{ marginRight: 20 }}
                    onClick={() => handleChangeState("openTambahProduct", true)}
                  >
                    + Tambah Produk
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.other}
                    aria-describedby={id}
                    onClick={handleClick}
                  >
                    ...
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem>
                      <PublishIcon />{" "}
                      <Typography style={{ paddingLeft: 10 }}>
                        Upload Excel
                      </Typography>
                      <input
                        type="file"
                        onChange={handleUploadExcel}
                        className={classes.inputHide}
                      />
                    </MenuItem>
                    <MenuItem onClick={handleDownloadExcel}>
                      <SystemUpdateAltIcon />{" "}
                      <Typography style={{ paddingLeft: 10 }}>
                        Download Excel
                      </Typography>
                    </MenuItem>
                    <a
                      href={require("../../../assets/Idejualan_Produk_Excel.xlsx")}
                      download
                      style={{ color: "#000000", textDecoration: "none" }}
                    >
                      <MenuItem>
                        <SystemUpdateAltIcon />{" "}
                        <Typography style={{ paddingLeft: 10 }}>
                          Download Contoh Excel
                        </Typography>
                      </MenuItem>
                    </a>
                  </Popover>
                </div>
              </div>

              <Paper style={{ marginTop: 20 }}>
                <div style={{ display: "flex", padding: 20 }}>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon
                        style={{ cursor: "pointer" }}
                        onClick={handleClickSearch}
                      />
                    </div>
                    <InputBase
                      placeholder="Search…"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ "aria-label": "search" }}
                      onChange={(event) => handleSearch(event.target.value)}
                      onKeyPress={handlePressSearch}
                    />
                  </div>

                  <FormControl className={classes.formControl}>
                    <Select
                      className={classes.select}
                      value={selectedCategory.name}
                      disableUnderline
                      displayEmpty
                    >
                      <MenuItem disabled value="">
                        <em>Kategori</em>
                      </MenuItem>
                      {state.category.map((res, index) => (
                        <MenuItem
                          key={index}
                          value={res.name}
                          onClick={(event) => setSelectedCategory(res)}
                        >
                          {res.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <Select
                      className={classes.select}
                      value={selectedFilterHarga}
                      disableUnderline
                      displayEmpty
                    >
                      <MenuItem disabled value="">
                        <em>Urutkan</em>
                      </MenuItem>
                      <MenuItem
                        value="-ref_price"
                        onClick={() => setSelectedFilterHarga("-ref_price")}
                      >
                        Termahal
                      </MenuItem>
                      <MenuItem
                        value="ref_price"
                        onClick={() => setSelectedFilterHarga("ref_price")}
                      >
                        Termurah
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>
                          <Checkbox
                            onChange={(e) =>
                              onCheckedAll(
                                e.target.checked,
                                isCheckedAll,
                                setIsCheckedAll,
                                filteredProduct,
                                setFilteredProduct,
                                selectedProduct,
                                setSelectedProduct
                              )
                            }
                            checked={isCheckedAll}
                          />
                        </StyledTableCell>
                        <StyledTableCell>No</StyledTableCell>
                        <StyledTableCell align="center">Produk</StyledTableCell>
                        <StyledTableCell align="center">
                          Inventori
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Harga Jual
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Kategori
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Catatan ditolak
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Featured
                        </StyledTableCell>
                        <StyledTableCell align="center">Kelola</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {!state.tabLoading && (
                      <TableBody>
                        {state.products.length !== 0 &&
                          dataRows().map((row) => (
                            <StyledTableRow key={row.name}>
                              <StyledTableCell component="th" scope="row">
                                {row.checkbox}
                              </StyledTableCell>
                              <StyledTableCell>{row.no}</StyledTableCell>
                              <StyledTableCell align="center">
                                {row.produk}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{
                                  backgroundColor: getColor(row.inventori),
                                }}
                              >
                                {row.inventori}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Rp. {numberSeparator(row.harga_jual)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.kategori}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.declined_note || "-"}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.feautured}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.kelola}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>

                {filteredProduct && filteredProduct.length === 0 && (
                  <div className={classes.notFound}>
                    <div className={classes.notFound2}>
                      <Typography variant="h5">
                        <b>Oops!</b>
                      </Typography>
                      <Typography>Data tidak ditemukan</Typography>
                    </div>
                  </div>
                )}

                {state.tabLoading && (
                  <div className={classes.tabLoading}>
                    <CircularProgress />
                  </div>
                )}

                {filteredProduct.length !== 0 && (
                  <div className={classes.pagination}>
                    <Pagination
                      count={state.products.meta.last_page}
                      color="primary"
                      page={page}
                      onChange={(e, value) => setPage(value)}
                    />
                  </div>
                )}
              </Paper>
            </Fragment>
          )}
        </Fragment>
      )}
      <div className={classes.floatingButton}>
        {!isEmpty(selectedProduct) && (
          <Fab
            color="secondary"
            aria-label="delete"
            variant="extended"
            onClick={() => setIsOpenDialogDelete(!isOpenDialogDelete)}
          >
            <DeleteIcon /> Delete ({selectedProduct.length})
          </Fab>
        )}
      </div>
      {DialogDelete()}
    </Fragment>
  );
});

export default Products;
