const constants = {
  COLOR: {
    RED: '#FF6564',
    YELLOW: '#FDCA40',
    GREEN: '#29C0C7',
    GREY: '#787878',
    BLACK : '#000000'
  }
}

export default constants