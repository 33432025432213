import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import idejualan_logo from "../../assets/images/Logo-Horizontal.png";
import { ReactComponent as Home } from "../../assets/icons/home1.svg";
import { ReactComponent as Graphic } from "../../assets/icons/graphic.svg";
import { ReactComponent as Expenses } from "../../assets/icons/expenses.svg";
import { ReactComponent as ShoppingCart } from "../../assets/icons/shopping-cart.svg";
import { ReactComponent as GraphicEditor } from "../../assets/icons/graphics-editor.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as Discount } from "../../assets/icons/discount.svg";
import Collapse from "@material-ui/core/Collapse";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import StoreIcon from "@material-ui/icons/Store";

const Sidebar = withStyles(style)((props) => {
  const { classes, history, location } = props;
  const [openNestedProduct, setOpenNestedProduct] = useState(false);
  const [openNestedOrder, setOpenNestedOrder] = useState(false);
  const [openNestedFinance, setOpenNestedFinance] = useState(false);
  const [openNestedOther, setOpenNestedOther] = useState(false);

  const handleClickNestedProduct = () => {
    setOpenNestedProduct(!openNestedProduct);
  };

  const handleClickNestedOrder = () => {
    setOpenNestedOrder(!openNestedOrder);
  };

  const handleClickNestedFinance = () => {
    setOpenNestedFinance(!openNestedFinance);
  };

  const handleClickNestedOther = () => {
    setOpenNestedOther(!openNestedOther);
  };

  const handleLogout = () => {
    swal({
      title: "Are you sure?",
      text: "Kamu akan keluar dari dashboard",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        localStorage.clear();
        window.location.reload();
      }
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.logoWrapper}>
        <img
          src={idejualan_logo}
          alt="idejualan"
          className={classes.idejualanLogo}
        />
      </div>

      <div className={classes.dashboardWrapper}>
        <List>

          {/* beranda */}
          <ListItem
            button
            onClick={() => {
              history.push("/");
            }}
            className={
              location.pathname === "/" ? classes.buttonClicked : classes.button
            }
          >
            <ListItemIcon
              style={{minWidth: 40}}
            >
              <Home
                className={
                  location.pathname === "/"
                    ? classes.iconClickedDs
                    : classes.iconDs
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/"
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                beranda
              </Typography>
            </ListItemText>
          </ListItem>

          {/* analytic */}
          <ListItem
            button
            onClick={() => {
              history.push("/analytic");
            }}
            className={
              location.pathname === "/analytic"
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon
              style={{minWidth: 40}}
            >
              <Graphic
                className={
                  location.pathname === "/analytic"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/analytic"
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Analytic
              </Typography>
            </ListItemText>
          </ListItem>

          {/* Product */}
          <ListItem
            button
            onClick={() => {
              handleClickNestedProduct();
            }}
            className={
              location.pathname === "/product/products" ||
              location.pathname === "/product/category-product" || 
              location.pathname === "/e-course" || 
              location.pathname === "/e-product"
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon
              style={{minWidth: 40}}
            >
              <GraphicEditor
                className={
                  location.pathname === "/product/products" ||
                  location.pathname === "/product/category-product" ||
                  location.pathname === "/e-course" || 
                  location.pathname === "/e-product"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/product/products" ||
                  location.pathname === "/product/category-product" || 
                  location.pathname === "/e-course" || 
                  location.pathname === "/e-product"
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Produk
              </Typography>
            </ListItemText>
            {openNestedProduct ? (
              <ArrowUp
                className={
                  location.pathname === "/product/products" ||
                  location.pathname === "/product/category-product" || 
                  location.pathname === "/e-course" || 
                  location.pathname === "/e-product"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            ) : (
              <ArrowDown
                className={
                  location.pathname === "/product/products" ||
                  location.pathname === "/product/category-product" || 
                  location.pathname === "/e-course" || 
                  location.pathname === "/e-product"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            )}
          </ListItem>
          <Collapse in={openNestedProduct} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              {/* Products */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/product/products");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/product/products"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Products
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* E-Course */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/e-course");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname.indexOf("/e-course") !== -1
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    E-Course
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* E-Product */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => history.push("/e-product")}
              >
                <ListItemText>
                  <Typography 
                    className={
                      location.pathname.indexOf("/e-product") !== -1
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    E-Product
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Category Product */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/product/category-product");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/product/category-product"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Category Product
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          {/* Penjualan */}
          <ListItem
            button
            onClick={() => {
              handleClickNestedOrder();
            }}
            className={
              location.pathname === "/order" ||
              location.pathname === "/order-custom" 
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon
              style={{minWidth: 40}}
            >
              <ShoppingCart
                className={
                  location.pathname === "/order"
                    ? classes.iconClickedDs
                    : classes.iconDs
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/order" ||
                  location.pathname === "/order-custom"
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Penjualan
              </Typography>
            </ListItemText>
            {openNestedOrder ? (
              <ArrowUp
                className={
                  location.pathname === "/order" ||
                  location.pathname === "/order-custom"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            ) : (
              <ArrowDown
                className={
                  location.pathname === "/order" ||
                  location.pathname === "/order-custom"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            )}
          </ListItem>
          <Collapse in={openNestedOrder} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              {/* Pesanan */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/order");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/order"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Pesanan
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Pesanan Custom */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/order-custom");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname.indexOf("/order-custom") !== -1
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Pesanan Custom
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>
          
          {/* Keuangan */}
          <ListItem
            button
            onClick={() => {
              handleClickNestedFinance();
            }}
            className={
              location.pathname === "/pengeluaran" ||
              location.pathname === "/history-transaction" 
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon
              style={{minWidth: 40}}
            >
              <Expenses
                className={
                  location.pathname === "/pengeluaran" ||
                  location.pathname === "/history-transaction"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/pengeluaran" ||
                  location.pathname === "/history-transaction"
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Keuangan
              </Typography>
            </ListItemText>
            {openNestedFinance ? (
              <ArrowUp
                className={
                  location.pathname === "/pengeluaran" ||
                  location.pathname === "/history-transaction"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            ) : (
              <ArrowDown
                className={
                  location.pathname === "/pengeluaran" ||
                  location.pathname === "/history-transaction"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            )}
          </ListItem>
          <Collapse in={openNestedFinance} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              {/* Pengeluaran */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/pengeluaran");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/pengeluaran"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Pengeluaran
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* History Transaksi */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/history-transaction");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname.indexOf("/history-transaction") !== -1
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    History Transaksi
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          {/* promo */}
          <ListItem
            button
            onClick={() => history.push("/promo")}
            className={
              location.pathname === "/promo/:slug"
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon
              style={{minWidth: 40}}
            >
              <Discount className={classes.icon} />
            </ListItemIcon>
            <ListItemText>
              <Typography className={classes.textList}>Promosi</Typography>
            </ListItemText>
          </ListItem>

          {/* integrasi marketplace */}
          <ListItem
            button
            onClick={() => {
              history.push("/marketplace");
            }}
            className={
              location.pathname.indexOf("/marketplace") !== -1
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon
              style={{minWidth: 40}}
            >
              <StoreIcon
                className={
                  location.pathname.indexOf("/marketplace") !== -1
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname.indexOf("/marketplace") !== -1
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Integrasi Marketplace
              </Typography>
            </ListItemText>
          </ListItem>

          {/* lainnya */}
          <ListItem
            button
            onClick={() => {
              handleClickNestedOther();
            }}
            className={
              location.pathname === "/info" ||
              location.pathname === "/announcements" 
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon
              style={{minWidth: 40}}
            >
              <GraphicEditor
                className={
                  location.pathname === "/info" ||
                  location.pathname === "/announcements" 
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/info" ||
                  location.pathname === "/announcements" 
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Lainnya
              </Typography>
            </ListItemText>
            {openNestedOther ? (
              <ArrowUp
                className={
                  location.pathname === "/info" ||
                  location.pathname === "/announcements" 
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            ) : (
              <ArrowDown
                className={
                  location.pathname === "/info" ||
                  location.pathname === "/announcements" 
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            )}
          </ListItem>
          <Collapse in={openNestedOther} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              {/* Pengumuman */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/announcements");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname.indexOf("/announcements") !== -1
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Pengumuman
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Info Store */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/info");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/info"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Info Store
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Log Out */}
              <ListItem
                button
                className={classes.nested}
                onClick={handleLogout}
              >
                <ListItemText>
                  <Typography
                    className={classes.textList}
                  >
                    Log Out
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>
        </List>
      </div>
    </div>
  );
});

export default withRouter(Sidebar);
