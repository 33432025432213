import { Typography } from "@material-ui/core";
import React, { useReducer, useState } from "react";
import DialogConnectProduct from "./DialogConnectProduct/DialogConnectProduct";
import DialogImportProduct from "./DialogImportProduct/DialogImportProduct";
import SyncMarketplaceButton from "./SyncMarketplaceButton/SyncMarketplaceButton";
import Table from "./Table/Table";

const Marketplace = () => {
  const [isDialogConnectOpen, setIsDialogConnectOpen] = useState(false);
  const [importProductOpen, setImportProductOpen] = useState(false);
  const [connectProduct, setConnectProduct] = useState(null);
  const [updateTable, forceUpdateTable] = useReducer((x) => x + 1, 0);

  const handleConnectProduct = (product) => {
    setConnectProduct(product);
    setIsDialogConnectOpen(true);
  };

  return (
    <div>
      <Typography variant="h3">Integrasi Marketplace</Typography>

      <SyncMarketplaceButton
        onUpdate={() => forceUpdateTable()}
        onImportProduct={() => setImportProductOpen(true)}
      />

      <Table
        onConnectProduct={handleConnectProduct}
        updateTable={updateTable}
      />

      <DialogConnectProduct
        product={connectProduct}
        open={isDialogConnectOpen}
        onClose={() => setIsDialogConnectOpen(false)}
      />

      <DialogImportProduct
        open={importProductOpen}
        onClose={() => setImportProductOpen(false)}
        onProductImported={() => forceUpdateTable()}
      />
    </div>
  );
};

export default Marketplace;
