import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Close as CloseIcon } from "@material-ui/icons";

import { getPopupSupplier } from "../../services/axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: true,
  speed: 2000,
  autoplaySpeed: 15000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ModalPopup = () => {
  const [open, setOpen] = useState(false);
  const [popups, setPopups] = useState([]);

  useEffect(() => {
    handleFetchPopup();
  }, []);

  const handleFetchPopup = async () => {
    try {
      const response = await getPopupSupplier();
      setPopups(response.data);
      setOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={open === true ? "modalPopUp" : "modalPopUp_close"}>
        <div className="modalPopUp_background">
          <div className="modalPopUp_content">
            <div className="modalPopUp_simpleBanner">
              <Slider {...settings}>
                {popups.map((res, index) => (
                  <div key={index}>
                    <a href={res.link} target="_blank">
                      <div
                        style={{
                          background: `url('${res.image}')`,
                          width: "100%",
                          height: "100vh",
                          maxHeight: "700px",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    </a>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="modalPopUp_closeArea" onClick={handleClose}>
              <div className="modalPopUp_closeBtn">
                <CloseIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalPopup;
