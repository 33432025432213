import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { Paper, Typography } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import { ReactComponent as TerkirimIcon } from "../../../../assets/icons/done.svg";
import { ReactComponent as PendingIcon } from "../../../../assets/icons/gift.svg";
import { ReactComponent as GagalIcon } from "../../../../assets/icons/close.svg";
import { getChartSummaryGrafik } from "../../../../services/axios";

const SummaryPenjualan = withStyles(styles)((props) => {
  const [chartSummaryGrafik, setChartSummaryGrafik] = useState({});
  const { classes } = props;
  useEffect(() => {
    const fetch = async () => {
      const report = await getChartSummaryGrafik();
      setChartSummaryGrafik(report);
    };
    fetch();
  }, []);
  function percentaseCalculate(num, delivered, pending, failed) {
    let sum = delivered + pending + failed;
    let percentFormat = ((num / sum) * 100) || 0;
    return `${Math.round(percentFormat)} %`;
  }

  const menu = [
    {
      icon: <TerkirimIcon className={classes.icon} />,
      text: `${
        chartSummaryGrafik.order_by_state &&
        chartSummaryGrafik.order_by_state.delivered
      } Pesanan Terkirim`,
      count: percentaseCalculate(
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.delivered,
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.delivered,
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.pending,
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.failed
      ),
      color: props.classes.green,
      bgColor: props.classes.bgGreen,
    },
    {
      icon: <PendingIcon className={classes.icon} />,
      text: `${
        chartSummaryGrafik.order_by_state &&
        chartSummaryGrafik.order_by_state.pending
      } Pesanan Pending`,
      count: percentaseCalculate(
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.pending,
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.delivered,
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.pending,
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.failed
      ),
      color: props.classes.yellow,
      bgColor: props.classes.bgYellow,
    },
    {
      icon: <GagalIcon className={classes.icon} />,
      text: `${
        chartSummaryGrafik.order_by_state &&
        chartSummaryGrafik.order_by_state.failed
      } Pesanan Gagal`,
      count: percentaseCalculate(
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.failed,
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.delivered,
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.pending,
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.failed
      ),
      color: props.classes.red,
      bgColor: props.classes.bgRed,
    },
  ];

  const data = {
    labels: ["Pesanan Gagal", "Pesanan Terkirim", "Pesanan Pending"],
    datasets: [
      {
        data: [chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.failed,
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.delivered,
        chartSummaryGrafik.order_by_state &&
          chartSummaryGrafik.order_by_state.pending],
        backgroundColor: ["#FF6564", "#3DDC97", "#FDCA40"],
        hoverBackgroundColor: ["#FF6564", "#3DDC97", "#FDCA40"],
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
  };

  return (
    <Fragment>
      <Typography className={classes.title}>
        <b>Grafik</b>
      </Typography>
      <Paper className={classes.wrapper}>
        <div className={classes.bag1}>
          <Typography>Order Statistik</Typography>
          {/* <select className={classes.filter}>
            {bulan.map((bulan) => (
              <option>{bulan}</option>
            ))}
          </select> */}
        </div>

        <Doughnut data={data} options={options} height={100} width={100} />
        {menu.map((res, i) => (
          <div style={{ display: "flex", marginTop: 20 }} key={i}>
            <div className={res.bgColor}>{res.icon}</div>
            <div style={{ paddingLeft: 20 }}>
              <Typography variant="h5" className={res.color}>
                {res.count + " "}
              </Typography>
              <Typography variant="caption" className={res.color}>
                {res.text}
              </Typography>
            </div>
          </div>
        ))}
      </Paper>
    </Fragment>
  );
});

export default SummaryPenjualan;
