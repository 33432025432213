import styled from "styled-components";

export const WrapDataTableBundling = styled.div`
  position: relative;
  height: 400px;
  width: 100%;
  .MuiDataGrid-root {
    position: relative;
    font-family: "DMSans-Regular";
    .MuiDataGrid-colCell {
      padding: 16px;
    }
    .MuiDataGrid-cell {
      font-size: 13px;
    }
    .MuiDataGrid-cell,
    .MuiDataGrid-row {
      min-height: 60px !important;
      max-height: 60px !important;
      line-height: 59px !important;
    }
    .MuiDataGrid-columnSeparator {
      display: none;
    }
    .MuiDataGrid-colCellTitle {
      font-family: "DMSans-Bold";
      font-size: 14px;
      color: #787878;
      letter-spacing: 0.02em;
    }
  }
`;
