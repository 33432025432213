import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Grid,
  Paper,
  Button,
  TextField,
  Collapse,
  Switch,
  Popper,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  ClickAwayListener,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CardDetailBarang from "./CardDetailBarang";
import CardCustomProduct from "./CardCustomProduct";
import createRandomId from "../../../../utils/createRandomId";
import swal from "sweetalert";
import { addProduct } from "../../../../services/axios";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddIcon from "@material-ui/icons/Add";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const TambahProduk = withStyles(style)((props) => {
  const { classes, category, handleChangeStateParent, onClose } = props;
  const dataSupplier = JSON.parse(localStorage.getItem("DATA"));
  const [state, setState] = useState({
    nama_produk: "",
    tags: "",
    kategori: { name: "Pilih Kategori" },
    supplier: dataSupplier,
    diskon: "",
    comission_value: "",
    comission_type: "PERCENT",
    deskripsi: "",
    platform_fee_value: dataSupplier.platform_fee_value,
    platform_fee_type: dataSupplier.platform_fee_type,
    detail_barang: [
      {
        code: createRandomId(10),
        properties: {
          color: "",
          size: "",
          material: "",
        },
        stock: "",
        weight_gram: "",
        consumer_price_idr: "",
        image_b64: null,
      },
    ],
    images: [null],
    videos: [null],
  });
  const [settings, setSettings] = useState({
    varian: true,
    diskon: false,
    publish: true,
    show_stock: true,
  });
  const [openChilderenCat, setOpenChilderenCat] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loadingTambah, setLoadingTambah] = useState(false);
  const [isCustom, setIsCustom] = React.useState(false);
  const [customVariant, setCustomVariant] = React.useState([""]);
  const [customProductImage, setCustomProductImage] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSettings = (key, value) => {
    setSettings({
      ...settings,
      [key]: value,
    });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleChangePhoto = async (event, code) => {
    const validImage = ["image/jpeg", "image/png"];
    if (validImage.includes(event.target.files[0].type)) {
      if (code) {
        setState({
          ...state,
          detail_barang: [
            ...state.detail_barang.filter((value) => value.code !== code),
            {
              ...state.detail_barang.filter((value) => value.code === code)[0],
              image_b64: await toBase64(event.target.files[0]),
            },
          ],
        });
      } else {
        setCustomProductImage(await toBase64(event.target.files[0]));
      }
    } else alert("Gambar yang di upload tidak valid");
  };

  const handleChangeImage = async (event, index) => {
    const validImage = ["image/jpeg", "image/png"];
    if (validImage.includes(event.target.files[0].type)) {
      const _images = [...state.images];
      _images[index] = event.target.files[0];
      setState({ ...state, images: _images });
    } else alert("Gambar yang di upload tidak valid");
  };

  const handleChangeVideo = async (event, index) => {
    const _videos = [...state.videos];
      _videos[index] = event.target.value;
      setState({ ...state, videos: _videos });
  };

  const handleSetState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const handleChangeSkus = (code, key, value) => {
    setState((state) => {
      const barang = state.detail_barang.find((item) => item.code === code);
      barang[key] = value;
      return { ...state };
    });
  };

  const handleRemoveSkus = (code) => {
    setState({
      ...state,
      detail_barang: state.detail_barang.filter((value) => value.code !== code),
    });
  };

  const handleCopySkus = (code) => {
    const barang = JSON.parse(
      JSON.stringify(state.detail_barang.find((item) => item.code === code))
    );
    setState({
      ...state,
      detail_barang: [
        ...state.detail_barang,
        { ...barang, code: createRandomId(10) },
      ],
    });
  };

  const handleChangeSkusProperties = (code, key, value) => {
    setState((state) => {
      const barang = state.detail_barang.find((item) => item.code === code);
      barang.properties[key] = value;
      return { ...state };
    });
  };

  const handleTambahVarian = () => {
    setState({
      ...state,
      detail_barang: [
        ...state.detail_barang,
        {
          code: createRandomId(10),
          properties: {
            color: "",
            size: "",
            material: "",
          },
          stock: "",
          weight_gram: "",
          consumer_price_idr: "",
          image_b64: null,
        },
      ],
    });
  };

  const handleChangeCustomVariant = (e, i) => {
    const newVariant = [...customVariant];
    newVariant[i] = e.target.value;

    setCustomVariant(newVariant);
  };

  const handleAddCustomVariant = () => {
    setCustomVariant((prev) => [...prev, ""]);
  };

  const handleRemoveCustomVariant = (i) => {
    const newVariant = [...customVariant];
    const filtered = newVariant.filter((val, index) => index !== i);
    setCustomVariant(filtered);
  };

  const handleDeleteImages = (i) => {
    const newImages = [...state.images];
    newImages.splice(i, 1);
    setState({ ...state, images: newImages });
  };

  const handleDeleteVideos = (i) => {
    const newVideos = [...state.videos];
    newVideos.splice(i, 1);
    setState({ ...state, videos: newVideos });
  };

  const handleSimpanProduk = async () => {
    for (let i = 0; i < state.detail_barang.length; i++) {
      if (state.nama_produk.length === 0) {
        swal("Oops!", "Silahkan input Nama Produk.", "warning");
      } else if (state.deskripsi.length === 0) {
        swal("Oops!", "Silahkan input Deskripsi Produk.", "warning");
      } else if (state.kategori.name === "Pilih Kategori") {
        swal("Oops!", "Silahkan input Kategori.", "warning");
      } else if (state.supplier.length === 0) {
        swal("Oops!", "Silahkan input Supplier.", "warning");
      } else if (state.comission_value.length === 0) {
        swal("Oops!", "Silahkan input Komisi Reseller.", "warning");
      } else if (
        state.comission_type === "PERCENT" &&
        state.comission_value > 100
      ) {
        swal("Oops!", "Komisi dengan type persen hanya sampai 100%", "warning");
      } else if (
        state.comission_type === "PERCENT" &&
        state.comission_value < 10
      ) {
        swal("Oops!", "Komisi minimal 10%", "warning");
      } else if (isCustom && customProductImage === null) {
        swal("Oops!", "Silahkan masukan gambar produk", "warning");
      } else if (
        !isCustom &&
        (state.detail_barang[i].properties.color.length === 0 ||
          state.detail_barang[i].properties.size.length === 0 ||
          state.detail_barang[i].properties.material.length === 0 ||
          state.detail_barang[i].stock.length === 0 ||
          state.detail_barang[i].weight_gram.length === 0 ||
          state.detail_barang[i].consumer_price_idr.length === 0 ||
          state.detail_barang[i].image_b64 === null)
      ) {
        swal("Oops!", "Silahkan lengkapi detail barang.", "warning");
      } else {
        if (i === state.detail_barang.length - 1) {
          let dataSkus = [];
          if (!isCustom) {
            for (let i = 0; i < state.detail_barang.length; i++) {
              dataSkus = [
                ...dataSkus,
                {
                  code: state.detail_barang[i].code,
                  properties: {
                    color: state.detail_barang[i].properties.color,
                    size: state.detail_barang[i].properties.size,
                    material: state.detail_barang[i].properties.material,
                  },
                  stock: state.detail_barang[i].stock,
                  weight_gram: state.detail_barang[i].weight_gram,
                  consumer_price_idr: state.detail_barang[i].consumer_price_idr,
                  image_b64: state.detail_barang[i].image_b64.split(",")[1],
                },
              ];
            }
          }
          const payload = {
            name: state.nama_produk,
            description: state.deskripsi,
            tags: state.tags.split(","),
            category_id: state.kategori.id,
            supplier_id: state.supplier.id,
            published: settings.publish,
            show_stock: settings.show_stock,
            commission_value: state.comission_value,
            commission_type: state.comission_type,
            images: state.images.filter((image) => image !== null),
            videos: state.videos.filter((video) => video !== null),
          };

          if (isCustom) {
            payload.image = customProductImage.split(",")[1];
            payload.is_custom = true;
            payload.properties_custom = customVariant;
          } else {
            payload.skus = dataSkus;
          }

          const formData = new FormData();

          formData.append("name", payload.name);
          formData.append("description", payload.description);
          payload.tags.forEach((tag, i) => formData.append(`tags[${i}]`, tag));
          formData.append("category_id", payload.category_id);
          formData.append("supplier_id", payload.supplier_id);
          formData.append("published", payload.published ? 1 : 0);
          formData.append("show_stock", payload.show_stock ? 1 : 0);
          formData.append("commission_value", payload.commission_value);
          formData.append("commission_type", payload.commission_type);
          payload.images.forEach((image, i) => {
            if (image) formData.append(`images[${i}]`, image);
          });
          payload.videos.forEach((video, i) => {
            if (video) formData.append(`videos[${i}]`, video);
          });

          if (!isCustom) {
            dataSkus.forEach((sku, i) => {
              const currentSku = `skus[${i}]`;
              formData.append(`${currentSku}[code]`, sku.code);
              formData.append(
                `${currentSku}[properties][material]`,
                sku.properties.material
              );
              formData.append(
                `${currentSku}[properties][color]`,
                sku.properties.color
              );
              formData.append(
                `${currentSku}[properties][size]`,
                sku.properties.size
              );
              formData.append(`${currentSku}[stock]`, sku.stock);
              formData.append(`${currentSku}[weight_gram]`, sku.weight_gram);
              formData.append(
                `${currentSku}[consumer_price_idr]`,
                sku.consumer_price_idr
              );
              formData.append(`${currentSku}[image_b64]`, sku.image_b64);
            });
          } else {
            formData.append("image", payload.customProductImage.split(",")[1]);
            formData.append("is_custom", 1);
            customVariant.forEach((custom, i) => {
              formData.append(`properties_custom[${i}]`, custom);
            });
          }

          setLoadingTambah(true);
          const response = await addProduct(formData);
          if (response.data) {
            swal("Success", "Produk berhasil ditambahkan", "success").then(
              () => {
                setLoadingTambah(false);
                onClose();
              }
            );
          } else {
            swal("Success", "Produk gagal ditambahkan", "warning");
          }
        }
      }
    }
  };

  return (
    <Fragment>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.flexCenter}>
          <ArrowBackIcon
            style={{ paddingRight: 10, cursor: "pointer" }}
            onClick={() => handleChangeStateParent("openTambahProduct", false)}
          />
          <b>Tambah Produk</b>
        </Typography>
      </div>

      <Grid container spacing={3} style={{ paddingTop: 30 }}>
        <Grid item xs={9}>
          <Paper elevation={2} style={{ padding: 30 }}>
            <h3>1. Informasi Dasar</h3>
            <Grid container spacing={2}>
              <Grid item xs={3} className="label">
                Nama Produk
              </Grid>
              <Grid item xs={9}>
                <TextField
                  placeholder="Masukkan nama Produk"
                  InputProps={{ disableUnderline: true }}
                  inputProps={{
                    maxLength: 80,
                  }}
                  value={state.nama_produk}
                  fullWidth={true}
                  className={classes.inputStyle}
                  onChange={(event) =>
                    handleSetState("nama_produk", event.target.value)
                  }
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={3} className="label">
                Komisi reseller
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className={classes.inputStyle}
                  fullWidth={true}
                  value={state.comission_value}
                  InputProps={{
                    disableUnderline: true,
                    inputProps: { min: 10 },
                    endAdornment: (
                      <div className={classes.inputAdornment}>%</div>
                    ),
                  }}
                  type="number"
                  onChange={(event) =>
                    handleSetState("comission_value", event.target.value)
                  }
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={3} className="label">
                Kategori
              </Grid>
              <Grid item xs={4}>
                <TextField
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  className={`${classes.inputStyle} ${classes.inputSelectStyle}`}
                  fullWidth={true}
                  value={state.kategori.name}
                  disabled
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: <ArrowDropDownIcon />,
                  }}
                />
                <Popper
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  disablePortal={false}
                  modifiers={{
                    flip: {
                      enabled: false,
                    },
                    preventOverflow: {
                      enabled: true,
                      boundariesElement: "scrollParent",
                    },
                  }}
                >
                  <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Paper>
                      <Typography className={classes.typography}>
                        <List>
                          {category.map((res, index) => (
                            <Fragment>
                              <ListItem
                                button
                                onClick={() =>
                                  openChilderenCat === res.name
                                    ? setOpenChilderenCat("")
                                    : setOpenChilderenCat(res.name)
                                }
                              >
                                <ListItemText primary={res.name} />
                                {openChilderenCat === res.name ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItem>
                              <Collapse
                                in={openChilderenCat === res.name}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  {res.children.map((child, index) => (
                                    <ListItem
                                      button
                                      className={classes.nested}
                                      onClick={() => {
                                        handleSetState("kategori", child);
                                        setOpenChilderenCat(false);
                                        setAnchorEl(null);
                                      }}
                                    >
                                      <ListItemText primary={child.name} />
                                    </ListItem>
                                  ))}
                                </List>
                              </Collapse>
                            </Fragment>
                          ))}
                        </List>
                      </Typography>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={3} className="label">
                Tags
              </Grid>
              <Grid item xs={9}>
                <TextField
                  placeholder="Masukan tag produk"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <div className={classes.inputAdornment}>
                        {state.tags.length}/40
                      </div>
                    ),
                  }}
                  inputProps={{
                    maxLength: 40,
                  }}
                  value={state.tags}
                  fullWidth={true}
                  className={classes.inputStyle}
                  onChange={(event) =>
                    handleSetState("tags", event.target.value)
                  }
                />
                <Typography variant="caption" component="div">
                  <i>Pisahkan antar tag dengan koma ","</i>
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={3} className="label">
                Deskripsi
              </Grid>
              <Grid item xs={9}>
                <TextField
                  placeholder="Masukan deskripsi"
                  value={state.deskripsi}
                  className={classes.inputStyle}
                  InputProps={{ disableUnderline: true }}
                  multiline
                  rows={10}
                  fullWidth
                  onChange={(event) =>
                    handleSetState("deskripsi", event.target.value)
                  }
                />
              </Grid>
            </Grid>

            {/* gambar */}
            <br />
            <Grid container spacing={2}>
              <Grid item xs={3} className="label">
                Gambar
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={2} alignItems="center">
                  {state.images.map((image, i) => (
                    <Grid item key={i}>
                      <div
                        className={classes.tambahGambar}
                        style={{
                          backgroundImage: `url(${
                            image && URL.createObjectURL(image)
                          })`,
                        }}
                      >
                        {image === null && (
                          <Fragment>
                            <Typography className={classes.tambahGambar1}>
                              +
                            </Typography>
                          </Fragment>
                        )}
                        <input
                          type="file"
                          className={classes.inputGambar}
                          onChange={(event) => handleChangeImage(event, i)}
                        />
                      </div>
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        fullWidth
                        style={{ marginTop: 8 }}
                        onClick={() => handleDeleteImages(i)}
                      >
                        Hapus
                      </Button>
                    </Grid>
                  ))}
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        setState({
                          ...state,
                          images: [...state.images, null],
                        })
                      }
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* video */}
            <br/>
            <Grid container spacing={2}>
              <Grid item xs={3} className='label'>
                Video
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={2} alignItems='center'>
                  {
                    state.videos.map((video, index) => 
                    <Grid item key={index+'video'} style={{marginBottom: 10, width: 250}}>
                      <TextField
                        placeholder="Masukkan link Video Produk"
                        InputProps={{ disableUnderline: true }}
                        style={{
                          border: `1px solid #e5e5e5`,
                          borderRadius: 5,
                          font: 13,
                        }}
                        value={video}
                        fullWidth={true}
                        onChange={(event) =>
                          handleChangeVideo(event, index)
                        }
                      />
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        fullWidth
                        style={{ marginTop: 8 }}
                        onClick={() => {
                          handleDeleteVideos(index)
                        }}
                      >
                        Hapus
                      </Button>
                    </Grid>
                    )
                  }
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        state.videos.length === 1 ?
                        swal("Sorry", "For now there is only one video input", "error")
                        :
                        setState({
                          ...state,
                          videos: [...state.videos, null],
                        })
                      }}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {settings.diskon && (
              <div className={classes.flex} style={{ paddingTop: 20 }}>
                <div className={classes.flex} style={{ width: "50%" }}>
                  <div className={classes.textVar}>
                    <Typography style={{ width: 150 }}>
                      <b>Diskon</b>
                    </Typography>
                  </div>
                  <div className={classes.discount}>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      fullWidth={true}
                      onChange={(event) =>
                        handleSetState("diskon", event.target.value)
                      }
                    />
                    <div className={classes.discountPercent}>%</div>
                  </div>
                </div>
              </div>
            )}

            <div style={{ paddingTop: 30 }}>
              <h3>2. Detail Varian</h3>
              {isCustom ? (
                <CardCustomProduct
                  data={customVariant}
                  image={customProductImage}
                  handleChangePhoto={handleChangePhoto}
                  handleChangeCustomVariant={handleChangeCustomVariant}
                  handleAddCustomVariant={handleAddCustomVariant}
                  handleRemoveCustomVariant={handleRemoveCustomVariant}
                />
              ) : (
                <>
                  {state.detail_barang.map((res, index) => (
                    <div style={{ paddingTop: 20 }} key={res.code}>
                      <CardDetailBarang
                        index={index}
                        data={res}
                        handleChangePhoto={handleChangePhoto}
                        handleChangeSkus={handleChangeSkus}
                        handleRemoveSkus={handleRemoveSkus}
                        handleChangeSkusProperties={handleChangeSkusProperties}
                        handleCopySkus={handleCopySkus}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>

            {!isCustom && (
              <>
                <div
                  className={classes.flexWidthCenter}
                  style={{ paddingTop: 30, marginBottom: 24 }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    style={{
                      borderRadius: 30,
                      padding: "10px 20px 10px 20px",
                      backgroundColor: "#f4f4f4",
                      color: "#29C0C7",
                      border: "none",
                      fontWeight: 600,
                      textTransform: "none",
                    }}
                    onClick={handleTambahVarian}
                    startIcon={<AddIcon />}
                  >
                    Tambah Varian
                  </Button>
                </div>
                <Grid container spacing={4} alignItems="center">
                  <Grid item>Custom variasi</Grid>
                  <Grid item xs>
                    <Button
                      variant="outlined"
                      size="large"
                      fullWidth
                      style={{
                        border: "2px dashed #29C0C7",
                        borderRadius: 5,
                        color: "#29C0C7",
                        textTransform: "none",
                        fontWeight: 600,
                      }}
                      startIcon={<AddCircleIcon />}
                      onClick={() => setIsCustom(true)}
                    >
                      Aktifkan Custom Variasi
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Paper style={{ padding: 30 }}>
            <Typography style={{ color: "#c4c4c4" }}>
              Product Setting
            </Typography>

            <div className={classes.flexBetween}>
              <Typography>Varian</Typography>
              <Switch
                checked={settings.varian}
                onChange={() => handleSettings("varian", !settings.varian)}
                color="primary"
                name="varian"
                disabled
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
            <div className={classes.flexBetween}>
              <Typography>Diskon</Typography>
              <Switch
                checked={settings.diskon}
                onChange={() => handleSettings("diskon", !settings.diskon)}
                color="primary"
                name="diskon"
                disabled
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>

            <Typography style={{ color: "#c4c4c4" }}>
              Storefront Setting
            </Typography>
            <div className={classes.flexBetween}>
              <Typography>Publish</Typography>
              <Switch
                checked={settings.publish}
                onChange={() => handleSettings("publish", !settings.publish)}
                color="primary"
                name="publish"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
            <div className={classes.flexBetween}>
              <Typography>Show Stock</Typography>
              <Switch
                checked={settings.show_stock}
                onChange={() =>
                  handleSettings("show_stock", !settings.show_stock)
                }
                color="primary"
                name="show_stock"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
            <div className={classes.flexBetween}>
              <Typography style={{ color: "red", fontSize: "12px" }}>
                *Note : harap upload foto dalam ukuran kurang dari 1 mb untuk
                menghindari upload yang terlalu lama.
              </Typography>
            </div>
            <div style={{ paddingTop: 20, textAlign: "center" }}>
              <Button
                variant="contained"
                style={{ textTransform: "capitalize" }}
                color="primary"
                onClick={handleSimpanProduk}
                fullWidth
              >
                {loadingTambah ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Simpan produk"
                )}
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default TambahProduk;
