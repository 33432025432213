import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IMPORT_PRODUCTS } from "services/marketplace.service";
import ProductList from "./ProductList/ProductList";

const DialogImportProduct = (props) => {
  const { open, onClose, onProductImported } = props;
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectProduct = (id) => {
    setSelectedProducts((state) => {
      if (state.indexOf(id) === -1) {
        return [...state, id];
      } else {
        return [...state.filter((item) => item !== id)];
      }
    });
  };

  useEffect(() => {
    setSelectedProducts([]);
    setIsLoading(false);
  }, [open]);

  const handleImportProduct = () => {
    setIsLoading(true);

    IMPORT_PRODUCTS(selectedProducts).then((response) => {
      setIsLoading(false);

      onClose();

      onProductImported();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-import-product"
    >
      <DialogTitle id="dialog-import-product">Import Produk</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Pilih produk yang ingin di-import ke Idejualan, dengan otomatis produk
          kamu akan terinput sebagai produk Idejualan.
        </DialogContentText>

        <ProductList
          selectedProducts={selectedProducts}
          handleSelectProduct={handleSelectProduct}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Batal
        </Button>
        <Button
          color="secondary"
          onClick={handleImportProduct}
          disabled={isLoading}
        >
          {isLoading && (
            <Box mr={1} display="flex">
              <CircularProgress size={24} color="inherit" />
            </Box>
          )}
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogImportProduct;
