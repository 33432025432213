import styled from "styled-components";

export const DataTableBundlingWithTabsWrap = styled.div`
  position: relative;
  .PrivateTabIndicator-colorSecondary-20 {
    background-color: #fdca40;
  }
  .MuiTabs-indicator {
    background-color: #fdca40;
  }
  .PrivateTabIndicator-root-18 {
    height: 4px;
  }
  .MuiTab-wrapper {
    font-family: "DMSans-Bold";
    text-transform: initial;
    font-size: 18px;
    letter-spacing: 0.02em;
  }
  .Mui-selected {
    .MuiTab-wrapper {
      color: #fdca40;
    }
  }
  .MuiTabs-flexContainer {
    border-bottom: 1px solid #c4c4c4;
  }
  .MuiTab-root {
    padding: 16px;
  }
`;
