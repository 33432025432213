import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Paper,
  InputBase,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CircularProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { getSupplierCategories } from "../../../services/axios";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.yellow.tertiary,
    },
  },
}))(TableRow);

const CategoryProduct = withStyles(style)((props) => {
  const { classes } = props;
  const slug = JSON.parse(localStorage.getItem("DATA")).slug;
  const [state, setState] = useState({
    category: null,
    filteredCategory: null,
  });

  useEffect(() => {
    const fetch = async () => {
      const response = await getSupplierCategories(slug);
      setState(state => ({
        ...state,
        category: response.data,
        filteredCategory: response.data,
      }));
    };
    fetch();
  },[slug]);

  const handleSearch = (keyword) => {
    setState({
      ...state,
      filteredCategory: state.category.filter((value) =>
        value.name.toLowerCase().match(new RegExp(keyword.toLowerCase()))
      ),
    });
  };

  return (
    <Fragment>
      <div className={classes.header}>
        <Typography variant="h6">
          <b>Kategori Product</b>
        </Typography>
      </div>

      <Paper style={{ marginTop: 30 }}>
        <div style={{ display: "flex", padding: 20 }}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(event) => handleSearch(event.target.value)}
            />
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" width={50}>
                  No
                </StyledTableCell>
                <StyledTableCell align="left">Nama Kategori</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.category &&
                state.filteredCategory.map((row, index) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {state.filteredCategory && state.filteredCategory.length === 0 && (
          <div className={classes.notFound}>
            <div className={classes.notFound2}>
              <Typography variant="h5">
                <b>Oops!</b>
              </Typography>
              <Typography>Data tidak ditemukan</Typography>
            </div>
          </div>
        )}

        {!state.filteredCategory && (
          <div className={classes.notFound}>
            <CircularProgress />
          </div>
        )}
      </Paper>
    </Fragment>
  );
});

export default CategoryProduct;
