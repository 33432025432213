import { Typography } from "@material-ui/core";
import React, { useReducer, useState } from "react";
import Table from "./Table/Table";

const Announcements = () => {
  return (
    <div>
      <Table />
    </div>
  );
};

export default Announcements;
