import React from "react";
import { Button, Card, Title } from "components/global.style";
import { SocialMedia, WrapDetailSupplier } from "./style";
import iconWA from "../../assets/icons/whatsapp.svg";
import iconGmail from "../../assets/icons/gmail.svg";
import { Grid, Tooltip, Typography } from "@material-ui/core";

const DetailInfoSupplier = ({ user, onClick }) => {
  return (
    <WrapDetailSupplier>
      <Title>Detail Info Suplier</Title>
      <Card style={{ marginTop: 16 }}>
        <div className="profile">
          <div className="avatar">
            <img src={user.avatar_url} alt="supplier" />
          </div>
          <div className="info">
            <h2>{user.name}</h2>
            <p className="counterFollow">
              {user?.stats?.followers_count ?? "0"} Pengikut
            </p>
            <SocialMedia style={{ marginTop: 8 }}>
              <div className="icon">
                <img src={iconWA} alt="whatsapp" />
              </div>
              <div className="text">
                <p>{user.phone}</p>
              </div>
            </SocialMedia>
            <SocialMedia>
              <div className="icon">
                <img src={iconGmail} alt="gmail" />
              </div>
              <div className="text">
                <p>{user.email}</p>
              </div>
            </SocialMedia>
          </div>
        </div>
        <Title style={{ marginTop: 24 }}>Alamat</Title>
        <Typography style={{ paddingTop: 10 }}>
          {user?.location?.address + ", " + user?.location?.postal_code}
        </Typography>
        <Title style={{ marginTop: 24 }}>Jasa Pengiriman</Title>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          {user.supported_couriers.map((res, key) => (
            <Grid item key={key}>
              <Tooltip title={res.name} placement="bottom">
                <img src={res.icon_url} alt={res.name} height={25} />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        <div style={{ textAlign: "center", marginTop: 48 }}>
          <Button onClick={onClick}>Edit Info</Button>
        </div>
      </Card>
    </WrapDetailSupplier>
  );
};

export default DetailInfoSupplier;
