import styled from "styled-components";

export const WrapHistoryPage = styled.div`
  position: relative;
  .PrivateTabIndicator-colorSecondary-20 {
    background-color: #FDCA40;
  }
  .MuiTabs-indicator {
    background-color: #FDCA40;
  }
  .PrivateTabIndicator-root-18 {
    height: 4px;
  }
  .MuiTab-wrapper {
    font-family: 'DMSans-Bold';
    text-transform: initial;
    font-size: 18px;
    letter-spacing: 0.02em;
  }
  .Mui-selected {
    .MuiTab-wrapper {
      color: #FDCA40;
    }
  }
  .MuiTabs-flexContainer {
    border-bottom: 1px solid #C4C4C4;
  }
  .MuiTab-root{
    padding: 16px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
`;

export const WrapTable = styled.div`
  position: relative;
  height: 400px;
  width: 100%;
  .MuiDataGrid-root {
    position: relative;
    font-family: "DMSans-Regular";
    .MuiDataGrid-colCell {
      padding: 16px;
    }
    .MuiDataGrid-cell {
      font-size: 13px;
    }
    .MuiDataGrid-cell,
    .MuiDataGrid-row {
      min-height: 60px !important;
      max-height: 60px !important;
      line-height: 59px !important;
    }
    .MuiDataGrid-columnSeparator {
      display: none;
    }
    .MuiDataGrid-colCellTitle {
      font-family: "DMSans-Bold";
      font-size: 14px;
      color: #787878;
      letter-spacing: 0.02em;
    }
  }
`;

export const WrapCard = styled.div`
  display: flex;
  position: relative;
  div {
    width: 100%;
    padding: 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    span {
      display: block;
      :first-child {
        font-size: 12px;
      }
      :last-child {
        font-size: 18px;
      }
    }
    :nth-child(2) {
      margin: 0px 16px;
    }
  }
`;
