import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { Typography } from "@material-ui/core";
import Content from "./content";
import StatusOrder from "./status-order";
import { getAllOrdersCustom, getAllOrders } from "../../services/axios";
import Loading from "../../components/loading";

const Order = withStyles(style)((props) => {
  const { classes } = props;
  const [state, setState] = useState({
    order: null,
    filteredOrder: null,
  });

  useEffect(() => {
    const fetch = async () => {
      const response = await getAllOrdersCustom({
        paginate: 20,
      });
      setState((state) => ({
        ...state,
        order: response,
        filteredOrder: response.data,
      }));
    };
    fetch();
  }, []);

  return (
    <Fragment>
      <Fragment>
        {!state.order ? (
          <Fragment>
            <Loading />
          </Fragment>
        ) : (
          <Fragment>
            <div className={classes.header}>
              <Typography variant="h6">
                <b>Order Custom</b>
              </Typography>
            </div>

            <div style={{ paddingTop: 20 }}>
              <Content
                order={state.order}
                filteredOrder={state.filteredOrder}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    </Fragment>
  );
});

export default Order;
