const style = (theme) => ({
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  wrapperCard: {
    border: `2px solid ${theme.palette.green.main}`,
    borderRadius: 15,
    padding: "20px 15px",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  ubahPesanan: {
    backgroundColor: theme.palette.green.main,
    textTransform: "capitalize",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#35bd82",
    },
  },
  downloadLabel: {
    border: `1px solid ${theme.palette.green.main}`,
    color: theme.palette.green.main,
  },
  EditIcon: {
    height: 15,
    width: "auto",
  },
  flexBetween: {
    display: "flex",
    // alignItems: "center",
    justifyContent: "space-between",
  },
  Divider: {
    backgroundColor: theme.palette.green.main,
  },
  roundStepper: {
    backgroundColor: theme.palette.green.main,
    width: 25,
    height: 25,
    borderRadius: "50%",
    marginLeft: -5,
    zIndex: 2
  },
  stepperWrapper: {
    display: "flex",
    alignItems: "center",
  },
  stepper: {
    height: 10,
    width: 92,
    backgroundColor: theme.palette.green.main,
    marginLeft: -5,
    zIndex: 1
  },
  statusPembayaran: {
    border: `1px solid ${theme.palette.green.main}`,
    color: theme.palette.green.main,
    borderRadius: 30,
    width: 125,
    marginBottom: 10
  },
  inputStyle: {
    backgroundColor: "#E0FFF2",
    borderRadius: 30,
    paddingLeft: 10,
    width: 200
  },
  updateButton: {
    marginTop: 10,
    borderRadius: 30,
    textTransform: 'capitalize',
    color: "#fff"
  },
});

export default style;
