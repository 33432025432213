import { Grid } from "@material-ui/core";
import { Title } from "components/global.style";
import { DataTableFlashsaleWithTabs, WidgetProfile } from "containers";
import React from "react";
import { WrapPromo } from "./style";
import Notification from "../home/components/notifikasi";
import { VariantPromo } from "components";
import iconPriceTag from "../../assets/icons/price-tag.svg";
import iconGetCash from "../../assets/icons/get-cash.svg";
import iconCardboardBox from "../../assets/icons/cardboard-box.svg";
import iconVoucher from "../../assets/icons/voucher.svg";
import iconShopSale from "../../assets/icons/shop-sale.svg";
// import iconFreeShipping from "../../assets/icons/free-shipping.svg";

const PromoPage = (props) => {
  const listVariantPromo = [
    {
      title: "Potongan Harga",
      desc: `Berikan potongan harga untuk Resellermu & tingkatkan penjualanmu`,
      img: iconPriceTag,
      onClick: () => props.history.push("/promo/potongan-harga"),
    },
    {
      title: "Flashsale (Promo Singkat)",
      desc: `Berikan diskon dalam waktu terbatas di beberapa produk di tokomu`,
      img: iconShopSale,
      onClick: () => props.history.push("/promo/flashsale"),
    },
    {
      title: "Kupon",
      desc: `Berikan kupon ke pengikut setia tokomu & tingkatkan penjualanmu`,
      img: iconVoucher,
      onClick: () => props.history.push("/promo/voucher"),
    },
    {
      title: "Bundling (Paket Produk)",
      desc: `Jual lebih banyak produk sekaligus dengan Diskon Bundling`,
      img: iconCardboardBox,
      onClick: () => props.history.push("/promo/bundling"),
    },
    {
      title: "Cashback",
      desc: `Berikan hadiah saldo DompetJualan ke Reseller atas pembeliannya`,
      img: iconGetCash,
      onClick: null,
    },
  ];

  const _mappingVariantPromo = () => {
    const render = [];
    listVariantPromo.map((variant, index) => {
      render.push(
        <Grid item xs={6} key={index}>
          <VariantPromo
            img={variant.img}
            title={variant.title}
            desc={variant.desc}
            onClick={variant.onClick}
          />
        </Grid>
      );
      return "";
    });
    return render;
  };
  return (
    <WrapPromo>
      <Grid container spacing={2}>
        <Grid item xs={9} style={{ paddingRight: 20 }}>
          <Title>Promo Toko Saya</Title>
          <p>Gunakan Fitur Promosi untuk meningkatkan penjualan di Tokomu</p>
          <div style={{ paddingTop: 16 }}>
            <Grid container spacing={2}>
              {_mappingVariantPromo()}
            </Grid>
          </div>
          <div style={{ paddingTop: 16 }}>
            <DataTableFlashsaleWithTabs {...props} />
          </div>
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <WidgetProfile {...props} />
            </Grid>
            <Grid item xs={12}>
              <Notification {...props} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </WrapPromo>
  );
};

export default PromoPage;
