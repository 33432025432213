import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { updateExpenses } from "../../../services/axios";
import numberSeparator from "../../../utils/numberSeparator";
import swal from "sweetalert";

const EditPengeluaran = (props) => {
  const classes = useStyles();
  const { onClose, open, refreshPage, data } = props;
  const [state, setState] = useState({
    name: data.name,
    description: data.description,
    cost: data.cost,
    qty: data.qty,
  });

  const handleChangeState = (valueState, value) => {
    setState({ ...state, [valueState]: value });
  };

  const handleUpdate = async () => {
    const response = await updateExpenses(data.id, state);
    if (response.updated) {
      swal("Success", "Data berhasil diupdate", "success").then(() =>
        refreshPage()
      );
    }
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        <span className={classes.bold}>Edit Pengeluaran</span>
      </DialogTitle>
      <DialogContent>
        <div>
          <Typography>Nama Pengeluaran</Typography>
          <TextField
            InputProps={{ disableUnderline: true }}
            style={{ width: 500, marginTop: 5 }}
            className={classes.inputStyle}
            value={state.name}
            onChange={(e) => handleChangeState("name", e.target.value)}
          />
        </div>

        <div style={{ paddingTop: 20 }}>
          <Typography>Deskripsi</Typography>
          <TextField
            InputProps={{ disableUnderline: true }}
            style={{ width: 500, marginTop: 5 }}
            className={classes.inputStyle}
            value={state.description}
            onChange={(e) => handleChangeState("description", e.target.value)}
          />
        </div>

        <div style={{ paddingTop: 20 }}>
          <Typography style={{ paddingBottom: 10 }}>Pengeluaran</Typography>
          <div className={classes.flexBetween}>
            <div>
              <Typography
                variant="body2"
                style={{ paddingLeft: 10, paddingBottom: 5 }}
              >
                Harga
              </Typography>
              <TextField
                InputProps={{ disableUnderline: true }}
                style={{ width: 150 }}
                value={state.cost}
                className={classes.inputStyle}
                onChange={(e) => handleChangeState("cost", e.target.value)}
              />
            </div>

            <div>
              <Typography
                variant="body2"
                style={{ paddingLeft: 10, paddingBottom: 5 }}
              >
                Jumlah
              </Typography>
              <TextField
                InputProps={{ disableUnderline: true }}
                style={{ width: 150 }}
                value={state.qty}
                className={classes.inputStyle}
                onChange={(e) => handleChangeState("qty", e.target.value)}
              />
            </div>

            <div style={{ width: 150 }}>
              <Typography variant="body2" style={{ paddingBottom: 5 }}>
                Subtotal
              </Typography>
              <Typography>
                <b>Rp. {numberSeparator(state.cost * state.qty)}</b>
              </Typography>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 24, paddingRight: 24 }}>
        <Button
          variant="contained"
          color="secondary"
          style={{ borderRadius: 30, textTransform: "capitalize" }}
          onClick={() => onClose()}
        >
          Batal
        </Button>
        <Button className={classes.update} onClick={handleUpdate}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  bold: {
    fontWeight: "bold",
  },
  selecTanggal: {
    backgroundColor: "rgba(182, 202, 255, 0.38)",
    display: "flex",
    alignItems: "center",
    width: 150,
    justifyContent: "space-between",
    padding: "5px 24px 5px 12px",
    borderRadius: 30,
    cursor: "pointer",
    marginLeft: 10,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  inputStyle: {
    border: `1px solid #3DDC97`,
    borderRadius: 30,
    paddingLeft: 10,
  },
  update: {
    backgroundColor: "#3ddc97",
    borderRadius: 30,
    color: "#ffffff",
    paddingLeft: 30,
    paddingRight: 30,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#33b17a",
    },
  },
});

export default EditPengeluaran;
