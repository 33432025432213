import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Button,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
} from "@material-ui/core";
import TambahPengeluaran from "./components/tambah-pengeluaran";
import EditPengeluaran from "./components/edit-pengeluaran";
import { getExpenses, deleteExpenses } from "../../services/axios";
import customDate from "../../utils/customDate";
import numberSeparator from "../../utils/numberSeparator";
import swal from "sweetalert";
import nextDayDate from "../../utils/nextDaydate";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { ReactComponent as BinIcon } from "../../assets/icons/bin.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.yellow.tertiary,
    },
  },
}))(TableRow);

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

function createData(
  no,
  tanggal,
  nama_pengeluaran,
  harga,
  jumlah,
  subtotal,
  kelola
) {
  return {
    no,
    tanggal,
    nama_pengeluaran,
    harga,
    jumlah,
    subtotal,
    kelola,
  };
}

const Pengeluaran = withStyles(style)((props) => {
  const { classes } = props;
  const [state, setState] = useState({
    openTambahPengeluaran: false,
    openEditPengeluaran: false,
    expenses: null,
    selectedData: null,
  });
  const [filterTanggal] = useState({
    from: new Date(),
    to: nextDayDate(new Date()),
  });
  const handleChangeState = (valueState, value) => {
    setState({ ...state, [valueState]: value });
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await getExpenses();
      setState(state => ({ ...state, expenses: response }));
    };
    fetch();
  },[]);

  const handleEdit = (data) => {
    setState({ ...state, selectedData: data, openEditPengeluaran: true });
  };

  const renderDate = (date) => {
    const newDate = new Date(date);
    const Month = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    return (
      newDate.getDate() +
      " " +
      Month[newDate.getMonth()] +
      " " +
      newDate.getFullYear()
    );
  };

  const Kelola = (data) => {
    return (
      <div>
        <Tooltip title="Edit" placement="bottom">
          <StyledButton onClick={() => handleEdit(data.data)}>
            <EditIcon />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Hapus" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10 }}
            onClick={() => handleDeleteProduct(data.data.id)}
          >
            <BinIcon />
          </StyledButton>
        </Tooltip>
      </div>
    );
  };

  const refreshPage = async () => {
    const response = await getExpenses();
    setState({
      ...state,
      openEditPengeluaran: false,
      openTambahPengeluaran: false,
      expenses: response,
    });
  };

  const handleDeleteProduct = (id) => {
    swal({
      title: "Are you sure?",
      text: "Data akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteExpenses(id);
        swal("Data dihapus!", {
          icon: "success",
        }).then(() => refreshPage());
      } else {
        swal("Data batal dihapus");
      }
    });
  };

  const rows = () => {
    let result = [];

    state.expenses.data.forEach((res, index) => {
      result = [
        ...result,
        createData(
          index + 1,
          customDate(new Date(res.created_at)),
          res.name,
          res.cost,
          res.qty,
          res.cost * res.qty,
          <Kelola data={res} />
        ),
      ];
    });
    return result;
  };

  return (
    <Fragment>
      <div className={classes.header}>
        <Typography variant="h6">
          <b>Pengeluaran</b>
        </Typography>
        <div>
          <Button
            variant="contained"
            color="secondary"
            className={classes.tambahOrder}
            style={{ marginRight: 20 }}
            onClick={() => handleChangeState("openTambahPengeluaran", true)}
          >
            + Tambah Pengeluaran
          </Button>
        </div>
      </div>

      <Paper style={{ marginTop: 20 }}>
        <div style={{ display: "flex", padding: 20 }}>
          <div className={classes.bgFilter}>
            <Typography variant="caption">
              {renderDate(filterTanggal.from)}
            </Typography>
            <CalendarIcon />
          </div>
          <div className={classes.bgFilter}>
            <Typography variant="caption">
              {renderDate(filterTanggal.to)}
            </Typography>
            <CalendarIcon />
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell align="center">Tanggal</StyledTableCell>
                <StyledTableCell align="center">
                  Nama Pengeluaran
                </StyledTableCell>
                <StyledTableCell align="center">Harga / Biaya</StyledTableCell>
                <StyledTableCell align="center">Jumlah</StyledTableCell>
                <StyledTableCell align="center">Subtotal</StyledTableCell>
                <StyledTableCell align="center">Kelola</StyledTableCell>
              </TableRow>
            </TableHead>
            {state.expenses && state.expenses.data.length !== 0 && (
              <TableBody>
                {rows().map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.no}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.tanggal}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.nama_pengeluaran}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Rp. {numberSeparator(row.harga)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.jumlah}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Rp {numberSeparator(row.subtotal)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.kelola}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {state.expenses && state.expenses.data.length === 0 && (
          <div className={classes.notFound}>
            <div className={classes.notFound2}>
              <Typography variant="h5">
                <b>Oops!</b>
              </Typography>
              <Typography>Data tidak ditemukan</Typography>
            </div>
          </div>
        )}
      </Paper>

      <TambahPengeluaran
        onClose={() => handleChangeState("openTambahPengeluaran", false)}
        open={state.openTambahPengeluaran}
        refreshPage={refreshPage}
      />
      {state.selectedData && (
        <EditPengeluaran
          onClose={() => handleChangeState("openEditPengeluaran", false)}
          open={state.openEditPengeluaran}
          refreshPage={refreshPage}
          data={state.selectedData}
        />
      )}
    </Fragment>
  );
});

export default Pengeluaran;
