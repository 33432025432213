import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  TextField,
  Grid,
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";

import { Pagination } from "@material-ui/lab";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { getProducts } from "services/axios";

const ProductList = (props) => {
  const { onProductChange } = props;
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [meta, setMeta] = useState(null);
  const [params, setParams] = useState({
    paginate: 10,
  });

  const fetchData = useCallback(
    _.debounce((params) => {
      getProducts(params).then((response) => {
        setProducts(response.data);
        setMeta(response.meta);
        setLoading(false);
      });
    }, 500),
    []
  );

  useEffect(() => {
    setLoading(true);

    fetchData(params);
  }, [params, fetchData]);

  const handlePageChange = (event, page) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: page,
    }));
  };

  const handleSearch = (event) => {
    const search = event.target.value;

    setParams((prevParams) => ({
      ...prevParams,
      page: 1,
      "filter[name]": search,
    }));
  };

  return (
    <form noValidate autoComplete="off">
      <FormControl variant="standard" fullWidth>
        <OutlinedInput
          placeholder="Cari Produk"
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
      {loading ? (
        <Box
          display="flex"
          height={200}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : products.length > 0 ? (
        <List>
          {products.map((item) => (
            <ListItem dense button onClick={() => onProductChange(item)}>
              <ListItemAvatar>
                <Avatar src={item.image_url} />
              </ListItemAvatar>
              <ListItemText
                primary={item.name}
                secondary={`${item.skus.length} varian`}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          height={200}
          alignItems="center"
        >
          Tidak ada data
        </Box>
      )}
      {meta && (
        <Grid container justify="center">
          <Pagination
            page={meta.current_page}
            count={meta.last_page}
            onChange={handlePageChange}
          />
        </Grid>
      )}
    </form>
  );
};

export default ProductList;
