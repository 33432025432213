import { BankSection, Button, Card, Title } from "components/global.style";
import React from "react";

const RekeningBankComponent = ({ rekening, onClickEdit = null }) => {
  return (
    <div>
      <Title>Rekening Bank</Title>
      <Card style={{ marginTop: 16 }}>
        <p>
          Nomer Rekening ini akan digunakan saat proses{" "}
          <span className="black">pencairan dana DompetJualan</span>
        </p>
        <BankSection borderColor="none">
          <div className="cardBank">
            <div>
              <div className="title">{rekening.alias}</div>
              <div className="number">{rekening.account_number}</div>
              <div className="cardHolder">a.n {rekening.account_name}</div>
            </div>
          </div>
        </BankSection>
        <div style={{ textAlign: "center", marginTop: 48 }}>
          <Button onClick={onClickEdit}>Edit Rekening</Button>
        </div>
      </Card>
    </div>
  );
};

export default RekeningBankComponent;
