import { axiosInstance } from "../config/fetchAxios";

export const GET_ANNOUNCEMENTS = async (params) => {
  const url = `/api/v1/announcements`;

  return axiosInstance.get(url, { params: params });
};

export const ADD_ANNOUNCEMENTS = async (body) => {
  const url = `/api/v1/announcements`;

  return axiosInstance.post(url, body);
};

export const EDIT_ANNOUNCEMENTS = async (id, body) => {
  const url = `/api/v1/announcements/${id}`;

  return axiosInstance.post(url, { ...body, _method: "PATCH" });
};

export const DELETE_ANNOUNCEMENT = async (id) => {
  const url = `/api/v1/announcements/${id}`;

  return axiosInstance.delete(url);
};
