import { axiosInstance } from "../config/fetchAxios";

export const GET_WALLET_INFO = async () => {
  const url = `/api/v2/wallet`;
  const data = axiosInstance.get(url);
  return data;
};

export const GET_TRANSACTIONS = (status, page) => {
  const url = `/api/v2/wallet/transactions?filter[wallet_status]=${status}&page=${page}`;
  const data = axiosInstance.get(url);
  return data;
};

export const GET_WALLET_TRANSACTIONS = async (status, page) => {
  const url = `/api/v3/wallet/transactions?include=order&filter[status]=${status}&page=${page}`;
  const data = axiosInstance.get(url);
  return data;
};
