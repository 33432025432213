import React, { useEffect, useState } from "react";
import { Grid,Button } from "@material-ui/core";
import { DetailInfoSupplier, RekeningBank } from "components";
import Loading from "components/loading";
import { useAuthContext } from "contexts/AuthContextProvider";
import { isEmpty } from "lodash";
import { GET_USER_BANK } from "services/bank.service";
import { WidgetProfile } from "containers";
import EditInfo from "pages/info/components/edit-info";
import { getDetailSupplier,editSupplier } from "../../services/axios";
import Notification from "../home/components/notifikasi";
import swal from "sweetalert";


const InfoStorePage = (props) => {
  const { user } = useAuthContext();
  const [rekening, setRekening] = useState({});
  const [isOpenEditInfo, setIsOpenEditInfo] = useState(false);
  const [dataSupplier, setDataSupplier] = useState({});
  const onClickEditRekening = () => {
    props.history.push("/info/edit-rekening");
  };

  const handleToggleEditInfo = async () => {
    await getDetailSupplier(user.slug).then((response) => {
      setDataSupplier(response.data);
      setIsOpenEditInfo(!isOpenEditInfo);
    });
  };

  useEffect(() => {
    const fetchBank = async () => {
      const getBank = await GET_USER_BANK();
      setRekening(getBank.data);
    };
    fetchBank();
  }, []);

  useEffect(() => {
    const fetchSupplier = async () => {
      await getDetailSupplier(user.slug).then((response) => {
        setDataSupplier(response.data);
      });
    };
    user.slug !== undefined && fetchSupplier();
  }, [user]);
  return (
    <div>
      {isOpenEditInfo && (
        <EditInfo handleClose={handleToggleEditInfo} dataApi={dataSupplier} />
      )}
      {!isOpenEditInfo && (
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Grid container spacing={3}>
              <Grid item xs={7}>
                {!isEmpty(dataSupplier) ? (
                  <DetailInfoSupplier
                    user={dataSupplier}
                    onClick={handleToggleEditInfo}
                  />
                ) : (
                  <Loading />
                )}
              </Grid>
              <Grid item xs={5}>
                <Grid item xs={12}>
                  {!isEmpty(rekening) ? (
                    <RekeningBank
                      rekening={rekening}
                      onClickEdit={onClickEditRekening}
                    />
                  ) : (
                    <Loading />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <WidgetProfile {...props} />
            </Grid>
            <Grid item xs={12}>
              <Notification {...props} />
            </Grid>
          </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default InfoStorePage;
