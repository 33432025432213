import styled from "styled-components";

export const WrapModal = styled.div`
  border-radius: 10px;
`;

export const InputSaldo = styled.div`
  margin-top: 16px;

  .MuiInput-underline::before {
    border-bottom: none !important;
  }
  .MuiInput-underline::after {
    border-bottom: none !important;
  }

  .MuiFormLabel-root{
    font-size: 16px;
    color: #000;
    margin-bottom: 8px;
  }

  label {
    font-size: 13px;
    color: #000;
  }

  .before {
    background: #ECECEC;
    border-radius: 5px 0px 0px 5px;
    padding: 12px 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  input{
    padding: 12px 16px;
    background: #F6F6F6;
    border-radius: 0px 5px 5px 0px;
    color: #888;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .MuiFormControl-root {
    width: 100%;
  }
`;

export const BankSection = styled.div`
  position: relative;
  margin-top: 16px;

  .title {
    font-size: 13px;
    color: #000;
  }

  .cardBank {
    border-radius: 5px;
    background: #F7F7F7;
    border: 1px solid #FBBD1C;
    margin-top: 8px;
    margin-bottom: 32px;
    padding: 16px;
    .title {
      font-size: 20px;
      color: #333;
      letter-spacing: 0.02em;
    }
    .number{
      font-size: 26px;
      font-family: 'DMSans-Bold';
      font-weight: 700;
      color: #333;
      letter-spacing: 0.02em;
    }
    .cardHolder {
      letter-spacing: 0.02em;
      font-size: 16px;
      color: #888;
    }
    .editRekening {
      background: #FFF4D6;
      padding: 16px;
      text-align: center;
      color: #787878;
      font-size: 20px;
      border-radius: 10px;
      :hover {
        cursor: pointer;
      }
    }
  }

  button {
    outline: 0;
    border: 0;
    background: #FBBD1C;
    border-radius: 5px;
    font-size: 20px;
    color: #FFF;
    font-family: 'DMSans-Bold';
    letter-spacing: 0.02em;
    padding: 17px;
    text-align: center;
    width: 100%;
  }
`

export const OTPWrap = styled.div`
  position: relative;
  width: max-content;
  margin: 0 auto;
  margin-top: 32px;

  .containerOtp {
    margin-bottom: 32px;
    input {
      padding: 8px;
      font-size: 24px;
      margin: 8px;
    }
  }
  button {
      outline: 0;
      border: 0;
      background: #FBBD1C;
      border-radius: 5px;
      font-size: 20px;
      color: #FFF;
      font-family: 'DMSans-Bold';
      letter-spacing: 0.02em;
      padding: 17px;
      text-align: center;
      width: 100%;
    }
`