import React, { useEffect, useState } from "react";
import WidgetProfileComponent from "./WidgetProfile.component";
import { isEmpty } from "lodash";
import { GET_WALLET_INFO } from "services/wallet.service";
import { ModalWithdraw } from "containers";
import numberSeparator from "utils/numberSeparator";

const WidgetProfileContainer = (props) => {
  const [dataProfile, setDataProfile] = useState({});
  const [walletInfo, setWalletInfo] = useState({});
  const [isOpenModalWithdraw, setIsOpenModalWithdraw] = useState(false);
  const [isRefetchWallet, setIsRefetchWallet] = useState(false)

  const toggleModalWithdraw = () => {
    setIsOpenModalWithdraw(!isOpenModalWithdraw);
  };

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("DATA"));
    if (!isEmpty(localStorageData)) {
      setDataProfile(localStorageData);
    }

    const fetch = async () => {
      const getWalletInfo = await GET_WALLET_INFO();
      getWalletInfo.data.amount_available = numberSeparator(getWalletInfo.data.amount_available);
      setWalletInfo(getWalletInfo.data);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const getWalletInfo = await GET_WALLET_INFO();
      setWalletInfo(getWalletInfo.data);
      setIsRefetchWallet(false)
    };
    isRefetchWallet && fetch();
  }, [isRefetchWallet]);

  return (
    <div>
      <WidgetProfileComponent
        dataProfile={dataProfile}
        walletInfo={walletInfo}
        toggleModalWithdraw={toggleModalWithdraw}
      />
      {
        isOpenModalWithdraw && 
        <ModalWithdraw
          isOpen={isOpenModalWithdraw}
          handleClose={toggleModalWithdraw}
          walletInfo={walletInfo}
          setIsRefetch={setIsRefetchWallet}
          {...props}
        />
      }
    </div>
  );
};

export default WidgetProfileContainer;
