import { axiosInstance } from "../config/fetchAxios";

export const GET_ALL_DISCOUNT = (include, paginate) => {
  const url = `/api/v2/promotions/discounts?include=product,product.skus,product.category&paginate=20`
  const params = { include, paginate }

  include ? params.include = include : params.include = `product,product.skus,product.category`
  paginate ? params.paginate = paginate : params.paginate = 20

  const response = axiosInstance.get(url, {
    params: params
  })

  return response
}

export const CREATE_DISCOUNT = body => {
  const url = `/api/v2/promotions/discounts`
  const response = axiosInstance.post(url, body)
  return response
}

export const DELETE_DISCOUNT = id => {
  let getToken = localStorage.getItem("TOKEN");
  const url = `/api/v2/promotions/discounts`
  const response = axiosInstance({
    method: 'delete',
    url: url,
    headers: {
      Authorization: `Bearer `+getToken,
      'Content-Type': 'application/json'
    },
    data: {
      "product_slug": id
    }
  })
  return response
}