import React from "react";
import style from "./FormStyle";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Paper,
  Button,
  TextField,
  Box,
  IconButton,
  Select,
  MenuItem,
  ListSubheader,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import swal from "sweetalert";
import {
  addEProduct,
  editEProduct,
  getEProductCategory,
} from "../../../services/axios";

const Form = withStyles(style)(
  ({ classes, setActiveState, selectedProduct, activeState }) => {
    const [product, setProduct] = React.useState({
      name: "",
      price: "",
      description: "",
      secret_link: "",
      category_id: "",
      images: [""],
    });
    const [categories, setCategories] = React.useState([]);
    const [deletedImages, setDeletedImages] = React.useState([]);
    const dataSupplier = JSON.parse(localStorage.getItem("DATA"));

    const getCategories = async () => {
      const res = await getEProductCategory();

      if (res.data) {
        setCategories(res.data);
      }
    };

    React.useEffect(() => {
      getCategories();
    }, []);

    React.useEffect(() => {
      if (activeState === "edit" && selectedProduct) {
        setProduct({
          category_id: selectedProduct.category.id,
          description: selectedProduct.description,
          name: selectedProduct.name,
          price: selectedProduct.price,
          secret_link: selectedProduct.secret_link,
          images: selectedProduct.images,
        });
      }
    }, []);

    const handleProductState = (key, value) => {
      setProduct((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    };

    const handleSubmit = async () => {
      const { name, price, description, secret_link, images, category_id } =
        product;

      const filteredImages = images.filter((val) => val !== "");

      if (name === "") {
        swal("Oops!", "Silahkan input Nama Produk.", "warning");
      } else if (category_id === "") {
        swal("Oops!", "Silahkan input Kategori Produk.", "warning");
      } else if (price === "") {
        swal("Oops!", "Silahkan input Harga Produk.", "warning");
      } else if (description === "") {
        swal("Oops!", "Silahkan input Deskripsi Produk.", "warning");
      } else if (secret_link === "") {
        swal("Oops!", "Silahkan input Link Produk.", "warning");
      } else if (filteredImages.length === 0) {
        swal(
          "Oops!",
          "Silahkan masukan gambar produk minimal 1 (satu)",
          "warning"
        );
      }

      const formData = new FormData();

      formData.append("name", product.name);
      formData.append("supplier_id", dataSupplier.id);
      formData.append("category_id", product.category_id);
      formData.append("description", product.description);
      formData.append("price", product.price);
      formData.append("secret_link", product.secret_link);

      if (activeState === "edit") {
        formData.append("_method", "put");
        product.images.forEach((image) => {
          if (image instanceof File) {
            formData.append("new_images[]", image);
          } else if (image.url instanceof File) {
            formData.append(`update_images[${image.id}]`, image.url);
          }
        });
        if (deletedImages.length > 0) {
          deletedImages.forEach((image) => {
            formData.append("remove_images", image);
          });
        }
      } else {
        product.images.forEach((image) => {
          formData.append("images[]", image);
        });
      }

      const response =
        activeState === "add"
          ? await addEProduct(formData)
          : await editEProduct(formData, selectedProduct.slug);

      if (response.data) {
        swal("Success", "Produk berhasil ditambahkan", "success").then(() => {
          setActiveState("list");
        });
      } else {
        swal("Success", "Produk gagal ditambahkan", "warning");
      }
    };

    const handleChangeImage = async (event, index) => {
      const validImage = ["image/jpeg", "image/png"];
      if (event.target.files && event.target.files.length > 0) {
        const newProduct = { ...product };

        newProduct.images[index] = event.target.files[0];

        setProduct(newProduct);
      } else alert("Gambar yang di upload tidak valid");
    };

    const handleChangeImageEdit = async (event, id) => {
      if (event.target.files && event.target.files.length > 0) {
        const newProduct = { ...product };

        const index = newProduct.images.findIndex((image) => image.id === id);

        if (index >= 0) {
          newProduct.images[index].url = event.target.files[0];
        }

        setProduct(newProduct);
      } else alert("Gambar yang di upload tidak valid");
    };

    const handleAddImage = () => {
      setProduct((prevState) => ({
        ...prevState,
        images: [...prevState.images, ""],
      }));
    };

    const handleDeleteImage = (index, imageId) => {
      setProduct((prevState) => ({
        ...prevState,
        images: prevState.images.filter((val, i) => i !== index),
      }));

      if (imageId) {
        setDeletedImages([...deletedImages, imageId]);
      }
    };

    const renderCategories = () => {
      const items = [];

      categories.forEach((category) => {
        if (category.children.length > 0) {
          items.push(<ListSubheader>{category.name}</ListSubheader>);
        }

        category.children.forEach((child) => {
          items.push(
            <MenuItem key={child.id} value={child.id}>
              {child.name}
            </MenuItem>
          );
        });
      });

      return items;
    };

    return (
      <div>
        <div className={classes.header}>
          <Typography variant="h6" className={classes.flexCenter}>
            <ArrowBackIcon
              style={{ paddingRight: 10, cursor: "pointer" }}
              onClick={() => setActiveState("list")}
            />
            <b>{activeState === "edit" ? "Edit" : "Tambah"} E-Produk</b>
          </Typography>
        </div>
        <Paper elevation={2} style={{ padding: 30 }}>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Nama Produk
            </Grid>
            <Grid item xs={9}>
              <TextField
                placeholder="Masukan nama produk"
                InputProps={{
                  disableUnderline: true,
                }}
                value={product.name}
                fullWidth={true}
                className={classes.inputStyle}
                onChange={(event) =>
                  handleProductState("name", event.target.value)
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Kategori Produk
            </Grid>
            <Grid item xs={9}>
              <Select
                value={product.category_id}
                onChange={(e) =>
                  e.target.value &&
                  handleProductState("category_id", e.target.value)
                }
                variant="outlined"
                fullWidth
              >
                <MenuItem value="" disabled>
                  Pilih kategori
                </MenuItem>
                {renderCategories()}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Harga
            </Grid>
            <Grid item xs={9}>
              <TextField
                placeholder="Masukan harga produk"
                InputProps={{
                  disableUnderline: true,
                }}
                type="number"
                value={product.price}
                fullWidth={true}
                className={classes.inputStyle}
                onChange={(event) =>
                  handleProductState("price", event.target.value)
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Link
            </Grid>
            <Grid item xs={9}>
              <TextField
                placeholder="Masukan link produk"
                InputProps={{
                  disableUnderline: true,
                }}
                inputProps={{
                  maxLength: 100,
                }}
                value={product.secret_link}
                fullWidth={true}
                className={classes.inputStyle}
                onChange={(event) =>
                  handleProductState("secret_link", event.target.value)
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Deskripsi
            </Grid>
            <Grid item xs={9}>
              <TextField
                placeholder="Masukan deskripsi produk"
                InputProps={{
                  disableUnderline: true,
                }}
                value={product.description}
                fullWidth={true}
                className={classes.inputStyle}
                onChange={(event) =>
                  handleProductState("description", event.target.value)
                }
                multiline
                rows={10}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Gambar Produk
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginTop: 20 }}
          >
            {product.images.map((image, i) => {
              if (activeState === "add") {
                return (
                  <Grid item>
                    <div
                      className={classes.tambahGambar}
                      style={{
                        backgroundImage: `url(${
                          image !== "" && URL.createObjectURL(image)
                        })`,
                      }}
                    >
                      {image === "" && (
                        <>
                          <Typography className={classes.tambahGambar1}>
                            +
                          </Typography>
                        </>
                      )}
                      <input
                        type="file"
                        className={classes.inputGambar}
                        onChange={(event) => handleChangeImage(event, i)}
                      />
                    </div>
                    {product.images.length > 1 && (
                      <Box mt={1}>
                        <IconButton onClick={() => handleDeleteImage(i)}>
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Grid>
                );
              } else {
                return (
                  <Grid item>
                    <div
                      className={classes.tambahGambar}
                      style={{
                        backgroundImage: `url(${
                          image.url
                            ? image.url instanceof File
                              ? URL.createObjectURL(image.url)
                              : image.url
                            : image !== "" && URL.createObjectURL(image)
                        })`,
                      }}
                    >
                      {image === "" && (
                        <>
                          <Typography className={classes.tambahGambar1}>
                            +
                          </Typography>
                        </>
                      )}
                      <input
                        type="file"
                        className={classes.inputGambar}
                        onChange={(event) =>
                          image.url
                            ? handleChangeImageEdit(event, image.id)
                            : handleChangeImage(event, i)
                        }
                      />
                    </div>
                    {product.images.length > 1 && (
                      <Box mt={1}>
                        <IconButton
                          onClick={() => handleDeleteImage(i, image.id)}
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Grid>
                );
              }
            })}
            <Grid item>
              <IconButton onClick={handleAddImage}>
                <AddCircleOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            justify="flex-end"
            style={{ marginTop: 20 }}
          >
            <Button onClick={handleSubmit} color="primary" variant="contained">
              Simpan
            </Button>
          </Grid>
        </Paper>
      </div>
    );
  }
);

export default Form;
