import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { FormBundling, WidgetProfile } from "containers";
import Notification from "../../../home/components/notifikasi";
import { ArrowBack } from "@material-ui/icons";

const CreateBundling = (props) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={9} style={{ paddingRight: 20 }}>
          <Typography
            variant="h6"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ArrowBack
              style={{ paddingRight: 10, cursor: "pointer" }}
              onClick={() => window.history.back()}
            />
            <b>Tambah Bundling</b>
          </Typography>
          <div style={{ paddingTop: 16 }}>
            <FormBundling {...props} />
          </div>
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <WidgetProfile {...props} />
            </Grid>
            <Grid item xs={12}>
              <Notification {...props} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateBundling;
