import React, { useContext, useEffect, useState } from 'react';
import { getDetailSupplier } from '../services/axios'

export const AuthContext = React.createContext()
export const useAuthContext= () => useContext(AuthContext)

export const AuthContextProvider = (props) => {
  const { children } = props
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState({})

  const logout = () => {
    setUser({})
    setIsAuthenticated(false)
  }

  const refetchSupplier = async () => {
    const getLocalStorageUser = JSON.parse(localStorage.getItem('DATA'))
    await getDetailSupplier(getLocalStorageUser.slug).then(res => {
      localStorage.setItem("DATA", JSON.stringify(res.data));
      setUser(res.data)
    })
  }

  useEffect(() => {
    const getLocalStorageUser = JSON.parse(localStorage.getItem('DATA'))
    if(getLocalStorageUser) {
      setUser(getLocalStorageUser)
      setIsAuthenticated(true)
    }
  }, [])
  
  return (
   <AuthContext.Provider
    value={{
      isAuthenticated,
      user,
      setUser,
      setIsAuthenticated,
      logout,
      refetchSupplier
    }}
   >
     {children}
   </AuthContext.Provider>
  );
};