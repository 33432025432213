const customDate = (date) => {
  if (date.constructor === String) {
    date = new Date(date);
  }

  const Day = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"];
  const Month = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  const returnData = `${Day[date.getDay()]}, ${
    date.getDate() + " " + Month[date.getMonth()]
  } (${date.getHours() + ":" + date.getMinutes()})`;
  return returnData;
};

export default customDate;
