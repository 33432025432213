const style = (theme) => ({
  root: {
    position: "relative",
    marginTop: theme.spacing(1),

    "& .PrivateTabIndicator-colorSecondary-20": {
      backgroundColor: "#fdca40",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#fdca40",
    },
    "& .PrivateTabIndicator-root-18": {
      height: "4px",
    },
    "& .MuiTab-wrapper": {
      fontFamily: "DMSans-Bold",
      textTransform: "initial",
      fontSize: "18px",
      letterSpacing: "0.02em",
    },
    "& .Mui-selected": {
      "& .MuiTab-wrapper": {
        color: "#fdca40",
      },
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "1px solid #c4c4c4",
    },
    "& .MuiTab-root": {
      padding: "16px",
    },
  },
});

export default style;
