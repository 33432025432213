import styled from "styled-components"

export const WrapWidgetProfile = styled.div`
  position: relative;

  .title {
    font-family: 'DMSans-Bold';
    font-size: 20px;
    color: #050505;
  }
  .dompetJualan {
    display: flex;
    margin-top: 16px;
    padding: 15px 10px;
    border-radius: 10px;
    background: #F7F7F7;
    .icon {
      padding: 8px;
      border-radius: 10px;
      border: 1px solid #FDCA40;
      width: 20px;
      height: 20px;
      img{ width: 100%; }
    }
    .text {
      margin-left: 14px;
      p {padding: 0; margin: 0;}
      p:nth-child(1) {
        font-size: 16px;
        color: #787878;
        letter-spacing: 0.02em;
        font-family: 'DMSans-Regular'
      }
      p:nth-child(2) {
        margin-top: 4px;
        font-size: 16px;
        color: #050505;
        letter-spacing: 0.02em;
        font-family: 'DMSans-Bold'
      }
    }
  }
  
  .btnIcon {
    position: relative;
    padding: 8px;
    text-align: center;
    font-size: 16px;
    color: #050505;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: none;
    width: 100%;
    margin-top: 10px;
    background: #FFF;
    outline: none;

    .bodyBtnIcon {
      display: flex;
      margin: 0 auto;
      width: max-content;
      align-items: center;
    }

    .icon {
      width: 24px;
      height: 24px;
      img {width: 100%}
      margin-right: 8px;
    }
  }

  .statusToko{
    text-align: center;
    margin: 10px 0 10px 0;
  }
`