import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  TextField,
} from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";
import TableContent from "./TableContent";
import { GET_CONNECTED_CHANNEL } from "services/marketplace.service";

const useTableStyles = makeStyles({
  container: {
    padding: "20px",
  },
  filterStatus: {
    marginLeft: "10px",
    minWidth: "200px",
  },
});

const Table = (props) => {
  const classes = useTableStyles();
  const [search, setSearch] = React.useState("");
  const [channel, setChannel] = React.useState("all");
  const [availableChannels, setAvailableChannels] = React.useState([]);

  const { onConnectProduct, updateTable } = props;

  useEffect(() => {
    GET_CONNECTED_CHANNEL().then(({ data }) => {
      setAvailableChannels(Object.keys(data));
    });
  }, [updateTable]);

  return (
    <Paper elevation={3} style={{ marginTop: 20 }}>
      <div className={classes.container}>
        <FormControl variant="standard">
          <OutlinedInput
            placeholder="Cari Produk"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <TextField
          select
          label="Marketplace"
          variant="outlined"
          className={classes.filterStatus}
          value={channel}
          onChange={(event) => setChannel(event.target.value)}
        >
          <MenuItem value="all">Semua</MenuItem>
          {availableChannels.map((channel) => (
            <MenuItem key={channel} value={channel}>
              {channel[0].toUpperCase() + channel.slice(1)}
            </MenuItem>
          ))}
        </TextField>
      </div>

      {/* Table */}
      <TableContent
        search={search}
        channel={channel !== "all" ? channel : null}
        onConnectProduct={onConnectProduct}
        {...props}
      />
    </Paper>
  );
};

export default Table;
