import { Profile } from 'components';
import React from 'react';
import { WrapWidgetProfile } from './style';
import iconWalletYellow from '../../assets/icons/wallet_yellow.svg'
import iconWithdraw from '../../assets/icons/withdraw_cash.svg'
import iconHistoryLine from '../../assets/icons/iconHistoryLine.svg'
import { useHistory } from 'react-router';
import { formatRupiah } from 'utils/currencyFormat';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import CancelIcon from '@material-ui/icons/Cancel';
import { getDetailSupplier,editSupplier } from "../../services/axios";
import swal from "sweetalert";

const WidgetProfileComponent = ({dataProfile, walletInfo, toggleModalWithdraw}) => {
  const history = useHistory()
  let saldo = walletInfo?.amount_available ?? 0

  const swalClose = () => {
    swal({
      title: "Apakah Anda Yakin ingin meliburkan toko?",
      text: "ketika libur, anda tidak dapat menerima pesanan dan semua barang anda akan dihilangkan untuk sementara waktu.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        SendCloseShop();
      } else {
        swal("Dibatalkan!");
      }
    });
  }

  const swalOpen = () => {
    swal({
      title: "Apakah Anda Yakin ingin membuka toko?",
      text: "ketika buka, anda akan dapat menerima pesanan kembali seperti semula.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        SendOpenShop();
      } else {
        swal("Dibatalkan!");
      }
    });
  }

  const SendOpenShop = async () => {
    const body = {
      _method: 'PATCH',
      is_offline: 0
    }
    const response = await editSupplier(dataProfile.slug, body);
      if (response.updated) {
        swal("Toko Anda Berhasil Dibuka!", {
          icon: "success",
        })
        .then((open) => {
          if (open) {
            window.location.href = '/info'
          }
        });

        // update local Storage
        const updateLocalStorage = async() => {
          await getDetailSupplier(dataProfile.slug).then(res => {
            console.log(res.data)
            localStorage.setItem("DATA", JSON.stringify(res.data));
          })
        }
        updateLocalStorage()
      }
  }
  
  const SendCloseShop = async () => {
    const body = {
      _method: 'PATCH',
      is_offline: 1
    }
    const response = await editSupplier(dataProfile.slug, body);
      if (response.updated) {
        swal("Toko Anda Berhasil Diliburkan!", {
          icon: "success",
        }).then((close) => {
          if (close) {
            window.location.href = '/info'
          }
        });

        // update local Storage
        const updateLocalStorage = async() => {
          await getDetailSupplier(dataProfile.slug).then(res => {
            localStorage.setItem("DATA", JSON.stringify(res.data));
          })
        }
        updateLocalStorage()
      }
  }

  return (
    <div>
      <Profile data={dataProfile} style={{marginBottom: 28}} />
      <WrapWidgetProfile>
        <div className='title'>
          Status Toko
        </div>
        <div className='statusToko'>
            <div 
            style={{
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center', 
              backgroundColor: '#F7F7F7', 
              borderRadius: '10px',
              cursor: 'pointer'
              }}
            onClick={
              dataProfile.is_offline ? swalOpen : swalClose
            }
            >
              {
                dataProfile.is_offline ?
                <CancelIcon style={{color: 'red'}}/>
                :
                <Brightness1Icon style={{color: 'green'}}/>
              }
              <p>{dataProfile.is_offline ? <>Toko Libur</> : <>Toko Buka</>}</p>
            </div>
        </div>
        <div className='title'>
          Saldo Jualan
        </div>
        <div className='dompetJualan'>
          <div className='icon'>
            <img src={iconWalletYellow} alt='dompet-jualan' />
          </div>
          <div className='text'>
            <p>DompetJualan</p>
            <p>Rp. {formatRupiah(saldo)}</p>
          </div>
        </div>
        <button className="btnIcon" onClick={toggleModalWithdraw}>
          <div className="bodyBtnIcon">
          <div className="icon">
            <img src={iconWithdraw} alt='withdraw' />
          </div>
          Cairkan
          </div>
          </button>
          <button className="btnIcon" onClick={() => history.push('/history-transaction')}>
          <div className="bodyBtnIcon">
          <div className="icon">
            <img src={iconHistoryLine} alt='riwayat' />
          </div>
            Riwayat Transaksi
          </div>
          </button>
      </WrapWidgetProfile>
    </div>
  );
};

export default WidgetProfileComponent;