import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import swal from "sweetalert";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import {
  ADD_ANNOUNCEMENTS,
  EDIT_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS,
} from "../../../services/aanouncements.service";

const DialogAddAnnouncement = withStyles(style)((props) => {
  const {
    classes,
    onClose,
    open,
    type,
    handleRefreshData,
    selectedPayload,
    handleSelectedPayload,
  } = props;
  const [payload, setPayload] = useState({
    title: "",
    message: "",
    tags: "",
    link: "",
  });

  React.useEffect(() => {
    if (selectedPayload) {
      setPayload({
        id: selectedPayload.id,
        title: selectedPayload.title,
        message: selectedPayload.message,
        tags: selectedPayload.tags,
        link: selectedPayload.link,
      });
    }

    return () => handleSelectedPayload(undefined);
  }, []);

  const handleChangePayload = (valueState, value) => {
    setPayload({ ...payload, [valueState]: value });
  };

  const handleSubmit = async () => {
    const response = selectedPayload
      ? await EDIT_ANNOUNCEMENTS(selectedPayload.id, {
          title: payload.title,
          message: payload.message,
          tags: payload.tags,
          link: payload.link,
        })
      : await ADD_ANNOUNCEMENTS(payload);
    response.data &&
      swal("Success", "Data ditambahkan", "success")
        .then(() => handleRefreshData())
        .then(() => onClose())
        .catch(() => swal("Error", "Data gagal ditambahkan", "warning"));
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {type === "add" ? "Tambah" : "Edit"} Pengumuman
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 500 }}>
            <div>
              <TextField
                required
                id="outlined-required"
                fullWidth
                label="Title"
                onChange={(e) => handleChangePayload("title", e.target.value)}
                value={payload.title}
                variant="outlined"
              />
            </div>

            <div className={classes.range}>
              <TextField
                required
                id="outlined-required"
                fullWidth
                label="Message"
                onChange={(e) => handleChangePayload("message", e.target.value)}
                value={payload.message}
                variant="outlined"
              />
            </div>
            <div className={classes.range}>
              <TextField
                id="outlined-required"
                fullWidth
                label="Tags"
                onChange={(e) =>
                  handleChangePayload(
                    "tags",
                    e.target.value.replace(" ", "").split(",")
                  )
                }
                value={payload.tags}
                placeholder="Pisah antar tag dengan koma"
                variant="outlined"
              />
            </div>
            <div className={classes.range}>
              <TextField
                id="outlined-required"
                fullWidth
                label="link"
                onChange={(e) => handleChangePayload("link", e.target.value)}
                value={payload.link}
                placeholder="Link Untuk produk/admin (Opsional)"
                variant="outlined"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: 24, paddingBottom: 24 }}>
          <Button variant="contained" color="secondary" onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});

export default DialogAddAnnouncement;
