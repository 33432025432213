import { Button, Grid } from "@material-ui/core";
import Loading from "components/loading";
import useECourse from "hooks/useECourse";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { GET_ECOURSES } from "services/e-course.service";
import ECourseItem from "../ECourseItem/ECourseItem";
import axios from "axios";

const ECourseList = () => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [search] = useECourse("search");
  const [selectedCategory] = useECourse("selectedCategory");
  const cancelTokenRef = useRef();

  const getData = useCallback(
    _.debounce(({ search, selectedCategory }) => {
      if (cancelTokenRef.current) cancelTokenRef.current.cancel("canceled");

      const cancelToken = axios.CancelToken.source();
      cancelTokenRef.current = cancelToken;

      GET_ECOURSES(
        {
          "filter[category.id]": selectedCategory ? selectedCategory : null,
          "filter[title]": search,
        },
        {
          cancelToken: cancelToken.token,
        }
      )
        .then(({ data, meta }) => {
          setData(data);
          setMeta(meta);
          setIsLoading(false);
          cancelTokenRef.current = null;
        })
        .catch((err) => {});
    }, 500),
    []
  );

  useEffect(() => {
    setIsLoading(true);

    getData({ search, selectedCategory });
  }, [search, selectedCategory, getData]);

  const loadMore = () => {
    setIsLoadMoreLoading(true);
    GET_ECOURSES({
      "filter[category.id]": selectedCategory ? selectedCategory : null,
      page: meta.current_page + 1,
    }).then(({ data, meta }) => {
      setData((state) => [...state, ...data]);
      setMeta(meta);
      setIsLoadMoreLoading(false);
    });
  };

  return isLoading ? (
    <Loading style={{ height: 500 }} />
  ) : (
    <>
      <Grid container spacing={3} style={{ marginTop: 20 }}>
        {data.map((item) => (
          <ECourseItem key={item.slug} data={item} />
        ))}
      </Grid>
      <div style={{ textAlign: "center", marginTop: 10 }}>
        {meta.current_page !== meta.last_page && (
          <Button
            variant="contained"
            color="primary"
            onClick={loadMore}
            disabled={isLoadMoreLoading}
          >
            Load More
          </Button>
        )}
      </div>
    </>
  );
};

export default ECourseList;
