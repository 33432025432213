import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getProducts } from "services/axios";
import { LINK_PRODUCT } from "services/marketplace.service";
import swal from "sweetalert";
import ProductList from "./ProductList/ProductList";
import SkuList from "./SkuList/SkuList";

const DialogConnectProduct = (props) => {
  const { open, onClose, product } = props;
  const [inventory, setInventory] = useState(null);
  const [skuLink, setSkuLink] = useState({});

  const handleSave = () => {
    const data = {
      channel_product_id: product.id,
      inventory_product_id: inventory.id,
      skus: Object.keys(skuLink)
        .map((skuId) => ({
          channel_sku_id: skuId,
          inventory_sku_id: skuLink[skuId],
        }))
        .filter((item) => Boolean(item.inventory_sku_id)),
    };

    LINK_PRODUCT([data])
      .then(({ data }) => {
        swal("Berhasil!", "Berhasil menghubungkan produk", "success").then(
          () => {
            const response = data[0];

            product.skus = response.skus;
            product.inventory = response.inventory;
            onClose();
          }
        );
      })
      .catch((error) => {
        swal("Error", error.response.data.message, "error");
      });
  };

  useEffect(() => {
    setInventory(null);
    setSkuLink({});

    if (product?.inventory) {
      getProducts({
        "filter[slug]": product.inventory.slug,
      }).then((response) => {
        if (response.data.length > 0) {
          setInventory(response.data[0]);
        }
      });

      const skuLink = product.skus.reduce((obj, item) => {
        if (
          item.inventory &&
          item.inventory.product_id === product.inventory?.id
        ) {
          return Object.assign(obj, { [item.id]: item.inventory.id });
        } else {
          return obj;
        }
      }, {});

      setSkuLink(skuLink);
    }
  }, [open, product]);

  const handleChangeProduct = () => {
    setSkuLink({});
    setInventory(null);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      {product && (
        <>
          <DialogTitle id="form-dialog-title">
            Hubungkan <b>{product.name}</b>
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              Pilih produk Idejualan yang ingin dihubungkan ke produk
              Marketplace.
            </DialogContentText>

            {!inventory ? (
              <ProductList
                onProductChange={(product) => setInventory(product)}
              />
            ) : (
              <SkuList
                product={product}
                inventory={inventory}
                skuLink={skuLink}
                onProductChange={handleChangeProduct}
                onSkuLinkChange={(value) => setSkuLink(value)}
              />
            )}
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Batal
        </Button>
        {inventory && (
          <Button onClick={handleSave} color="secondary">
            Simpan
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogConnectProduct;
