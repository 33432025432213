import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { Typography } from "@material-ui/core";
import Content from "./content";
import StatusOrder from "./status-order";
import { getAllOrders } from "../../services/axios";
import Loading from "../../components/loading";

const Order = withStyles(style)((props) => {
  const { classes } = props;
  const slug = JSON.parse(localStorage.getItem("DATA")).slug;
  const [state, setState] = useState({
    openUbahPesanan: false,
    dataEdit: null,
    order: null,
    filteredOrder: null,
  });

  const handleUbahPesanan = (data) => {
    setState({ ...state, openUbahPesanan: true, dataEdit: data });
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await getAllOrders(slug);
      setState(state => ({
        ...state,
        order: response,
        filteredOrder: response.data,
      }));
    };
    fetch();
  }, [slug]);

  const handleCloseStatusOrder = async () => {
    const response = await getAllOrders(slug);
    setState({
      ...state,
      openUbahPesanan: false,
      order: response,
      filteredOrder: response.data,
    });
  };

  return (
    <Fragment>
      {state.openUbahPesanan ? (
        <Fragment>
          <StatusOrder
            data={state.dataEdit}
            order={state.order}
            filteredOrder={state.filteredOrder}
            onClose={handleCloseStatusOrder}
          />
        </Fragment>
      ) : (
        <Fragment>
          {!state.order ? (
            <Fragment>
              <Loading />
            </Fragment>
          ) : (
            <Fragment>
              <div className={classes.header}>
                <Typography variant="h6">
                  <b>Order</b>
                </Typography>
              </div>

              <div style={{ paddingTop: 20 }}>
                <Content
                  handleUbahPesanan={handleUbahPesanan}
                  order={state.order}
                  filteredOrder={state.filteredOrder}
                />
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
});

export default Order;
