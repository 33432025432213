import React, { Fragment, useState } from "react";
import {
  Paper,
  Input,
  Typography,
  Button,
  Switch,
  TextField,
  Fade,
  MenuItem,
  Fab,
  Menu,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Backdrop,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import CardOrder from "./components/CardOrder";
import Pagination from "@material-ui/lab/Pagination";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  getAllOrders,
  downloadLabel,
  getAllOrdersCustom,
} from "../../services/axios";

const Content = withStyles(style)((props) => {
  const { classes, handleUbahPesanan, order, filteredOrder } = props;
  const slug = JSON.parse(localStorage.getItem("DATA")).slug;
  console.log(slug);
  const DateNow = `${new Date().getFullYear()}-${
    "0" + (new Date().getMonth() + 1)
  }-${"0" + new Date().getDate()}`;
  const [filter, setFilter] = useState({
    open: false,
    requested: 0,
    waitingAnswer: 0,
    approved: 0,
    checkout: 0,
    rejected: 0,
    canceled: 0,
    page: 1,
    search: "",
    loadingSearch: false,
  });
  const [dataOrder, setDataOrder] = useState({
    order: order,
    filteredOrder: filteredOrder,
  });
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [currentFilterIndex, setCurrentFilterIndex] = useState(0);
  const filterList = [{ key: "order_id", name: "Order ID" }];
  const [downloadQueue, setDownloadQueue] = useState([]);
  const [selectAllLabel, setSelectAllLabel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleAllLabel = () => {
    setSelectAllLabel((value) => {
      if (value === false) {
        dataOrder.filteredOrder.forEach((item) => {
          if (item.is_label_downloaded === 0) {
            toggleDownloadQueue(item.id);
          }
        });
      } else {
        setDownloadQueue([]);
      }
      return !value;
    });
  };

  const toggleDownloadQueue = (id) => {
    setDownloadQueue((value) => {
      const list = [...value];
      if (list.indexOf(id) === -1) {
        list.push(id);
      } else {
        list.splice(list.indexOf(id), 1);
      }
      return list;
    });
  };

  const handleChangeFilter = (event, index) => {
    setAnchorElFilter(null);
    setCurrentFilterIndex(index);
  };

  const handleResetFilter = async () => {
    setFilter({
      ...filter,
      requested: 0,
      waitingAnswer: 0,
      approved: 0,
      checkout: 0,
      rejected: 0,
      canceled: 0,
    });

    const response = await getAllOrdersCustom({
      paginate: 20,
      "filter[supplier.slug]": slug,
    });

    setDataOrder({
      ...dataOrder,
      order: response,
      filteredOrder: response.data,
    });
  };

  const handleFilter = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };

  const handleChangePage = async (e, value) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const fetch = async () => {
      const response = await getAllOrdersCustom({
        paginate: value * 20,
        "filter[supplier.slug]": slug,
        "filter[status_in]": `${
          (filter.requested !== 0 ? filter.requested : "") +
          (filter.waitingAnswer !== 0 ? "," + filter.waitingAnswer : "") +
          (filter.approved !== 0 ? "," + filter.approved : "") +
          (filter.checkout !== 0 ? "," + filter.checkout : "") +
          (filter.canceled !== 0 ? "," + filter.canceled : "") +
          (filter.rejected !== 0 ? "," + filter.rejected : "")
        }`,
        "filter[id]": filter.search,
      });

      setFilter({ ...filter, page: value });

      setDataOrder({
        ...dataOrder,
        order: response,
        filteredOrder: response.data,
      });
    };
    fetch();
  };

  const handleGunakanFilter = () => {
    const fetch = async () => {
      const response = await getAllOrdersCustom({
        paginate: 20,
        "filter[supplier.slug]": slug,
        "filter[status_in]": `${
          (filter.requested !== 0 ? filter.requested : "") +
          (filter.waitingAnswer !== 0 ? "," + filter.waitingAnswer : "") +
          (filter.approved !== 0 ? "," + filter.approved : "") +
          (filter.checkout !== 0 ? "," + filter.checkout : "") +
          (filter.canceled !== 0 ? "," + filter.canceled : "") +
          (filter.rejected !== 0 ? "," + filter.rejected : "")
        }`,
      });

      setFilter({ ...filter, page: 1 });

      setDataOrder({
        ...dataOrder,
        order: response,
        filteredOrder: response.data,
      });
    };
    fetch();
  };

  const handleSearch = (keyword) => {
    setFilter({ ...filter, search: keyword });
  };

  const handleCari = async () => {
    const fetch = async () => {
      const response = await getAllOrdersCustom({
        paginate: 20,
        "filter[supplier.slug]": slug,
        "filter[status_in]": `${
          (filter.requested !== 0 ? filter.requested : "") +
          (filter.waitingAnswer !== 0 ? "," + filter.waitingAnswer : "") +
          (filter.approved !== 0 ? "," + filter.approved : "") +
          (filter.checkout !== 0 ? "," + filter.checkout : "") +
          (filter.canceled !== 0 ? "," + filter.canceled : "") +
          (filter.rejected !== 0 ? "," + filter.rejected : "")
        }`,
        "filter[id]": filter.search,
      });

      setFilter({ ...filter, page: 1 });

      setDataOrder({
        ...dataOrder,
        order: response,
        filteredOrder: response.data,
      });
    };
    fetch();
  };

  const downloadMultipleLabel = async () => {
    setIsLoading(true);
    const response = await downloadLabel(downloadQueue.join(","));
    const url = window.URL.createObjectURL(
      new Blob([response], { type: "application/pdf" })
    );
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `Order ${downloadQueue.map((id) => `#${id}`).join(", ")}`
    );
    document.body.appendChild(link);
    link.click();
    // Update order
    setDataOrder((order) => {
      return {
        ...order,
        filteredOrder: order.filteredOrder.map((item) => {
          if (downloadQueue.indexOf(item.id) !== -1) {
            item = { ...item, is_label_downloaded: 1 };
          }
          return item;
        }),
      };
    });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Paper style={{ padding: 10 }}>
        <div style={{ display: "flex" }}>
          <div className={classes.searchWrapper}>
            <SearchIcon className={classes.SearchIcon} />
            <Input
              disableUnderline={true}
              placeholder="Cari ID..."
              onChange={(e) => setFilter({ ...filter, search: e.target.value })}
            />
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleCari()}
            style={{ borderRadius: 30, marginLeft: 10 }}
          >
            Cari
          </Button>
          <Button
            className={classes.filter}
            onClick={() => handleFilter("open", !filter.open)}
          >
            <Typography>Filter</Typography>
            <ArrowDropDownIcon />
          </Button>
        </div>
        {filter.open && (
          <Fade in={filter.open}>
            <div className={classes.filterOrderWrapper}>
              <Typography variant="h6">
                <b>Filter Order</b>
              </Typography>
              <div className={classes.flexBetween} style={{ paddingTop: 10 }}>
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>Requested</Typography>
                  <Switch
                    checked={filter.requested !== 0}
                    onChange={() =>
                      handleFilter(
                        "requested",
                        filter.requested === 10 ? 0 : 10
                      )
                    }
                    name="Requested"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>
                    Waiting answer
                  </Typography>
                  <Switch
                    checked={filter.waitingAnswer === 20}
                    onChange={() =>
                      handleFilter(
                        "waitingAnswer",
                        filter.waitingAnswer === 20 ? 0 : 20
                      )
                    }
                    name="waiting_answer"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>Approved</Typography>
                  <Switch
                    checked={filter.approved === 30}
                    onChange={() =>
                      handleFilter("approved", filter.approved === 30 ? 0 : 30)
                    }
                    name="Approved"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>Checkout</Typography>
                  <Switch
                    checked={filter.checkout === 40}
                    onChange={() =>
                      handleFilter("checkout", filter.checkout === 40 ? 0 : 40)
                    }
                    name="Checkout"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>Rejected</Typography>
                  <Switch
                    checked={filter.rejected === 90}
                    onChange={() =>
                      handleFilter("rejected", filter.rejected === 90 ? 0 : 90)
                    }
                    name="Rejected"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
                <div className={classes.filterDiv}>
                  <Typography style={{ color: "#fff" }}>Canceled</Typography>
                  <Switch
                    checked={filter.canceled === 91}
                    onChange={() =>
                      handleFilter("canceled", filter.canceled === 91 ? 0 : 91)
                    }
                    name="Canceled"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: 20,
                }}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  style={{
                    textTransform: "capitalize",
                    borderRadius: 30,
                    marginRight: 10,
                  }}
                  onClick={handleResetFilter}
                >
                  Reset
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ textTransform: "capitalize", borderRadius: 30 }}
                  onClick={handleGunakanFilter}
                >
                  Gunakan Filter
                </Button>
              </div>
            </div>
          </Fade>
        )}
        {dataOrder.filteredOrder &&
          dataOrder.filteredOrder.map((res, index) => (
            <div key={res.id} style={{ paddingTop: 20 }}>
              <CardOrder
                data={res}
                index={
                  dataOrder.order.meta.total -
                  (dataOrder.order.meta.from + (index - 1))
                }
                // isLabelChecked={downloadQueue.indexOf(res.id) !== -1}
                onLoading={(value) => {
                  setIsLoading(value);
                }}
                // onToggleDownloadQueue={(id) => toggleDownloadQueue(id)}
              />
            </div>
          ))}
        {dataOrder.filteredOrder.length === 0 && (
          <div className={classes.notFound}>
            <div className={classes.notFound2}>
              <Typography variant="h5">
                <b>Oops!</b>
              </Typography>
              <Typography>Data tidak ditemukan</Typography>
            </div>
          </div>
        )}
        {dataOrder.order && dataOrder.order.meta.last_page !== 1 && (
          <div className={classes.Pagination}>
            <Pagination
              count={dataOrder.order.meta.last_page}
              variant="outlined"
              onChange={handleChangePage}
              color="secondary"
            />
          </div>
        )}
      </Paper>
    </Fragment>
  );
});

export default Content;
