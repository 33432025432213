import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import Grid from "@material-ui/core/Grid";
import Notification from "./components/notifikasi";
import SummaryPenjualan from "./components/summary-penjualan";
import { WidgetProfile } from "containers";
import Statistik from "./components/statistik";
import Grafik from "./components/grafik";

const Home = withStyles(style)((props) => {
  const { classes } = props;
  return (
    <Fragment>
      <Grid container className={classes.container}>
        <Grid item xs={9} style={{ paddingRight: 20 }}>
          <div>
            <SummaryPenjualan />
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Statistik />
              </Grid>
              <Grid item xs={5}>
                <Grafik />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <WidgetProfile {...props} />
            </Grid>
            <Grid item xs={12}>
              <Notification {...props} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default Home;
