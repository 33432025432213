import axios from "axios";
import swal from "sweetalert";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  let getToken = localStorage.getItem("TOKEN");

  config.headers = Object.assign(
    {
      Authorization: getToken,
    },
    config.headers
  );
  return config;
});

axiosInstance.interceptors.request.use((config) => {
  let getToken = localStorage.getItem("TOKEN");

  config.headers = Object.assign(
    {
      Authorization: "Bearer " + getToken,
    },
    config.headers
  );
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    if (error.response && error.response.status && error.response.status) {
      if (
        error.response.status === 401 &&
        window.location.pathname !== "/login"
      ) {
        swal({
          title: "Waktu sesi telah habis, silahkan Login kembali",
          icon: "info",
        }).then(() => {
          localStorage.clear();
          window.location.reload();
        });
      } else if (
        error.response.status === 401 &&
        window.location.pathname === "/login"
      ) {
        swal("Oops!", "Email dan Password tidak cocok.", "warning").then(() =>
          window.location.reload()
        );
      } else if (
        error.response.status === 403 &&
        window.location.pathname === "/order"
      ) {
        swal(
          "Oops!",
          `Update Gagal, ${error.response.data.message}`,
          "warning"
        );
      }
    }
    // if (window.confirm('Terjadi kesalahan, silahkan muat ulang')) {
    //   window.location.reload();
    // }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
