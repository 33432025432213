import React from "react";
import swal from "sweetalert";

import {
  Box,
  Grid,
  Typography,
  IconButton,
  Paper,
  Button,
} from "@material-ui/core";
import { AddAPhoto, ArrowBack } from "@material-ui/icons";

import {
  uploadProductsTestimony,
  getProductTestimony,
  deleteProductTestimony,
} from "../../../../services/axios";

function TestimoniProduk({ onClose, slug }) {
  const [testimonials, setTestimonials] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [deletedTestimonials, setDeletedTestimonials] = React.useState([]);
  const [addedTestimonials, setAddedTestimonials] = React.useState([null]);

  React.useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const res = await getProductTestimony(slug);
    setTestimonials(res.data);
  };

  const handleChangeImage = async (event, i) => {
    const validImage = ["image/jpeg", "image/png"];
    const file = event.target.files[0];
    if (validImage.includes(file.type)) {
      const currentTestimonials = [...addedTestimonials];
      currentTestimonials[i] = file;
      setAddedTestimonials(currentTestimonials);
    } else alert("Gambar yang di upload tidak valid");
  };

  const handleSave = async () => {
    const formData = new FormData();
    let nullAdded = 0;

    addedTestimonials.forEach((testimonial, i) => {
      if (testimonial && testimonial.type) {
        formData.append(`images[${i}]`, testimonial);
      } else {
        nullAdded++;
      }
    });

    const deletedFormData = new FormData();
    deletedTestimonials.forEach((testimonial, i) => {
      deletedFormData.append("_method", "delete");
      deletedFormData.append(`image_id[${i}]`, testimonial);
    });
    try {
      setIsLoading(true);
      if (nullAdded && !deletedTestimonials.length) {
        return swal(
          "Warning",
          "Silahkan tambahkan / hapus testimoni",
          "warning"
        );
      }
      if (nullAdded !== addedTestimonials.length) {
        await uploadProductsTestimony(slug, formData);
      }
      if (deletedTestimonials.length) {
        await deleteProductTestimony(slug, deletedFormData);
      }
      swal("Testimoni berhasil disimpan", {
        icon: "success",
      }).then(() => {
        onClose();
        setAddedTestimonials([null]);
        setDeletedTestimonials([]);
      });
    } catch (error) {
      swal("Error", "Terjadi kesalahan", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTestimony = async (imageId) => {
    setDeletedTestimonials([...deletedTestimonials, imageId]);
  };

  const handleUndoDeleteTestimony = async (imageId) => {
    const currentDeletedTestimonials = [...deletedTestimonials];
    const deleted = currentDeletedTestimonials.filter(
      (deleted) => deleted !== imageId
    );
    setDeletedTestimonials(deleted);
  };

  const handleDeleteAddedTestimony = (index) => {
    const currentAddedTestimonials = [...addedTestimonials];
    const deleted = currentAddedTestimonials.splice(index, 1);
    setAddedTestimonials(deleted);
  };

  return (
    <Paper style={{ padding: 16 }}>
      <Box display="flex" alignItems="center">
        <ArrowBack
          style={{ paddingRight: 10, cursor: "pointer" }}
          onClick={() => onClose()}
        />
        <b>Upload Testimoni Produk</b>
      </Box>
      <Box display="flex" justifyContent="center" my={2}>
        <Grid container spacing={3}>
          {testimonials.map((testimonial, i) => {
            const isDeleted = deletedTestimonials.find(
              (deleted) => deleted === testimonial.id
            );
            return (
              <Grid item md={3} sm={2} xs={1}>
                <div
                  style={{
                    border: `2px dashed #29C0C7`,
                    borderRadius: 5,
                    height: 200,
                    width: "100%",
                    cursor: "pointer",
                    position: "relative",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${testimonial.file_path})`,
                  }}
                >
                  <input
                    type="file"
                    disabled
                    style={{
                      opacity: 0.0,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      width: "100%",
                      height: "100%",
                      cursor: "not-allowed",
                    }}
                    onChange={(event) => handleChangeImage(event, i)}
                  />
                </div>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() =>
                      isDeleted
                        ? handleUndoDeleteTestimony(testimonial.id)
                        : handleDeleteTestimony(testimonial.id)
                    }
                    style={{ backgroundColor: isDeleted ? "green" : "" }}
                  >
                    {isDeleted ? "Batal" : "Hapus"}
                  </Button>
                </Box>
              </Grid>
            );
          })}
          {addedTestimonials.map((testimonial, i) => (
            <Grid item md={3} sm={2} xs={1}>
              <div
                style={{
                  border: `2px dashed #29C0C7`,
                  borderRadius: 5,
                  height: 200,
                  width: "100%",
                  cursor: "pointer",
                  position: "relative",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${
                    testimonial && URL.createObjectURL(testimonial)
                  })`,
                }}
              >
                {testimonial === null && (
                  <React.Fragment>
                    <Typography
                      style={{
                        height: "84%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 100,
                        color: "#29C0C7",
                      }}
                    >
                      +
                    </Typography>
                  </React.Fragment>
                )}
                <input
                  type="file"
                  style={{
                    opacity: 0.0,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  onChange={(event) => handleChangeImage(event, i)}
                />
              </div>
              {addedTestimonials.length > 1 && (
                <Box mt={2}>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => handleDeleteAddedTestimony(i)}
                  >
                    Hapus
                  </Button>
                </Box>
              )}
            </Grid>
          ))}
          <Grid item>
            <IconButton
              onClick={() => setAddedTestimonials([...addedTestimonials, null])}
            >
              <AddAPhoto />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? "Loading...." : "Simpan"}
        </Button>
      </Box>
    </Paper>
  );
}

export default TestimoniProduk;