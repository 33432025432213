import React from 'react';
import { WrapProfile } from './style';

const ProfileComponent = (props) => {
  const { data, style } = props
  return (
    <WrapProfile style={style}>
      <div className="wrap">
        <div className="avatar">
          <img src={data.avatar_url} alt="profile" />
        </div>
        <div className="text">
          <p>{ data.name }</p>
          <p>{data.description}</p>
        </div>
      </div>
    </WrapProfile>
  );
};

export default ProfileComponent;