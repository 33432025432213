import React, { Fragment, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  Container,
  Backdrop,
  InputAdornment,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import style from "./style";
import { withStyles } from "@material-ui/core/styles";
import Brandlogo from "../../assets/images/Brand.svg";
import IlustrationDesktop from "../../assets/images/ilustrasi.svg";
import BackgoundDesktop from "../../assets/images/background.svg";
import MobileVectorBg from "../../assets/images/Vector1.svg";
import MobileVector from "../../assets/images/login2.svg";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { login } from "../../services/axios";
import { setCookie, getCookie, eraseCookie } from "../../services/cookie";
import swal from "sweetalert";

const Content = withStyles(style)((props) => {
  const { classes } = props;
  const history = useHistory();
  const historyLogin = JSON.parse(getCookie("rememberMe"));
  const [state, setState] = useState({
    email: historyLogin ? historyLogin.email : "",
    password: historyLogin ? historyLogin.password : "",
    showPassword: false,
    rememberMe: historyLogin ? true : false,
    isLoading: false,
  });

  const handleChangeState = (valueState, value) => {
    setState({ ...state, [valueState]: value });
  };

  const handleClickShowPassword = () => {
    handleChangeState("showPassword", !state.showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    handleChangeState("isLoading", true);
    const response = await login({
      email: state.email,
      password: state.password,
    });

    console.log(response);

    if (response.code === "unactivated") {
      handleChangeState("isLoading", false);
      swal("Oops!", "Akun belum diaktivasi.", "warning");
    } else {
      localStorage.setItem("TOKEN", response.access_token);
      localStorage.setItem("DATA", JSON.stringify(response.data));
      if (state.rememberMe) {
        setCookie(
          "rememberMe",
          JSON.stringify({ email: state.email, password: state.password }),
          30
        );
      } else {
        eraseCookie("rememberMe");
      }

      if (response) {
        if (response.data) {
          if (response.data.location && response.data.location === null) {
            swal({
              title: "Alamat belum sesuai",
              text: "Silahkan isi alamat terlebih dahulu",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(() => {
              window.location.href = "/info";
            });
          } else {
            window.location.href = "/";
          }
        }
      }
    }
  };

  return (
    <Fragment>
      <div className={classes.containerContent}>
        <div>
          <Typography>Silakan masuk ke dalam akun kamu</Typography>
          <div className={classes.inputGroup}>
            <TextField
              className={classes.textField}
              label="Email"
              value={state.email}
              variant="outlined"
              onChange={(e) => handleChangeState("email", e.target.value)}
            />
            <TextField
              className={classes.textFieldPassword}
              label="Password"
              value={state.password}
              type={state.showPassword ? "text" : "password"}
              variant="outlined"
              onChange={(e) => handleChangeState("password", e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Grid container style={{ alignItems: "center" }}>
            <Grid item xs={6} className={classes.checkboxRememberMe}>
              <Checkbox
                color="primary"
                checked={state.rememberMe}
                onClick={() =>
                  handleChangeState("rememberMe", !state.rememberMe)
                }
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <Typography>Ingat saya</Typography>
            </Grid>
            <Grid item xs={6} className={classes.forgotPassword}>
                <Typography
                  style={{ color: "#F63B68", cursor: "pointer" }}
                  onClick={() => history.push("/forget-password")}
                >
                  Lupa password ?
                </Typography>
              </Grid>
          </Grid>

          <Button
            variant="contained"
            fullWidth={true}
            color="primary"
            className={classes.loginButton}
            onClick={handleLogin}
          >
            Login
          </Button>
          <Backdrop open={state.isLoading} className={classes.backdrop}>
            <CircularProgress color="primary" />
          </Backdrop>

          <Box mb={1} textAlign="center">
            <Typography variant="caption">Belum punya akun?</Typography>
          </Box>

          <Button
            variant="contained"
            fullWidth={true}
            color="secondary"
            onClick={() => history.push("/register")}
          >
            Daftar
          </Button>
        </div>
      </div>
    </Fragment>
  );
});

const Mobile = withStyles(style)((props) => {
  const { classes } = props;
  return (
    <Fragment>
      <div className={classes.logo}>
        <img
          src={Brandlogo}
          alt="idejualan"
          style={{ height: 40, marginTop: 10 }}
        />
      </div>
      <div>
        <img src={MobileVectorBg} alt="" style={{ width: "100%" }} />
        <div className={classes.imageVector}>
          <img src={MobileVector} alt="" />
        </div>
      </div>
      <Container maxWidth="xs">
        <Content />
      </Container>
    </Fragment>
  );
});

const Desktop = withStyles(style)((props) => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <img src={Brandlogo} alt="idejualan" className={classes.logo} />
      <Grid container>
        <Grid item xs={6}>
          <Content desktop />
        </Grid>
        <Grid item xs={6}>
          <div className={classes.containerImg}>
            <img
              src={BackgoundDesktop}
              alt="idejualan"
              style={{ height: "100%" }}
            />
            <img src={IlustrationDesktop} alt="" className={classes.vector} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
});

const Login = () => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return <React.Fragment>{isMobile ? <Mobile /> : <Desktop />}</React.Fragment>;
};

export default Login;
