import { Tabs, Tab } from "@material-ui/core";
import { SearchCustom } from "components";
import { DataTableBundling } from "containers";
import React, { useState, useEffect } from "react";
import { DataTableBundlingWithTabsWrap } from "./style";
import { GET_ACCOUNT_BUNDLING } from "services/bundling.service";
import moment from "moment";

const DataTableBundlingWithTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [dataAllPromo, setDataAllPromo] = useState([]);
  const [isRefetch, setIsRefetch] = useState(false);

  const filteredSedangBerjalan = dataAllPromo.filter((data) =>
    moment().isBetween(
      moment(data.startDate).format("YYYY-MM-DD HH:mm"),
      moment(data.endDate).format("YYYY-MM-DD HH:mm")
    )
  );

  const filteredAkanDatang = dataAllPromo.filter(
    (data) =>
      !moment().isBetween(
        moment(data.startDate).format("YYYY-MM-DD HH:mm"),
        moment(data.endDate).format("YYYY-MM-DD HH:mm")
      )
  );

  const filteredSelesai = dataAllPromo.filter((data) =>
    moment().isAfter(moment(data.endDate).format("YYYY-MM-DD HH:mm"))
  );

  useEffect(() => {
    const fetchMyBundlingList = async () => {
      let myBundlingList = [];
      await GET_ACCOUNT_BUNDLING().then((res) => {
        res.data.map((data) => {
          myBundlingList.push({
            id: data.id,
            name: data.name,
            period: data.period_type,
            startDate: data.start_date,
            endDate: data.end_date,
          });
          return "";
        });
      });
      setDataAllPromo(myBundlingList);
    };

    if (isRefetch) {
      setDataAllPromo([]);
      fetchMyBundlingList();
      setIsRefetch(false);
    } else {
      fetchMyBundlingList();
    }
  }, [isRefetch]);

  const TabPanel = ({ children, activeTab, index }) => (
    <div
      role="tabpanel"
      hidden={activeTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {activeTab === index && <div className="content">{children}</div>}
    </div>
  );
  return (
    <DataTableBundlingWithTabsWrap>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
      >
        <Tab label="Semua" />
        <Tab label="Sedang Berjalan" />
        <Tab label="Akan Datang" />
        <Tab label="Telah Berakhir" />
      </Tabs>
      <div style={{ padding: "24px 16px" }}>
        <SearchCustom onChange={null} onSearch={null} withIcon />
      </div>
      <TabPanel activeTab={activeTab} index={0}>
        <DataTableBundling
          rowsData={dataAllPromo}
          setIsRefetch={setIsRefetch}
          {...props}
        />
      </TabPanel>
      <TabPanel activeTab={activeTab} index={1}>
        <DataTableBundling
          rowsData={filteredSedangBerjalan}
          setIsRefetch={setIsRefetch}
          {...props}
        />
      </TabPanel>
      <TabPanel activeTab={activeTab} index={2}>
        <DataTableBundling
          rowsData={filteredAkanDatang}
          setIsRefetch={setIsRefetch}
          {...props}
        />
      </TabPanel>
      <TabPanel activeTab={activeTab} index={3}>
        <DataTableBundling
          rowsData={filteredSelesai}
          setIsRefetch={setIsRefetch}
          {...props}
        />
      </TabPanel>
    </DataTableBundlingWithTabsWrap>
  );
};

export default DataTableBundlingWithTabs;
