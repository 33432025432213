const style = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tambahOrder: {
    borderRadius: 30,
    textTransform: 'capitalize',
  },
  other: {
    borderRadius: 30
  },
  filterWrapper: {
    paddingTop: 20
  },
  filterOutlined: {
    border: `1px solid ${theme.palette.green.main}`,
    color: theme.palette.green.main,
    textTransform: 'capitalize',
    borderRadius: 30
  },
  filterContained: {
    backgroundColor: theme.palette.green.main,
    borderRadius: 30,
    textTransform: 'capitalize',
    color: "#fff",
    '&:hover': {
      backgroundColor: "#35bd82"
    }
  },
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: "0 10px 10px 0",
    backgroundColor: theme.palette.white.tertiary,
    width: 'fit-content',
    padding: '0 10px'
  },
  filter: {
    borderRadius: 30,
    backgroundColor: theme.palette.white.tertiary,
    padding: `10px 30px`,
    margin: '0 10px',
    textTransform: 'capitalize'
  },
  downloadAllBtn: {
    marginLeft: 4,
    padding: 'auto 4px',
    textTransform: "capitalize",
  },
  SearchIcon: {
    fill: "#bbbbbb"
  },
  select: {
    width: 'fit-content',
    borderRadius: 30,
    marginLeft: 10,
    backgroundColor: theme.palette.white.tertiary,
    padding: '0 10px'
  },
  Pagination: {
    paddingTop: 40,
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  filterOrderWrapper: {
    borderRadius: 15,
    backgroundColor: theme.palette.white.tertiary,
    padding: 20,
    marginTop: 20
  },
  filterDiv: {
    display: 'flex',
    alignItems: 'center',
    width: 125,
    justifyContent: 'space-between',
    backgroundColor: "#B6CAFF",
    paddingLeft: 10,
    marginTop: 10,
    borderRadius: 30
  },
  flexBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  calendarIcon: {
    '& path': {
      fill: "#fff"
    }
  },
  filterTanggal: {
    display: 'flex',
    paddingTop: 10,
  },
  notFound: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200
  },
  notFound2: {
    textAlign: 'center'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(5),
    backgroundColor: theme.palette.green.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#35bd82",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

export default style;
