import {
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";
import useECourse from "hooks/useECourse";
import React, { useEffect } from "react";
import { GET_ECOURSE_CATEGORIES } from "services/e-course.service";

const ECourseHeader = () => {
  const [, setSearch] = useECourse("search");
  const [categories, setCategories] = useECourse("categories");
  const [selectedCategory, setSelectedCategory] =
    useECourse("selectedCategory");

  useEffect(() => {
    GET_ECOURSE_CATEGORIES().then(({ data }) => {
      setCategories(data);
    });
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <FormControl variant="standard">
        <OutlinedInput
          placeholder="Cari E-Course"
          onChange={(e) => setSearch(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>

      <TextField
        select
        label="Kategori"
        variant="outlined"
        style={{ minWidth: 200, marginLeft: 10 }}
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        <MenuItem value={0}>Semua</MenuItem>
        {categories.map((category) => (
          <MenuItem value={category.id}>{category.name}</MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default ECourseHeader;
