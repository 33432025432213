import React from "react";
import { WrapDataTableBundling } from "./style";
import { DataGrid } from "@material-ui/data-grid";
import { LabelPeriode } from "components/global.style";
import { ActionButton } from "components";
import moment from "moment";
import swal from "sweetalert";
import { DELETE_BUNDLING } from "services/bundling.service";

const DataTableBundling = (props) => {
  const { rowsData = [], setIsRefetch } = props;

  const handleDeleteRow = (id) => {
    swal({
      title: "Are you sure?",
      text: "Promo akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        DELETE_BUNDLING(id);
        swal("Promo dihapus!", {
          icon: "success",
        }).then(() => {
          setIsRefetch(true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
      } else {
        swal("Promo batal dihapus");
      }
    });
  };

  const renderPeriode = (params) => {
    return (
      <div style={{ marginTop: 20 }}>
        {params.row.period === "LIMITED"
          ? `${moment(params.row.startDate).format(
              "DD-MM-YYYY HH:mm"
            )} s/d ${moment(params.row.endDate).format("DD-MM-YYYY HH:mm")}`
          : "Tidak terbatas"}
      </div>
    );
  };
  const columns = [
    { field: "name", headerName: "Nama Promo", flex: 1, sortable: false },
    {
      field: "periode",
      headerName: "Periode",
      width: 265,
      sortable: false,
      renderCell: renderPeriode,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 160,
      align: "right",
      renderCell: (params) => (
        <div style={{ marginTop: 16 }}>
          <ActionButton
            type="view"
            onClick={() =>
              props.history.push(`/promo/bundling/${params.row.id}`)
            }
          />
          <ActionButton
            type="edit"
            onClick={() =>
              props.history.push(`/promo/bundling/edit/${params.row.id}`)
            }
            style={{ marginLeft: 8 }}
          />
          <ActionButton
            type="delete"
            style={{ marginLeft: 8 }}
            onClick={() => handleDeleteRow(params.row.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <WrapDataTableBundling>
      <DataGrid
        rows={rowsData}
        columns={columns}
        pageSize={5}
        checkboxSelection
        disableColumnSelector
        disableColumnMenu
        disableSelectionOnClick
      />
    </WrapDataTableBundling>
  );
};

export default DataTableBundling;
