import React, {useState} from 'react'
import style from "./style";
import { withStyles } from "@material-ui/core/styles";
import { Backdrop, Box, Button, Grid, Hidden, IconButton, Input, InputAdornment, TextField, Typography } from '@material-ui/core';
import Brandlogo from "../../assets/images/Brand.svg";
import IlustrationDesktop from "../../assets/images/ilustrasi.svg";
import BackgoundDesktop from "../../assets/images/background.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import MobileVectorBg from "../../assets/images/Vector1.svg";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { ResetPasswordSupplier } from 'services/axios';

const ResetPasswordPage = withStyles(style)((props) => {
  const { classes, match } = props;
  let history = useHistory()
  const [isLoading, setisLoading] = useState(false)
  const [state, setstate] = useState({
    valuePassword: "",
    showValuePassword: false,
    confirmPassword: "",
    showConfirmPassword: false,
  })

  const handleChangeInput = (event) => {
    setstate({...state, [event.target.name]: event.target.value});
  };

  const handleClickShowPassword = () => {
    setstate({
      ...state,
      showValuePassword: !state.showValuePassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setstate({
      ...state,
      showConfirmPassword: !state.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async(event) => {
    event.preventDefault()

    const data = {
      token: match.params.token,
      new_password: state.valuePassword,
    };
    if (state.confirmPassword !== '' || state.valuePassword !== '') {
      if (state.confirmPassword === state.valuePassword) {
        setisLoading(true);
        try {
          const response = await ResetPasswordSupplier(data);
          swal("Success!", response.message, "success").then(() =>
            history.push("/login")
          );
        } catch (error) {
          swal('Error', error.response.data.message, 'error').then(() =>
            setisLoading(false)
          );
        }
      }else{
        swal('Error', 'Maaf Password dan Confirm Password Tidak Sama', 'error')
      }
    }else{
      swal('Error', 'Maaf Password dan Confirm Password Harus Di Isi', 'error')
    }
  }

  console.log(state)

  return (
    <Box className={classes.container}>
      <Hidden smDown>
        <img src={Brandlogo} alt="idejualan" className={classes.logo} />
      </Hidden>

      <Hidden mdUp>
        <Box>
          <div className={classes.logoMobile}>
            <img
              src={Brandlogo}
              alt="idejualan"
              style={{ height: 40, marginTop: 10 }}
            />
          </div>
          <div>
            <img src={MobileVectorBg} alt="" style={{ width: "100%" }} />
          </div>
        </Box>
      </Hidden>

      <Grid container>
        <Hidden smDown>
          <Grid item xs={6}>
            <div className={classes.containerImg}>
              <img
                src={BackgoundDesktop}
                alt="idejualan"
                style={{ height: "100%" }}
              />
              <img src={IlustrationDesktop} alt="" className={classes.vector} />
            </div>
          </Grid>
        </Hidden>

        {/* content */}
        <Grid item xs={12} sm={12} md={6}>
          <Box className={classes.containerContent}>
            <Box style={{ maxWidth: 450, maxHeight: 350, padding: 20, marginTop: 100, border: '1px solid #fbbb14', borderRadius: 8 }}>
              <Typography variant='h5'>Form Reset Password</Typography>
              <div style={{ paddingTop: 20 }}>
                <Typography className={classes.guide}>
                  Sebaiknya gunakan kata sandi yang kuat yang tidak Anda gunakan di tempat lain.
                </Typography>
                <form onSubmit={(event) => {handleSubmit(event)}}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div className={classes.styledInput}>
                        <Input
                          label="Password baru"
                          type={state.showValuePassword ? "text" : "password"}
                          value={state.valuePassword}
                          fullWidth={true}
                          variant="outlined"
                          disableUnderline={true}
                          name='valuePassword'
                          onChange={handleChangeInput}
                          required
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {state.showValuePassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.styledInput}>
                        <Input
                          label="Masukan ulang password baru"
                          type={state.showConfirmPassword ? "text" : "password"}
                          value={state.confirmPassword}
                          fullWidth={true}
                          variant="outlined"
                          disableUnderline={true}
                          name='confirmPassword'
                          onChange={handleChangeInput}
                          required
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {state.showConfirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    className={classes.styledButton}
                    type='submit'
                  >
                    Ubah Password
                  </Button>
                </form>
                <Typography style={{ textAlign: "center" }}>
                  Kembali ke halaman{" "}
                  <span className={classes.link} onClick={() => history.push("/login")}>
                    login
                  </span>{" "}
                  atau{" "}
                  <span
                    className={classes.link}
                    onClick={() => history.push("/register")}
                  >
                    daftar
                  </span>
                </Typography>
              </div>
              <Backdrop open={isLoading} className={classes.backdrop}>
                <CircularProgress color="primary" />
              </Backdrop>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
})

export default ResetPasswordPage