import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import customDate from "utils/customDate";
import useECourse from "hooks/useECourse";

const useStyles = makeStyles({
  media: {
    height: 250,
  },
  label: {
    position: "absolute",
    top: 10,
    left: 10,
    zIndex: 1,
    "& button": {
      marginRight: 10,
    },
  },
});

const ECourseItem = ({ data }) => {
  const classes = useStyles();
  const [, setSelectedCategory] = useECourse("selectedCategory");

  return (
    <Grid item xs={3}>
      <Card style={{ position: "relative" }}>
        <div className={classes.label}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setSelectedCategory(data.category.id)}
          >
            {data.category.name}
          </Button>
        </div>
        <CardActionArea>
          <Link to={`/e-course/${data.slug}`}>
            <CardMedia
              className={classes.media}
              image={data.image.resized.large}
              title={data.title}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                color="textPrimary"
              >
                {data.title}
              </Typography>
              <Typography variant="body" color="textSecondary" component="p">
                {customDate(data.created_at)}
              </Typography>
            </CardContent>
          </Link>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default ECourseItem;
