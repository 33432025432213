import React, { Fragment, useState, useEffect} from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import swal from "sweetalert";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as Notification } from "../../../../assets/icons/notification.svg";
import enterRounded from "../../../../assets/icons/enter-rounded.svg";
import failRounded from "../../../../assets/icons/fail-rounded.svg";
import successRounded from "../../../../assets/icons/success-rounded.svg";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  getSupplierNotification,
  readNotification,
} from "../../../../services/axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const customDate = (data) => {
  const getDate = new Date(data);
  return `${getDate.getDate()}-${
    getDate.getMonth() + 1
  }-${getDate.getFullYear()} ${getDate.getHours()}:${getDate.getMinutes()}`;
};

const handleReaded = (id) => {
  const slug = JSON.parse(localStorage.getItem("DATA")).slug;
  readNotification(slug, id);
  swal("Notifikasi Terbaca!", {
    icon: "success",
  }).then(() => window.location.reload());
  // useCallback
};

const ItemNotification = ({ data }) => {
  // console.log(data)
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px 0",
      }}
    >
      <div style={{ lineHeight: 0 }}>
        <img
          src={
            new RegExp("dibatalkan").test(data.data.message.toLowerCase())
              ? failRounded
              : new RegExp("diteruskan").test(data.data.message.toLowerCase())
              ? successRounded
              : enterRounded
          }
          alt=""
        />
      </div>
      <div style={{ paddingLeft: 20 }}>
        <Typography style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          {data.data.type.replace("-", " ")}
        </Typography>
        <Typography variant="caption" style={{ color: "#8C8C8C" }}>
          {data.data.message}
        </Typography>
        <div style={{ display: "flex", justifyContent: 'space-between' }}>
          <Typography variant="caption" style={{ color: "#8C8C8C" }}>
            {customDate(data.created_at)}
          </Typography>
          {data.read_at === null && (
            <Typography
              variant="caption"
              style={{ color: "#FDCA40", cursor: "pointer" }}
              onClick={() => handleReaded(data.id)}
            >
              Tandai Telah Dibaca
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

const Notifikasi = withStyles(style)((props) => {
  const { classes } = props;
  const slug = JSON.parse(localStorage.getItem("DATA")).slug;
  const [value, setValue] = React.useState(0);
  const [dataNotifikasi, setDataNotifikasi] = useState(null);
  const [notifikasiLength] = useState(10);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await getSupplierNotification(slug);
      setDataNotifikasi(response.data);
    };

    fetch();
  },[notifikasiLength, slug]);

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.header}>
          <Notification className={classes.iconNotification} />
          <Typography className={classes.headerText} style={{color: '#FFF', paddingLeft: 10, fontSize: '1em'}}>Notifikasi</Typography>
        </div>
        <div className={classes.content}>
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { background: "black" } }}
            aria-label="simple tabs example"
          >
            <Tab label="Notifikasi" {...a11yProps(0)} />
            <Tab label="Terbaca" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            {dataNotifikasi &&
              dataNotifikasi.map((res, index) => {
                return (
                  res.read_at === null && (
                    <Fragment key={index}>
                      <ItemNotification data={res} />
                    </Fragment>
                  )
                );
              })}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {dataNotifikasi &&
              dataNotifikasi.map((res, index) => {
                return (
                  res.read_at !== null && (
                    <Fragment key={index}>
                      <ItemNotification data={res} />
                    </Fragment>
                  )
                );
              })}
          </TabPanel>
        </div>
      </div>
    </Fragment>
  );
});

export default Notifikasi;
