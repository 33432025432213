import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { Typography, Grid, Paper, Button } from "@material-ui/core";
import SimpleImageSlider from "react-simple-image-slider";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const DetailProduct = withStyles(style)((props) => {
  const { classes, data, handleEditProduct, handleClose } = props;
  console.log(data);
  const [state, setState] = useState({
    selectedSkus: data.is_custom ? data : data.skus[0],
  });

  const handleSetState = (state_name, value) => {
    setState({ ...state, [state_name]: value });
  };

  const getImage = () => {
    let dataResult = [];
    if (data.is_custom) {
      dataResult.push({ url: data.image_url });
    } else {
      data.skus.forEach((res) => {
        dataResult = [...dataResult, { url: res.image_url }];
      });
    }
    console.log(dataResult);
    return dataResult;
  };

  const getTotalStock = () => {
    let resultData = 0;
    data.skus.forEach((res) => {
      resultData = resultData + res.stock;
    });
    return resultData;
  };

  return (
    <Fragment>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.flexCenter}>
          <ArrowBackIcon
            style={{ paddingRight: 10, cursor: "pointer" }}
            onClick={() => handleClose()}
          />
          <b>Detail Produk</b>
        </Typography>
      </div>

      <Grid container spacing={3} style={{ marginTop: 20 }}>
        <Grid item xs={9}>
          <Paper style={{ padding: 30 }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: 250 }}>
                <SimpleImageSlider
                  width={250}
                  height={250}
                  images={getImage()}
                />
              </div>
              <div style={{ paddingLeft: 20 }}>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <Typography>
                    <b>{data.name}</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "#c4c4c4", paddingLeft: 10 }}
                  >
                    {state.selectedSkus.code}
                  </Typography>
                </div>
                {!data.is_custom && (
                  <div style={{ display: "flex", paddingTop: 20 }}>
                    <div>
                      <Typography variant="body2" style={{ color: "#c4c4c4" }}>
                        Harga Reseller
                      </Typography>
                      <Typography>
                        <b>Rp. {state.selectedSkus.price.reseller}</b>
                      </Typography>
                    </div>
                    <div style={{ paddingLeft: 20 }}>
                      <Typography variant="body2" style={{ color: "#c4c4c4" }}>
                        Komisi
                      </Typography>
                      <Typography>
                        <b>Rp. {state.selectedSkus.price.commission}</b>
                      </Typography>
                    </div>
                    <div style={{ display: "flex", paddingTop: 20 }}>
                      <Typography
                        variant="body2"
                        style={{ color: "#c4c4c4", width: 50 }}
                      >
                        Tersisa
                      </Typography>
                      <Typography variant="body2" style={{ paddingLeft: 20 }}>
                        {state.selectedSkus.stock || "-"}
                      </Typography>
                    </div>
                    <div style={{ display: "flex", paddingTop: 5 }}>
                      <Typography
                        variant="body2"
                        style={{ color: "#c4c4c4", width: 50 }}
                      >
                        Warna
                      </Typography>
                      <Typography variant="body2" style={{ paddingLeft: 20 }}>
                        {state.selectedSkus.properties.color || "-"}
                      </Typography>
                    </div>
                    <div style={{ display: "flex", paddingTop: 5 }}>
                      <Typography
                        variant="body2"
                        style={{ color: "#c4c4c4", width: 50 }}
                      >
                        Berat
                      </Typography>
                      <Typography variant="body2" style={{ paddingLeft: 20 }}>
                        {state.selectedSkus.weight_gram || "-"}
                      </Typography>
                    </div>
                  </div>
                )}

                <div style={{ display: "flex", paddingTop: 5 }}>
                  <Typography
                    variant="body2"
                    style={{ color: "#c4c4c4", width: 50 }}
                  >
                    Varian
                  </Typography>
                  <Grid container spacing={1} style={{ paddingLeft: 20 }}>
                    {data.is_custom ? (
                      <Grid container spacing={2}>
                        {data.properties_custom.map((res, index) => (
                          <Grid item key={1} xs>
                            {res}
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <>
                        {data.skus.map((res, index) => (
                          <Grid item>
                            <img
                              src={res.image_url}
                              style={{
                                borderRadius: 5,
                                cursor: "pointer",
                                objectFit: "cover",
                              }}
                              onClick={() =>
                                handleSetState("selectedSkus", res)
                              }
                              height={50}
                              width={50}
                              alt=""
                            />
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: 30 }}>
              <Typography style={{ color: "#c4c4c4" }}>Deskripsi :</Typography>
              <Typography>
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
              </Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper style={{ padding: 30 }}>
            <div>
              <Typography style={{ color: "#c4c4c4" }}>Total Stock</Typography>
              <Typography>
                <b>{data.is_custom ? "-" : getTotalStock()}</b>
              </Typography>
            </div>
            <div style={{ paddingTop: 10 }}>
              <Typography style={{ color: "#c4c4c4" }}>Kategori</Typography>
              <Typography>
                <b>{data.category.name}</b>
              </Typography>
            </div>
            <div style={{ paddingTop: 10 }}>
              <Typography style={{ color: "#c4c4c4" }}>Jenis Produk</Typography>
              <Typography>
                <b>Produk Sendiri</b>
              </Typography>
            </div>
            <div style={{ paddingTop: 10 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                style={{ textTransform: "capitalize", color: "#ffffff" }}
                onClick={() => handleEditProduct(data)}
              >
                Edit Barang
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default DetailProduct;
