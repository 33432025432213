import React, { Fragment } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Sidebar from "./components/sidebar";
import Navbar from "./components/navbar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./pages/login";

import Home from "./pages/home";
import listRoute from "./config/listRoute";
import { AuthContext } from "contexts/AuthContextProvider";
import RegisterPage from "pages/register";
import ModalPopup from "components/ModalPopup";
import ForgetPasswordPage from "pages/forget-password-page";
import ResetPasswordPage from "pages/reset-password-page";

const theme = createMuiTheme({
  palette: {
    primary: {
      500: "#FDCA40",
    },
    yellow: {
      primary: "#FDCA40",
      secondary: "#FDCD0F",
      tertiary: "#FFF4D6",
    },
    white: {
      primary: "#FFFFFF",
      secondary: "#F5F5F5",
      tertiary: "#E5ECFF",
    },
    grey: {
      primary: "#333333",
      secondary: "#55555",
      tertiary: "#A2A2A2",
    },
    green: {
      main: "#3DDC97",
    },
    red: {
      main: "#FF6564",
    },
    blue: {
      main: "#29C0C7",
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: 600,
      },
    },
  },
});

const unProtectedRoute = (
  <Router>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={RegisterPage} />
      <Route exact path="/forget-password" component={ForgetPasswordPage} />
      <Route exact path="/form-reset-password/:token" component={ResetPasswordPage} />
      <Route render={() => <Redirect to="/login" />} />
    </Switch>
  </Router>
);

const protectedRoute = (
  <Router>
    <div style={{ display: "flex" }}>
      <Sidebar />
      <div
        style={{
          width: window.screen.width - 244,
          paddingLeft: 244,
        }}
      >
        <Navbar />
        <div style={{ padding: 20, marginTop: 79 }}>
          <Switch>
            <Route exact path="/" component={Home} />
            {listRoute.map((res, index) => (
              <Route key={index} path={res.path} component={res.component} />
            ))}
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </div>
      </div>
    </div>
  </Router>
);

function App() {
  const auth = React.useContext(AuthContext);

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        {auth.isAuthenticated && <ModalPopup />}
        {!auth.isAuthenticated ? unProtectedRoute : protectedRoute}
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
