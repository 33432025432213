import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Paper,
  Button,
} from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";
import TableContent from "./TableContent";
import DialogAddAnnouncement from "../Dialog/DialogAddAnnouncement";

const useTableStyles = makeStyles({
  container: {
    padding: "20px",
  },
  filterStatus: {
    marginLeft: "10px",
    minWidth: "200px",
  },
});

const Table = (props) => {
  const classes = useTableStyles();
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [search, setSearch] = React.useState("");

  return (
    <Paper elevation={3} style={{ marginTop: 20 }}>
      <div className={classes.container}>
        <div>
          <FormControl variant="standard">
            <OutlinedInput
              placeholder="Cari Produk"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div style={{ marginTop: 20 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsOpenDialog(true)}
          >
            Tambah Data
          </Button>
        </div>
      </div>

      {/* Table */}
      <TableContent
        search={search}
        {...props}
        open={isOpenDialog}
        onOpen={() => setIsOpenDialog(true)}
        onClose={() => setIsOpenDialog(false)}
        handleSearch={(value) => setSearch(value)}
      />
    </Paper>
  );
};

export default Table;
