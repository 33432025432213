import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Line } from "react-chartjs-2";
import { getChartMonthly, getReportSummary } from "../../services/axios";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { GET_REPORTS_SUMMARY_FILTER } from "services/reports.service";

const Analytic = withStyles(style)((props) => {
  const { classes } = props;
  const [reportSummary, setReportSummary] = useState({});
  const [chartDaily, setChartDaily] = useState({});
  const [isOpenDate, setIsOpenDate] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      key: "selection",
    },
  ]);

  const onApplyFilter = async () => {
    setIsOpenDate(!isOpenDate);
    const report = await GET_REPORTS_SUMMARY_FILTER(
      moment(selectionRange[0].startDate).format("YYYY-MM-DD"),
      moment(selectionRange[0].endDate).format("YYYY-MM-DD")
    );
    setReportSummary(report);
  };

  useEffect(() => {
    const fetch = async () => {
      const report = await getReportSummary();
      setReportSummary(report);
      const reportMonthly = await getChartMonthly();
      setChartDaily(reportMonthly);
    };
    fetch();
  }, []);

  const data = {
    labels:
      chartDaily.incomes &&
      Object.keys(chartDaily.incomes && chartDaily.incomes),
    datasets: [
      {
        label: "Pemasukan",
        fill: true,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointRadius: 1,
        pointHitRadius: 10,
        data: chartDaily.incomes && Object.values(chartDaily.incomes),
      },
      {
        label: "Pengeluaran",
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderCapStyle: "butt",
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(255, 99, 132, 1)",
        pointBackgroundColor: "rgba(255, 99, 132, 1)",
        pointHoverBackgroundColor: "rgba(255, 99, 132, 1)",
        pointHoverBorderColor: "rgba(255, 99, 132, 1)",
        pointRadius: 1,
        pointHitRadius: 10,
        data: chartDaily.expenses && Object.values(chartDaily.expenses),
      },
    ],
  };
  const options = {
    legend: {
      display: false,
    },
  };
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item sm={12} style={{ display: "flex", alignItems: "center" }}>
          <div className={classes.title}>
            <b>Analisis</b>
          </div>
          <div>
            <Button
              className={classes.filter}
              onClick={() => setIsOpenDate(!isOpenDate)}
            >
              <Typography>Filter</Typography>
              <ArrowDropDownIcon />
            </Button>
          </div>
        </Grid>
        <Grid item sm={12}>
          {isOpenDate && (
            <div className={classes.calendar}>
              <DateRangePicker
                onChange={(item) => setSelectionRange([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={selectionRange}
                direction="vertical"
              />
              <Button
                className={classes.filterApply}
                onClick={() => onApplyFilter()}
              >
                <Typography>Apply</Typography>
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography>
            <b>Best Location</b>
          </Typography>
          <div>
            <Paper style={{ marginTop: 20 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nama Kota</TableCell>
                      <TableCell align="center">Total Terjual</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportSummary.best_order_locations &&
                      reportSummary.best_order_locations.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">
                            <div style={{ display: "flex" }}>
                              <Typography variant="body2">
                                <b>{row.name}</b>
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body2">
                              {row.order_count} Penjualan
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <b>Best Seller</b>
          </Typography>

          <Paper style={{ marginTop: 20 }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Nama Produk</TableCell>
                    {/* <TableCell align="center">Rating</TableCell> */}
                    <TableCell align="center">Penjualan</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportSummary.best_sellers &&
                    reportSummary.best_sellers.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell align="center">{row.name}</TableCell>
                        {/* <TableCell align="center">{row.rating}</TableCell> */}
                        <TableCell align="center">{row.total}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            <b>Grafik Penjualan</b>
          </Typography>

          <Paper style={{ padding: 30, marginTop: 20 }}>
            <Line data={data} options={options} />
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default Analytic;
