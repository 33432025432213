import { Typography } from "@material-ui/core";
import Loading from "components/loading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_ECOURSE } from "services/e-course.service";

const ECoursePage = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();

  useEffect(() => {
    GET_ECOURSE(slug).then(({ data }) => {
      setData(data[0]);
      setIsLoading(false);
    });
  }, [slug]);

  return (
    <div style={{ maxWidth: 1200, margin: "auto" }}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Typography variant="h3" align="center" style={{ marginBottom: 20 }}>
            {data.title}
          </Typography>

          <img
            alt={data.title}
            src={data.image.url}
            height="500"
            style={{ margin: "auto", display: "block" }}
          />

          <div
            style={{ fontSize: 20, lineHeight: 2 }}
            dangerouslySetInnerHTML={{
              __html: data.content,
            }}
          ></div>
        </>
      )}
    </div>
  );
};

export default ECoursePage;
