import { axiosInstance } from "../config/fetchAxios";

export const GET_ACCOUNT_FLASHSALE = async () => {
  const url = `/api/v2/my-flashsales`
  const data = axiosInstance.get(url)
  return data
}

export const GET_ACCOUNT_FLASHSALE_ID = async (id) => {
  const url = `/api/v2/my-flashsales/${id}`
  const data = axiosInstance.get(url)
  return data
}

export const CREATE_FLASHSALE = (body) => {
  const url = `/api/v2/my-flashsales`
  const response = axiosInstance.post(url, body)
  return response
}

export const DELETE_FLASHSALE = (id) => {
  const url = `/api/v2/my-flashsales/${id}`
  const response = axiosInstance.delete(url)
  return response
}

export const EDIT_FLASHSALE = (id, body) => {
  const url = `/api/v2/my-flashsales/${id}`
  const response = axiosInstance.put(url, body)
  return response
}