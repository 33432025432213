import { Typography } from "@material-ui/core";
import React from "react";
import ECourseHeader from "./ECourseHeader/ECourseHeader";
import ECourseList from "./ECourseList/ECourseList";

const ECoursePage = () => {
  return (
    <div>
      <Typography variant="h3">E-Course</Typography>

      <ECourseHeader />

      <ECourseList />
    </div>
  );
};

export default ECoursePage;
