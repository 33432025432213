import {
  Box,
  Button,
  withStyles,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import {
  ImportExport,
  Sync,
  SystemUpdate,
  SystemUpdateRounded,
} from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import SystemUpdateAlt from "@material-ui/icons/SystemUpdateAlt";
import { axiosInstance } from "config/fetchAxios";
import React, { useEffect, useReducer, useState } from "react";
import {
  CONNECT_TO_CHANNEL,
  DISCONNECT_CHANNEL,
  GET_AVAILABLE_CHANNEL,
  GET_CONNECTED_CHANNEL,
  SYNC_PRODUCT_CHANNEL,
} from "services/marketplace.service";
import swal from "sweetalert";

const ConnectedButton = withStyles({
  root: {
    background: "#2196f3",
    color: "#fff",
    "&:hover": {
      background: "#2196f3",
    },
  },
})(Button);

const SyncMarketplaceButton = (props) => {
  const [channel, setChannel] = useState([]);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [update, forceUpdate] = useReducer((x) => x + 1, 0);
  const { onUpdate, onImportProduct } = props;

  useEffect(() => {
    GET_CONNECTED_CHANNEL().then(({ data }) => {
      const channelResponse = data;
      const channelData = [];

      GET_AVAILABLE_CHANNEL().then(({ data }) => {
        data.forEach((item) => {
          channelData.push({
            channel: item,
            status: channelResponse[item] || false,
          });
        });

        setChannel(channelData);
      });
    });
  }, [update]);

  const handleSync = async () => {
    setSyncInProgress(true);

    const connectedChannel = channel.filter((item) => item.status);

    await Promise.all(
      connectedChannel.map(async ({ channel }) => {
        await SYNC_PRODUCT_CHANNEL(channel);
      })
    ).catch(() => {
      setSyncInProgress(false);
    });

    setSyncInProgress(false);

    if (onUpdate) onUpdate();
  };

  const handleConnectToChannel = (code) => {
    CONNECT_TO_CHANNEL(code).then((response) => {
      if (response.redirect) {
        window.location.href = response.next.url;
        return;
      }

      if (response.form && code === "tokopedia") {
        const { submit } = response.form;

        handleTokopediaForm().then((shop_domain) => {
          axiosInstance.post(submit, { shop_domain }).then((response) => {
            swal(
              "Berhasil!",
              `Selamat, Integrasi dengan Tokopedia akan segera diproses.
              kamu akan menerima email lanjutan yang akan
              memberitahumu mengenai langkah selanjutnya.`,
              "success"
            );
          });
        });
      }
    });
  };

  const handleTokopediaForm = async () => {
    return new Promise((resolve) => {
      swal({
        title: "Integrasi Tokopedia",
        text: `Masukan link Tokopedia kamu. Contoh: https://www.tokopedia.com/furupulu`,
        content: "input",
        buttons: [
          "Batal",
          {
            text: "Hubungkan",
            closeModal: false,
          },
        ],
      })
        .then((domain) => {
          if (domain === null) throw new Error();

          const regex = /tokopedia.com\/((\w|-)+)/gm;

          return [...domain.matchAll(regex)].map((m) => m[1])[0];
        })
        .then((shop_domain) => {
          if (shop_domain) return resolve(shop_domain);

          swal(
            "Link Tokopedia Tidak Valid",
            "Harap masukan link Tokopedia yang valid!",
            "error"
          );
        })
        .catch((e) => {});
    });
  };

  const handleDisconnectChannel = (code) => {
    swal({
      title: "Apakah kamu yakin?",
      text: "Anda akan menghapus hubungan channel " + code.toUpperCase(),
      icon: "warning",
      dangerMode: true,
      buttons: ["Batal", "HAPUS"],
    }).then((willDelete) => {
      if (willDelete) {
        DISCONNECT_CHANNEL(code).then(() => {
          swal("Dihapus!", "Berhasil menghapus channel", "success").then(() => {
            forceUpdate();

            if (onUpdate) onUpdate();
          });
        });
      }
    });
  };

  const getLastUpdate = () => {
    const connectedChannel = channel
      .filter((item) => item.status)
      .sort((a, b) => {
        return (
          Date.parse(b.status.last_sync_at) - Date.parse(a.status.last_sync_at)
        );
      });

    if (connectedChannel.length > 0) {
      return connectedChannel[0].status.last_sync_at;
    }
  };

  return (
    <Box mt={4} display="flex" flexDirection="row">
      <Box mr={2} flexGrow={1}>
        <Tooltip title={`Terakhir update: ${getLastUpdate()}`}>
          <Button
            variant="contained"
            color="secondary"
            disabled={syncInProgress}
            onClick={handleSync}
          >
            {!syncInProgress ? (
              <Sync />
            ) : (
              <CircularProgress size={24} color="inherit" />
            )}
            <Box ml={1}>Sinkronisasi Produk</Box>
          </Button>
        </Tooltip>
        <Box display="inline" ml={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onImportProduct}
          >
            <SystemUpdateAlt />
            <Box ml={1}>Import Produk</Box>
          </Button>
        </Box>
      </Box>
      {channel.map((item) => (
        <Box mr={2}>
          {item.status ? (
            <Tooltip title="Klik untuk disconnect channel">
              <ConnectedButton
                variant="contained"
                color=""
                onClick={() => handleDisconnectChannel(item.channel)}
              >
                <Check />
                <span>{item.channel}</span>
              </ConnectedButton>
            </Tooltip>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleConnectToChannel(item.channel)}
            >
              Sambungkan ke {item.channel}
            </Button>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default SyncMarketplaceButton;
