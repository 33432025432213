import styled from 'styled-components'

export const WrapDetailSupplier = styled.div`
p{color: #787878; margin:0; padding: 8px 0px;}
  .profile {
    display: flex;
    .avatar {
      width: 124px;
      height: 124px;
      background: #C4C4C4;
      img{ width: 100% }
    }
    .info{
     margin-left: 24px;
      h2 {
        margin: 0;padding: 0;
        font-family: 'DMSans-Bold';
        font-size: 20px;
        color: #000;
      }
      .counterFollow {
        margin: 0;padding: 0;
        margin-top: 8px;
        color: #8C8484;
        font-size: 16px;
        font-family: 'DMSans-Bold';
      }
    }
  }
`

export const SocialMedia = styled.div`
  display: flex;
  .icon {
    width: 14px;
    height: 24px;
    img{ width: 100%}
  }
  .text {
    margin-left: 8px;
    p {
    margin:0; padding:0;
    color: #787878;
    font-size: 16px;
  }
  }
`