const styles = (theme) => ({
  bgIcon: {
    backgroundColor: theme.palette.yellow.primary,
    padding: '0 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12
  },
  wrapper: {
    display: 'flex',
    padding: '18px 16px',
  },
  title: {
    paddingBottom: 20,
    paddingTop: 30
  },
  filter: {
    borderRadius: 30,
    backgroundColor: theme.palette.white.tertiary,
    padding: `10px 30px`,
    margin: '0 10px',
    textTransform: "capitalize",
  },
  calendar: {
    borderRadius: 8,
    backgroundColor: theme.palette.white.tertiary,
    padding: `24px`,
  },
  filterApply: {
    marginTop: 24,
    borderRadius: 30,
    backgroundColor:theme.palette.red.main,
    color: '#FFF',
    padding: `10px 30px`,
    margin: '0 10px',
    textTransform: "capitalize",
    '&:hover': {
      backgroundColor:theme.palette.red.main,
      color: '#FFF',
    }
  },
  caption: {
    color: '#8C8484',
    fontSize: '14px',
    // fontFamily: 'DM Sans'
  }
})

export default styles;