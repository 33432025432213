import React, { Fragment } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Button,
  Divider,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@material-ui/core";

import numberSeparator from "../../../../utils/numberSeparator";
import {
  inputPriceOrderCustom,
  cancelOrderCustom,
} from "../../../../services/axios";
import swal from "sweetalert";

class CardOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      price: "",
      weight: "",
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.isLabelChecked !== nextProps.isLabelChecked ||
      this.props.data !== nextProps.data ||
      this.state !== nextState
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  render() {
    const slug = JSON.parse(localStorage.getItem("DATA")).slug;
    const { classes, index, onLoading, theme } = this.props;
    const { data } = this.state;

    const handleProperties = () => {
      const keys = Object.keys(data.properties);

      return (
        <List>
          {keys.map((key) => (
            <ListItem>
              <ListItemText>
                {key} = {data.properties[key]}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      );
    };

    const handleUpdatePrice = async () => {
      onLoading(true);

      if (this.state.price === "" || this.state.weight === "") {
        return swal("Oops!", "Harga atau berat tidak boleh kosong", "warning");
      }

      const response = await inputPriceOrderCustom({
        id: data.id,
        price: this.state.price,
        weight_gram: this.state.weight,
      });

      if (response.updated) {
        swal("Success!", "Pesanan berhasil diupdate!", "success");
        this.setState({
          data: response.data,
        });
      } else {
        swal("Oops!", "Pesanan gagal diupdate!", "warning");
      }
      onLoading(false);
    };

    const handleCancelOrder = async () => {
      onLoading(true);

      const response = await cancelOrderCustom({
        id: data.id,
      });

      if (response.updated) {
        swal("Success!", "Pesanan berhasil diupdate!", "success");
        this.setState({
          data: response.data,
        });
      } else {
        swal("Oops!", "Pesanan gagal diupdate!", "warning");
      }
      onLoading(false);
    };

    return (
      <Fragment>
        <div className={classes.wrapperCard}>
          <div className={classes.flexBetween} style={{ paddingBottom: 10 }}>
            <div className={classes.flexAlignCenter}>
              <Typography variant="h6">
                <b>#{index}</b>
              </Typography>
              <div style={{ paddingLeft: 10 }}>
                <Typography variant="body2">
                  Card Order ID <b>#{data.id}</b>
                </Typography>
              </div>
            </div>

            <div>Status : {data.status.message}</div>
          </div>

          <Divider className={classes.Divider} />
          <Box my={2} />
          <Grid container>
            <Grid item md={4} xs={12}>
              <Box mb={1}>
                <Typography variant="subtitle1">
                  <Box fontWeight={600}>Properti</Box>
                </Typography>
              </Box>
              <Box mb={1}>{handleProperties()}</Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box>
                {data.status.code === 10 && (
                  <>
                    <Box mb={1}>
                      <TextField
                        variant="outlined"
                        placeholder="Masukan harga"
                        type="number"
                        value={this.state.price}
                        onChange={(e) =>
                          this.setState({
                            price: e.target.value,
                          })
                        }
                      />
                    </Box>
                    <Box mb={1}>
                      <TextField
                        variant="outlined"
                        placeholder="Masukan berat (gram)"
                        type="number"
                        value={this.state.weight}
                        onChange={(e) =>
                          this.setState({
                            weight: e.target.value,
                          })
                        }
                      />
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleUpdatePrice}
                        >
                          Update harga
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          onClick={handleCancelOrder}
                        >
                          Batalkan pesanan
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid container justify="flex-end">
                <Grid item>
                  <Box mb={1}>
                    <img
                      height={80}
                      width={80}
                      src={data.product.image_url}
                      alt={data.product.name}
                    />
                  </Box>
                  <Box mb={1}>
                    <Typography variant="subtitle1">
                      <Box fontWeight={600}>Nama Produk</Box>
                    </Typography>
                    <Typography variant="caption">
                      {data.product.name}
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography variant="subtitle1">
                      <Box fontWeight={600}>Deskripsi</Box>
                    </Typography>
                    <Typography variant="caption">
                      {data.product.description}
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography variant="subtitle1">
                      <Box fontWeight={600}>Harga</Box>
                    </Typography>
                    <Typography variant="caption">
                      {data.price ? `RP.${numberSeparator(data.price)}` : "-"}
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography variant="subtitle1">
                      <Box fontWeight={600}>Berat(gram)</Box>
                    </Typography>
                    <Typography variant="caption">
                      {data.weight_gram || "-"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Divider className={classes.Divider} />
        </div>
      </Fragment>
    );
  }
}

export default withStyles(style)(withTheme(CardOrder));
