import styled from "styled-components"

export const WrapProfile = styled.div`
  position: relative;
  
  .wrap {
    width: 100%;
    display: flex;

    .avatar {
      width: 84px;
      height: 84px;
      img{
        width: 100%;
        border-radius: 50%;
        border: 2px solid #FBBD1C;
      }
    }
    .text {
      margin-left: 24px;
      p{padding: 0; margin:0;}
      p:nth-child(1){
        color: #050505;
        font-size: 20px;
        font-weight: 700;
        font-family: DMSans-Bold;
      }
      p:nth-child(2){
        color: #8C8484;
        font-size: 16px;
        margin-top: 13px;
      }
    }
  }
`