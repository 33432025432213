import { Tabs, Tab } from "@material-ui/core";
import { SearchCustom } from "components";
import React, { useState, useEffect } from "react";
import { DataTableVoucherWithTabsWrap } from "./style";
import moment from 'moment'
import DataTableVoucher from "containers/DataTableVoucher";
import { GET_VOUCHER } from "services/voucher.service";

const DataTableVoucherWithTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  const [dataAllPromo, setDataAllPromo] = useState([])
  const [isRefetch, setIsRefetch] = useState(false)

  const filteredSedangBerjalan = dataAllPromo.filter(data => moment().isBetween(moment(data.startDate).format("YYYY-MM-DD HH:mm"), moment(data.endDate).format("YYYY-MM-DD HH:mm")))

  const filteredAkanDatang = dataAllPromo.filter(data => !moment().isBetween(moment(data.startDate).format("YYYY-MM-DD HH:mm"), moment(data.endDate).format("YYYY-MM-DD HH:mm")))

  const filteredSelesai = dataAllPromo.filter(data => moment().isAfter(moment(data.endDate).format("YYYY-MM-DD HH:mm")))

  useEffect(() => {
    const fetchMyVoucher = async () => {
      let myVoucher = []
      await GET_VOUCHER().then( res => {
        res.data.map( data => {
          myVoucher.push({
            id: data.id,
            name: data.name,
            type: data.type,
            amount: data.amount,
            startDate: data.start_date,
            endDate: data.end_date,
            used: data.used,
          })
          return''
        })
      })
      setDataAllPromo(myVoucher)
    }
    
    if(isRefetch) {
      setDataAllPromo([])
      fetchMyVoucher()
      setIsRefetch(false)
    } else {
      fetchMyVoucher()
    }
  }, [isRefetch])

  const TabPanel = ({ children, activeTab, index }) => (
    <div
      role="tabpanel"
      hidden={activeTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {activeTab === index && <div className="content">{children}</div>}
    </div>
  );
  return (
    <DataTableVoucherWithTabsWrap>
      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
        <Tab label="Semua" />
        <Tab label="Sedang Berjalan" />
        <Tab label="Akan Datang" />
        <Tab label="Telah Berakhir" />
      </Tabs>
      <div style={{padding: '24px 16px'}}>
        <SearchCustom onChange={null} onSearch={null} withIcon />
      </div>
      <TabPanel activeTab={activeTab} index={0}>
        <DataTableVoucher rowsData={dataAllPromo} setIsRefetch={setIsRefetch} {...props}/>
      </TabPanel>
      <TabPanel activeTab={activeTab} index={1}>
        <DataTableVoucher rowsData={filteredSedangBerjalan} setIsRefetch={setIsRefetch} {...props}/>
      </TabPanel>
      <TabPanel activeTab={activeTab} index={2}>
        <DataTableVoucher rowsData={filteredAkanDatang} setIsRefetch={setIsRefetch} {...props}/>
      </TabPanel>
      <TabPanel activeTab={activeTab} index={3}>
        <DataTableVoucher rowsData={filteredSelesai} setIsRefetch={setIsRefetch} {...props}/>
      </TabPanel>
    </DataTableVoucherWithTabsWrap>
  );
};

export default DataTableVoucherWithTabs;
