import React from "react";
import { Grid, Typography } from "@material-ui/core";
import FormPotonganHarga from "containers/FormPotonganHarga";
import { ArrowBack } from "@material-ui/icons";

const PotonganHargaPage = (props) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ paddingRight: 20 }}>
          <Typography
            variant="h6"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ArrowBack
              style={{ paddingRight: 10, cursor: "pointer" }}
              onClick={() => window.history.back()}
            />
            <b>Potongan Harga</b>
          </Typography>
          <div style={{ paddingTop: 16 }}>
            <FormPotonganHarga {...props} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PotonganHargaPage;
