import Analytic from "../pages/analytic";
import Pengeluaran from "../pages/pengeluaran";
import Order from "../pages/order";
import OrderCustom from "../pages/order-custom";
import PromoPage from "../pages/promo";
import Products from "../pages/product/products";
import ProductsCategory from "../pages/product/category-product";
import EditRekeningPage from "pages/info-store-v2/edit-rekening";
import InfoStorePage from "pages/info-store-v2";
import FlashsalePage from "pages/promo/flashsale";
import PotonganHargaPage from "pages/promo/potonganHarga";
import VoucherPage from "pages/promo/voucher";
import BundlingPage from "pages/promo/bundling";
import CreateFlashsale from "pages/promo/flashsale/tambah-flashsale";
import EditFlashsale from "pages/promo/flashsale/edit-flashsale";
import CreateVoucher from "pages/promo/voucher/tambah-voucher";
import EditVoucher from "pages/promo/voucher/edit-voucher";
import CreateBundling from "pages/promo/bundling/tambah-bundling";
import EditBundling from "pages/promo/bundling/edit-bundling";
import HistoryTransactionPage from "pages/history-transaction";
import EProductPage from "pages/e-product";
import ECoursePage from "pages/e-course";
import ECourseDetailPage from "pages/e-course-detail";
import Marketplace from "pages/marketplace";
import AnnouncementsPage from "pages/announcements";

const listRoute = [
  {
    name: "ANALYTIC",
    path: "/analytic",
    component: Analytic,
  },
  {
    name: "EDIT REKENING",
    path: "/info/edit-rekening",
    component: EditRekeningPage,
  },
  {
    name: "INFO",
    path: "/info",
    component: InfoStorePage,
  },
  {
    name: "Detail E-Course",
    path: "/e-course/:slug",
    component: ECourseDetailPage,
  },
  {
    name: "E-Course",
    path: "/e-course",
    component: ECoursePage,
  },
  {
    name: "PENGELUARAN",
    path: "/pengeluaran",
    component: Pengeluaran,
  },
  {
    name: "ORDER",
    path: "/order",
    component: Order,
  },
  {
    name: "ORDER CUSTOM",
    path: "/order-custom",
    component: OrderCustom,
  },
  {
    name: "PRODUCTS",
    path: "/product/products",
    component: Products,
  },
  {
    name: "PRODUCT-CATEGORY",
    path: "/product/category-product",
    component: ProductsCategory,
  },
  {
    name: "FLASHSALE",
    path: "/promo/flashsale/new",
    component: CreateFlashsale,
  },
  {
    name: "FLASHSALE",
    path: "/promo/flashsale/edit/:id",
    component: EditFlashsale,
  },
  {
    name: "FLASHSALE",
    path: "/promo/flashsale",
    component: FlashsalePage,
  },
  {
    name: "VOUCHER",
    path: "/promo/voucher/new",
    component: CreateVoucher,
  },
  {
    name: "VOUCHER",
    path: "/promo/voucher/edit/:id",
    component: EditVoucher,
  },
  {
    name: "VOUCHER",
    path: "/promo/voucher",
    component: VoucherPage,
  },
  {
    name: "POTONGAN-HARGA",
    path: "/promo/potongan-harga",
    component: PotonganHargaPage,
  },
  {
    name: "BUNDLING",
    path: "/promo/bundling/edit/:id",
    component: EditBundling,
  },
  {
    name: "BUNDLING",
    path: "/promo/bundling/new",
    component: CreateBundling,
  },
  {
    name: "BUNDLING",
    path: "/promo/bundling",
    component: BundlingPage,
  },
  {
    name: "PROMO",
    path: "/promo",
    component: PromoPage,
  },
  {
    name: "Marketplace",
    path: "/marketplace",
    component: Marketplace,
  },
  {
    name: "HISTORYTRANSACTION",
    path: "/history-transaction",
    component: HistoryTransactionPage,
  },
  {
    name: "EPRODUCT",
    path: "/e-product",
    component: EProductPage,
  },
  {
    name: "ANNOUNCEMENTS",
    path: "/announcements",
    component: AnnouncementsPage,
  },
];

export default listRoute;
