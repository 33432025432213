import { axiosInstance } from "../config/fetchAxios";

export const POST_VOUCHER = (body) => {
  const url = `/api/v2/my-vouchers`
  const response = axiosInstance.post(url, body)
  return response
}

export const GET_VOUCHER = () => {
  const url = `/api/v2/my-vouchers`
  const data = axiosInstance.get(url)
  return data
}

export const GET_VOUCHER_ID = (id) => {
  const url = `/api/v2/my-vouchers/${id}`
  const data = axiosInstance.get(url)
  return data
}

export const EDIT_VOUCHER = (id, body) => {
  const url = `/api/v2/my-vouchers/${id}`
  const response = axiosInstance.put(url, body)
  return response
}

export const DELETE_VOUCHER = (id) => {
  const url = `/api/v2/my-vouchers/${id}`
  const response = axiosInstance.delete(url)
  return response
}
