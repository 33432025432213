import { axiosInstance } from "../config/fetchAxios";

export const GET_ECOURSES = async (params) => {
  const url = `/api/v2/ecourses?include=image,category`;
  const data = axiosInstance.get(url, {
    params,
  });
  return data;
};

export const GET_ECOURSE = async (slug) => {
  const url = `/api/v2/ecourses?filter[slug]=${slug}&include=image,category`;
  const data = axiosInstance.get(url);
  return data;
};

export const GET_ECOURSE_CATEGORIES = async () => {
  const url = `/api/v2/ecourse-categories`;
  const data = axiosInstance.get(url);
  return data;
};
