import styled from "styled-components";

export const FormAddPromoWrap = styled.div`
  position: relative;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  span {
    font-size: 10px;
    color: #777777;
  }
  .label {
    font-size: 16px;
    color: #555555;
    padding-left: 24px;
  }
  .desc {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 17px;
    color: #777777;
    padding-left: 24px;
  }
`;

export const RadioInputStyle = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 14px;
  }
  .PrivateRadioButtonIcon-checked-28 {
    color: #fdca40;
  }
`;
