import styled from "styled-components"

export const WrapInformationMessage = styled.div`
  position: relative;
  .btnIcon {
    position: relative;
    padding: 8px;
    text-align: center;
    font-size: 16px;
    color: #050505;
    border-radius: 5px;
    border: none;
    margin-top: 10px;
    background: #FFF2D0;
    outline: none;

    .bodyBtnIcon {
      display: flex;
      margin: 0 auto;
      width: max-content;
      align-items: center;
    }

    .icon {
      width: 24px;
      height: 24px;
      img {width: 100%}
      margin-right: 8px;
    }
  }
`