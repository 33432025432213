const style = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tambah: {
    borderRadius: 30,
    textTransform: "capitalize",
    color: "#ffffff",
  },
  other: {
    borderRadius: 30,
  },
  search: {
    position: "relative",
    borderRadius: 30,
    backgroundColor: "#f3f6fe",
    marginLeft: 0,
    width: 200,
    display: 'flex',
    alignitems: 'center'
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  formControl: {
    backgroundColor: "#f3f6fe",
    borderRadius: 30,
    marginLeft: 10,
  },
  select: {
    padding: '5px 24px 5px 12px',
  },
  loadingDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: window.screen.height - 79
  },
  loading: {
    textAlign: 'center'
  },
  inputHide: {
    opacity: 0.0,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 10
  },
  tabLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 400
  },
  notFound: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 400
  },
  notFound2: {
    textAlign: 'center'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(5),
    backgroundColor: theme.palette.green.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#35bd82",
    },
  },
  floatingButton: {
    position: "fixed",
    right: 36,
    bottom: 80,
  },
})

export default style;