import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";

import style from "./style";

const DataGridProductWithTabs = withStyles(style)((props) => {
  const { classes } = props;
  const [activeTab, setActiveTab] = useState(0);

  const TabPanel = ({ children, activeTab, index }) => (
    <div
      role="tabpanel"
      hidden={activeTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {activeTab === index && <div className="content">{children}</div>}
    </div>
  );

  return (
    <div className={classes.root}>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
      >
        <Tab label="Semua" />
        <Tab label="Diskon" />
        <Tab label="Diarsipkan" />
      </Tabs>
      <div style={{ padding: "24px 16px" }}>
        {/* <SearchCustom onChange={null} onSearch={null} withIcon /> */}
      </div>
      <TabPanel activeTab={activeTab} index={0}>
        {/* <DataTableBundling
          rowsData={dataAllPromo}
          setIsRefetch={setIsRefetch}
          {...props}
        /> */}
        1
      </TabPanel>
      <TabPanel activeTab={activeTab} index={1}>
        {/* <DataTableBundling
          rowsData={filteredSedangBerjalan}
          setIsRefetch={setIsRefetch}
          {...props}
        /> */}
        2
      </TabPanel>
      <TabPanel activeTab={activeTab} index={2}>
        {/* <DataTableBundling
          rowsData={filteredAkanDatang}
          setIsRefetch={setIsRefetch}
          {...props}
        /> */}
        3
      </TabPanel>
    </div>
  );
});

export default DataGridProductWithTabs;
