import React, { useCallback } from "react";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import _ from "lodash";
import {
  DELETE_ANNOUNCEMENT,
  GET_ANNOUNCEMENTS,
} from "services/aanouncements.service";
import { Box, Button, Collapse, IconButton } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import Loading from "components/loading";
import Check from "@material-ui/icons/Check";
import DialogAddAnnouncement from "../Dialog/DialogAddAnnouncement";

const headCells = [
  { id: "id", label: "No" },
  { id: "title", label: "Title" },
  { id: "message", label: "Message" },
  { id: "tags", label: "Tags" },
  { id: "link", label: "link" },
  { id: "kelola", label: "kelola" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  photo: {
    marginRight: 10,
    borderRadius: "50%",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
}));

function TableHeader() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const { row, index, handleDelete, onOpen, handleSelectedPayload } = props;
  let number = index;

  const handleEdit = () => {
    onOpen();
    handleSelectedPayload({
      id: row.id,
      title: row.title,
      message: row.message,
      tags: row.tags,
      link: row.link,
    });
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>{number++}</TableCell>
        <TableCell>{row.title}</TableCell>
        <TableCell>{row.message}</TableCell>
        <TableCell>
          {(row.tags || []).map((tag, i) => (
            <Chip
              style={{ marginRight: 8 }}
              key={i + tag}
              label={tag}
              color="primary"
            />
          ))}
        </TableCell>
        <TableCell>{row.link}</TableCell>
        <TableCell>
          <Button onClick={handleEdit} variant="contained" color="primary">
            Edit
          </Button>{" "}
          |{" "}
          <Button
            onClick={() => handleDelete(row.id)}
            variant="contained"
            color="secondary"
          >
            Hapus
          </Button>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function TableContent(props) {
  const classes = useStyles();
  const { search, open, onClose, onOpen } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [meta, setMeta] = React.useState({
    total: 1,
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedPayload, setSelectedPayload] = React.useState(undefined);

  const cancelTokenRef = React.useRef();

  const getData = useCallback(
    _.debounce(({ rowsPerPage, page, search, channel, getData }) => {
      if (cancelTokenRef.current) cancelTokenRef.current.cancel("canceled");

      const cancelToken = axios.CancelToken.source();
      cancelTokenRef.current = cancelToken;

      GET_ANNOUNCEMENTS(
        {
          paginate: rowsPerPage,
          page: page + 1,
          "filter[title]": search,
        },
        {
          cancelToken: cancelToken.token,
        }
      )
        .then(({ data, meta: { total, from } }) => {
          setRows(data);
          setMeta({ total, from });
          setIsLoading(false);
          cancelTokenRef.current = null;
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }, 1000),
    []
  );

  React.useEffect(() => {
    setPage(0);
  }, [search]);

  React.useEffect(() => {
    setIsLoading(true);
    getData({ rowsPerPage, page, search, getData });
  }, [rowsPerPage, page, search, getData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRefreshData = () => {
    getData({ rowsPerPage, page, search, getData });
  };

  const handleDelete = (id) => {
    swal({
      title: "Apakah Anda Yakin?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        DELETE_ANNOUNCEMENT(id);
        handleRefreshData();
      } else {
        swal("Dibatalkan!");
      }
    });
  };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHeader />

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell align="center" colSpan={headCells.length}>
                  <Loading style={{ height: 400 }} />
                </TableCell>
              </TableRow>
            ) : rows.length > 0 ? (
              rows.map((row, index) => (
                <Row
                  key={row.id}
                  index={index + meta.from}
                  row={row}
                  handleDelete={handleDelete}
                  handleSelectedPayload={(value) => setSelectedPayload(value)}
                  onOpen={onOpen}
                />
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={headCells.length}>
                  <Box
                    display="flex"
                    height={400}
                    justifyContent="center"
                    alignItems="center"
                  >
                    Tidak ada data
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={meta.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {open && (
        <DialogAddAnnouncement
          open={open}
          onClose={onClose}
          onOpen={onOpen}
          type="add"
          handleRefreshData={handleRefreshData}
          selectedPayload={selectedPayload}
          handleSelectedPayload={(value) => setSelectedPayload(value)}
        />
      )}
    </div>
  );
}
