import { Grid, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { WrapCard, WrapTable, WrapHistoryPage } from "./style";
import { DataGrid } from "@material-ui/data-grid";
import {
  GET_WALLET_INFO,
  GET_WALLET_TRANSACTIONS,
} from "services/wallet.service";
import { ButtonCustom } from "components";
import { formatRupiah } from "utils/currencyFormat";
import moment from "moment";
import DialogExportTransaction from "./Dialog/DialogExportTransaction";

const HistoryTransactionPage = (props) => {
  const [allData, setAllData] = React.useState([]);
  const [wallet, setWallet] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [totalData, setTotalData] = React.useState(0);
  const [isRefetch, setIsRefetch] = useState(false);
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let wallet = await GET_WALLET_INFO();
      setWallet(wallet.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchWallets = async () => {
      let wallets = [];
      let status = "";

      if (activeTab === 1) status = "0";
      else if (activeTab === 2) status = "1";
      else if (activeTab === 3) status = "2";

      await GET_WALLET_TRANSACTIONS(status, page).then((res) => {
        res.data.map((data, i) => {
          wallets.push({ id: i, ...data });
        });

        setAllData(wallets);
        setTotalData(res.meta.total);
      });
    };

    if (isRefetch) {
      setAllData([]);
      fetchWallets();
      setIsRefetch(false);
    } else {
      fetchWallets();
    }
  }, [isRefetch, page, activeTab]);

  const columns = [
    {
      field: "note",
      headerName: "Catatan",
      sortable: false,
      width: 200,
      renderCell: (params) => <>{params.row.note}</>,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        if (params.row.status === "IN") {
          return <span style={{ color: "green" }}>Masuk</span>;
        } else if (params.row.status === "OUT") {
          return <span style={{ color: "red" }}>Keluar</span>;
        } else return <span>Ongoing</span>;
      },
    },
    {
      field: "amount",
      headerName: "Jumlah",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        let color = "";
        if (params.row.status === "IN") {
          color = "green";
        } else if (params.row.status === "OUT") {
          color = "red";
        }
        return (
          <span style={{ color: color }}>
            Rp.{formatRupiah(params.row.amount)}
          </span>
        );
      },
    },
    {
      field: "supplier_gross_idr",
      headerName: "Pemasukan kotor",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        if (params.row.order) {
          return <>Rp.{formatRupiah(params.row.order.supplier_gross_idr)}</>;
        } else return "-";
      },
    },
    {
      field: "platform_fee",
      headerName: "Fee Idejualan",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        if (params.row.order) {
          return <>Rp.{formatRupiah(params.row.order.platform_fee)}</>;
        } else return "-";
      },
    },
    {
      field: "shipping_cost",
      headerName: "Ongkir",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        if (params.row.order) {
          if (
            params.row.order.is_cod ||
            params.row.order.shipment_type === "PICKUP"
          ) {
            return <>Rp.{formatRupiah(0)}</>;
          } else return <>Rp.{formatRupiah(params.row.order.shipping_cost)}</>;
        } else return "-";
      },
    },
    {
      field: "voucher_is_admin",
      headerName: "Voucher",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        if (params.row.order) {
          if (!params.row.order.voucher_is_admin) {
            return (
              <>
                Rp.
                {formatRupiah(params.row.order.voucher_amount) || 0} -{" "}
                {params.row.order.voucher_type}
              </>
            );
          } else return "-";
        } else return "-";
      },
    },
    {
      field: "supplier_net_idr",
      headerName: "Saldo",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        if (params.row.saldo) {
          return <>Rp.{formatRupiah(params.row.saldo)}</>;
        } else return "-";
      },
    },
    {
      field: "created_at",
      headerName: "Tanggal transaksi",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <>{moment(params.row.created_at).format("DD MMM YYYY HH:MM")}</>
      ),
    },
  ];

  const handleOpenExport = () => {
    setOpen(true);
  };

  const handleCloseExport = () => {
    setOpen(false);
  }

  const TabPanel = ({ children, activeTab, index }) => (
    <div
      role="tabpanel"
      hidden={activeTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {activeTab === index && <div className="content">{children}</div>}
    </div>
  );

  const handleChangeTab = (value) => {
    setPage(1);
    setActiveTab(value);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <h4>Riwayat Transaksi</h4>
        </Grid>
        <Grid item xs={12}>
          <WrapCard>
            <div>
              <span>Total Available</span>
              <span>Rp {formatRupiah(wallet?.amount_available ?? 0)}</span>
            </div>
            <div>
              <span>Total Withdrawed</span>
              <span>Rp {formatRupiah(wallet?.withdrawed ?? 0)}</span>
            </div>
            <div>
              <span>Total Ongoing</span>
              <span>Rp {formatRupiah(wallet?.amount_ongoing ?? 0)}</span>
            </div>
          </WrapCard>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 16 }}>
        <Grid item xs={12}>
          <WrapHistoryPage>
            <div className="header">
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => handleChangeTab(newValue)}
            >
              <Tab label="Semua" />
              <Tab label="Keluar" />
              <Tab label="Masuk" />
              <Tab label="On going" />
            </Tabs>
            <ButtonCustom 
              color="red"
              withBorder={true}
              onClick={handleOpenExport}
            >export</ButtonCustom>
            </div>
            <TabPanel activeTab={activeTab} index={0}>
              {/* <WrapTable> */}
              <DataGrid
                rows={allData}
                rowCount={totalData}
                columns={columns}
                pageSize={20}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                pagination
                paginationMode="server"
                autoHeight
                page={page}
                onPageChange={(params) => setPage(params.page)}
              />
              {/* </WrapTable> */}
            </TabPanel>
            <TabPanel activeTab={activeTab} index={1}>
              {/* <WrapTable> */}
              <DataGrid
                rows={allData}
                rowCount={totalData}
                columns={columns}
                pageSize={20}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                pagination
                paginationMode="server"
                autoHeight
                page={page}
                onPageChange={(params) => setPage(params.page)}
              />
              {/* </WrapTable> */}
            </TabPanel>
            <TabPanel activeTab={activeTab} index={2}>
              {/* <WrapTable> */}
              <DataGrid
                rows={allData}
                rowCount={totalData}
                columns={columns}
                pageSize={20}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                pagination
                paginationMode="server"
                autoHeight
                page={page}
                onPageChange={(params) => setPage(params.page)}
              />
              {/* </WrapTable> */}
            </TabPanel>
            <TabPanel activeTab={activeTab} index={3}>
              {/* <WrapTable> */}
              <DataGrid
                rows={allData}
                rowCount={totalData}
                columns={columns}
                pageSize={20}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                pagination
                paginationMode="server"
                autoHeight
                page={page}
                onPageChange={(params) => setPage(params.page)}
              />
              {/* </WrapTable> */}
            </TabPanel>
          </WrapHistoryPage>
        </Grid>
      </Grid>
      <DialogExportTransaction
          {...props}
          open={open}
          onClose={handleCloseExport}
        />
    </div>
  );
};

export default HistoryTransactionPage;
