const style = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  inputStyle: {
    border: `1px solid #e5e5e5`,
    borderRadius: 5,
    paddingLeft: 10,
    font: 13,
  },
  inputSelectStyle: {
    "& .MuiInputBase-root.Mui-disabled": {
      cursor: "pointer",
      color: "#777777",
    },
  },
  inputAdornment: {
    color: "#7777",
    borderLeft: `1px solid #e5e5e5`,
    padding: "0 8px",
    marginLeft: 8,
  },
  komisi: {
    display: "flex",
    border: `1px solid ${theme.palette.green.main}`,
    borderRadius: 30,
    paddingLeft: 10,
    width: "100%",
    marginRight: 10,
  },
  textKomisi: {
    display: "flex",
    paddingLeft: 10,
    paddingRight: 15,
    width: 50,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.green.main,
    borderRadius: "0 30px 30px 0",
    color: "#ffffff",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#3db581",
    },
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  textVar: {
    display: "flex",
    alignItems: "center",
  },
  flex: {
    display: "flex",
  },
  flexWidthCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  discount: {
    display: "flex",
    border: `1px solid ${theme.palette.green.main}`,
    borderRadius: 30,
    paddingLeft: 10,
    width: "100%",
    marginRight: 10,
  },
  discountPercent: {
    display: "flex",
    paddingLeft: 10,
    paddingRight: 15,
    alignItems: "center",
    backgroundColor: theme.palette.green.main,
    borderRadius: "0 30px 30px 0",
    color: "#ffffff",
  },
  description: {
    border: `1px solid ${theme.palette.green.main}`,
    borderRadius: 5,
    padding: 10,
    marginTop: 10,
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  kategori: {
    padding: "6px 0 7px",
    lineHeight: "initial",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 5,
  },
  tambahGambar: {
    border: `2px dashed #29C0C7`,
    borderRadius: 5,
    height: 125,
    width: 125,
    cursor: "pointer",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  tambahGambar1: {
    height: "84%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 100,
    color: "#29C0C7",
  },
  inputGambar: {
    opacity: 0.0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
});

export default style;
