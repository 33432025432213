import { Grid, InputAdornment, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormAddPromoWrap } from "./style";
import iconAdd from "../../assets/icons/ic-add.svg";
import { ActionButton, ButtonCustom } from "components";
import { ModalTableProduct } from "containers";
import _ from "lodash";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { formatRupiah } from "utils/currencyFormat";
import { FormTablePromoWrap } from "components/FormTablePromo/style";
import { CREATE_FLASHSALE, EDIT_FLASHSALE, GET_ACCOUNT_FLASHSALE_ID } from "services/flashsale.service";
import swal from "sweetalert";

const FormFlashsale = (props) => {
  const id = props.id;
  const isEdit = id !== undefined
  const [name] = useState("Flashsale");
  const [isOpenModalTable, setIsOpenModalTable] = useState(false);
  const [items, setItems] = useState([]);
  const [dataForm, setDataForm] = useState({
    name: "",
    startDate: moment().format("YYYY-MM-DDTHH:mm"),
    endDate: moment().add({ days: 1 }).format("YYYY-MM-DDTHH:mm"),
  });

  useEffect(() => {
    if (isEdit) {
      GET_ACCOUNT_FLASHSALE_ID(id).then(({ data }) => {
        setDataForm({
          name: data.name,
          startDate: moment(data.start_date).format("YYYY-MM-DDTHH:mm"),
          endDate: moment(data.end_date).format("YYYY-MM-DDTHH:mm"),
        })
        setItems(data.items.map((item => {
          item.product_slug = item.product.slug
          return item
        })))
      })
    }
  }, [id, isEdit])

  const handleChangeInput = (formName, value) => {
    setDataForm({ ...dataForm, [formName]: value });
  };

  const toggleDiscountType = (product) => {
    setItems((items) => [
      ...items.map((item) => {
        if (item.product_slug === product.slug) {
          item.type = item.type === "PERCENT" ? "FIXED" : "PERCENT";
        }
        return item;
      }),
    ]);
  };

  const changeAmount = (product, amount) => {
    setItems((items) => [
      ...items.map((item) => {
        if (item.product_slug === product.slug) {
          item.amount = amount
        }
        return item;
      }),
    ]);
  };

  const deleteProduct = (product) => {
    setItems((items) => [
      ...items.filter((item) => item.product_slug !== product.slug),
    ]);
  };

  const _renderForm = () => {
    return (
      <>
        <div style={{ padding: 24 }}>
          <h3>1. Informasi Dasar</h3>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Nama {name}
              <div>
                <span>* ditampilkan pada Tokomu</span>
              </div>
            </Grid>
            <Grid item xs={8}>
              <TextField
                placeholder={"Nama " + name}
                variant="outlined"
                size="small"
                fullWidth
                value={dataForm.name}
                onChange={(e) => handleChangeInput("name", e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 14 }}>
            <Grid item xs={3} className="label">
              Periode {name}
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="datetime-local"
                type="datetime-local"
                defaultValue={dataForm.startDate}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                variant="outlined"
                size="small"
                value={dataForm.startDate}
                onChange={(e) => handleChangeInput("startDate", e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="datetime-local"
                type="datetime-local"
                defaultValue={dataForm.endDate}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                variant="outlined"
                size="small"
                value={dataForm.endDate}
                onChange={(e) => handleChangeInput("endDate", e.target.value)}
              />
            </Grid>
          </Grid>

          <h3>2. Pilih Produk</h3>
          <p className="desc">
            Tambahkan Produk ke Promo ini, lalu atur Potongan Harganya
          </p>
          <ButtonCustom
            icon={iconAdd}
            onClick={() => setIsOpenModalTable(!isOpenModalTable)}
            color="red"
            withBorder={true}
            style={{ marginLeft: 24, marginTop: 16 }}
          >
            Tambah Produk
          </ButtonCustom>
        </div>
        {/* PRODUCTS TABLE */}
        <FormTablePromoWrap>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nama Produk</TableCell>
                  <TableCell>Harga Awal</TableCell>
                  <TableCell>Jumlah Potongan Harga</TableCell>
                  <TableCell align="right">Aksi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(({ product, type, amount }) => (
                  <TableRow key={product.slug}>
                    <TableCell component="th" scope="row">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={product.image_url}
                          alt={product.name}
                          width="40"
                          style={{ padding: 4 }}
                        />
                        {product.name}
                      </div>
                    </TableCell>
                    <TableCell>
                      Rp. {formatRupiah(product.skus[0].price.reseller)}
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        type="number"
                        style={{ height: 38 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className="switchPercent"
                              onClick={() => toggleDiscountType(product)}
                            >
                              {type === "PERCENT" ? "%" : "Rp"}
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => changeAmount(product, e.target.value)}
                        defaultValue={amount ?? 0}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <div style={{ marginTop: 8 }}>
                        <ActionButton
                          circleBorder
                          type="delete"
                          onClick={() => deleteProduct(product)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FormTablePromoWrap>
        {/* END PRODUCTS TABLE */}
        <div
          style={{
            display: "flex",
            position: "relative",
            float: "right",
            marginTop: 20,
          }}
        >
          <ButtonCustom
            withBorder
            color={"#929292"}
            onClick={() => window.history.back()}
          >
            Batal
          </ButtonCustom>
          <ButtonCustom
            bgColor="red"
            style={{ marginLeft: 16 }}
            onClick={() => handleSubmit()}
          >
            Simpan Promo
          </ButtonCustom>
        </div>
      </>
    );
  };

  const handleSubmit = async (type) => {
    if (dataForm.name === "") {
      return swal("Error", "Silahkan isi nama", "error");
    }

    if (!items.length) {
      return swal("Error", "Silahkan pilih produk terlebih dahulu", "error");
    }

    if (!isEdit) {
      await CREATE_FLASHSALE({
        name: dataForm.name,
        start_date: moment(dataForm.startDate).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(dataForm.endDate).format("YYYY-MM-DD HH:mm:ss"),
        items: [...items].map((item) => {
          delete item.product;
          return item;
        }),
      })
        .then(() => {
          swal("Success", "Berhasil menambahkan promo", "success");
          props.history.goBack();
        })
        .catch((error) => {
          console.group("Gagal menambah flashsale");
          console.error("Request", JSON.parse(error.config.data));
          console.error("Response", error.response.data);
          console.groupEnd();
          swal("Error", error.response.data.message, "error");
        });
    } else {
      await EDIT_FLASHSALE(id, {
        name: dataForm.name,
        start_date: moment(dataForm.startDate).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(dataForm.endDate).format("YYYY-MM-DD HH:mm:ss"),
        items: [...items].map((item) => {
          delete item.product;
          return item;
        }),
      })
        .then(() => {
          swal("Success", "Berhasil merubah promo", "success");
          props.history.goBack();
        })
        .catch((error) => {
          console.group("Gagal merubah flashsale");
          console.error("Request", JSON.parse(error.config.data));
          console.error("Response", error.response.data);
          console.groupEnd();
          swal("Error", error.response.data.message, "error");
        });
    }
  };

  const onCloseModal = () => {
    setIsOpenModalTable(!isOpenModalTable);
  };

  const handleSubmitAddProduct = async (products) => {
    onCloseModal();
    setItems((items) => [
      ...items.filter((item) =>
        products.some((product) => product.slug === item.product_slug)
      ),
      ...products
        .filter((product) =>
          items.every((item) => product.slug !== item.product_slug)
        )
        .map((product) => ({
          product_slug: product.slug,
          type: "FIXED",
          amount: 0,
          product: product,
        })),
    ]);
  };

  return (
    <FormAddPromoWrap>
      <div>{_renderForm()}</div>
      <ModalTableProduct
        isOpen={isOpenModalTable}
        onClose={onCloseModal}
        selectedProducts={items.map((item) => item.product)}
        handleSubmit={handleSubmitAddProduct}
      />
    </FormAddPromoWrap>
  );
};

export default FormFlashsale;
