import React, {useState, useEffect} from 'react'
import style from "./style";
import { withStyles } from "@material-ui/core/styles";
import { Backdrop, Box, Button, Grid, Hidden, TextField, Typography } from '@material-ui/core';
import Brandlogo from "../../assets/images/Brand.svg";
import IlustrationDesktop from "../../assets/images/ilustrasi.svg";
import BackgoundDesktop from "../../assets/images/background.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import MobileVectorBg from "../../assets/images/Vector1.svg";
import MobileVector from "../../assets/images/login2.svg";
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { ReqTokenResetPasswordSupplier } from 'services/axios';

const ForgetPasswordPage = withStyles(style)((props) => {
  const { classes } = props;
  let history = useHistory()
  const [isLoading, setisLoading] = useState(false)
  const [state, setstate] = useState({
    email: ''
  })

  const handleChangeInput = (event) => {
    setstate({...state, [event.target.name]: event.target.value});
  };

  const handleSubmit = async(event) => {
    event.preventDefault()
    const redirect = window.location.origin + "/form-reset-password";

    const data = {
      email: state.email,
      redirect: redirect,
    };
    if (state.email !== "") {
      setisLoading(true);
      try {
        const response = await ReqTokenResetPasswordSupplier(data);
        swal("Success!", response.message, "success").then(() =>
          history.push("/login")
        );
      } catch (error) {
        swal('Error', error.response.data.message, 'error').then(() =>
          setisLoading(false)
        );
      }
    }else{
      swal('Error', 'Maaf Email Harus Di Isi', 'error')
    }
  }

  console.log(state)

  return (
    <Box className={classes.container}>
      <Hidden smDown>
        <img src={Brandlogo} alt="idejualan" className={classes.logo} />
      </Hidden>

      <Hidden mdUp>
        <Box>
          <div className={classes.logoMobile}>
            <img
              src={Brandlogo}
              alt="idejualan"
              style={{ height: 40, marginTop: 10 }}
            />
          </div>
          <div>
            <img src={MobileVectorBg} alt="" style={{ width: "100%" }} />
          </div>
        </Box>
      </Hidden>

      <Grid container>
        <Hidden smDown>
          <Grid item xs={6}>
            <div className={classes.containerImg}>
              <img
                src={BackgoundDesktop}
                alt="idejualan"
                style={{ height: "100%" }}
              />
              <img src={IlustrationDesktop} alt="" className={classes.vector} />
            </div>
          </Grid>
        </Hidden>

        {/* content */}
        <Grid item xs={12} sm={12} md={6}>
          <Box className={classes.containerContent}>
            <Box style={{ maxWidth: 450, maxHeight: 350, padding: 20, marginTop: 100, border: '1px solid #fbbb14', borderRadius: 8 }}>
              <Typography variant='h5'>Reset Password</Typography>
              <div style={{ paddingTop: 20 }}>
                <Typography className={classes.guide}>
                  Masukkan email yang terdaftar. Kami akan mengirimkan
                  kode verifikasi untuk mengatur ulang kata sandi.
                </Typography>
                <form onSubmit={(event) => {handleSubmit(event)}}>
                  <TextField
                    name='email'
                    label="Email"
                    type="email"
                    fullWidth={true}
                    value={state.email}
                    variant="outlined"
                    onChange={handleChangeInput}
                    required
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    className={classes.styledButton}
                    type='submit'
                  >
                    Kirim
                  </Button>
                </form>
                <Typography style={{ textAlign: "center" }}>
                  Kembali ke halaman{" "}
                  <span className={classes.link} onClick={() => history.push("/login")}>
                    login
                  </span>{" "}
                  atau{" "}
                  <span
                    className={classes.link}
                    onClick={() => history.push("/register")}
                  >
                    daftar
                  </span>
                </Typography>
              </div>
              <Backdrop open={isLoading} className={classes.backdrop}>
                <CircularProgress color="primary" />
              </Backdrop>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
})

export default ForgetPasswordPage