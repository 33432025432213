import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Checkbox,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  getAllCity,
  editSupplier,
  getAllCourier,
  syncSupplierCourier,
  getProvinces,
  getCitiesByProvinceId,
  getDistrictsByCityId,
  getSubDistrictsByDisctrictId,
} from "../../../../services/axios";
import swal from "sweetalert";

const getCodeCouriers = (data) => {
  let resultData = [];
  data.forEach((res) => {
    resultData = [...resultData, res.code];
  });
  return resultData;
};

const EditInfo = withStyles(style)((props) => {
  const { classes, handleClose, dataApi } = props;
  const [state, setState] = useState({
    avatar: null,
    name: dataApi.name,
    description: dataApi.description,
    email: dataApi.email,
    is_dropship: dataApi.is_dropship == true ? true : false,
    address: dataApi.location.address,
    password: "",
    phone: dataApi.phone,
    postal_code: dataApi.location.postal_code,
    couriers: getCodeCouriers(dataApi.supported_couriers),
    province_id: dataApi.location.village?.district?.city?.province_id,
    city_id: dataApi.location.village?.district?.city?.id,
    district_id: dataApi.location.village?.district?.id,
    village_id: dataApi?.location?.village?.id,
    is_cod: dataApi.is_cod,
    shipment_type: dataApi.shipment_type,
  });
  const [allCourier, setAllCourier] = useState(null);
  const [selectedGambar, setSelectedGambar] = useState(dataApi.avatar_url);
  const [updated, setUpdated] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDisctricts] = useState([]);
  const [subDistricts, setSubDistricts] = useState([]);

  console.log(state)

  React.useEffect(() => {
    handleGetProvinces();

    if (state.city_id) {
      handleGetCities(state.province_id);
    }

    if (state.district_id) {
      handleGetDistricts(state.city_id);
    }

    if (state.village_id) {
      handleGetSubDistricts(state.district_id);
    }
  }, []);

  const handleChangeProvince = (e) => {
    setState({
      ...state,
      province_id: e.target.value,
      city_id: null,
      district_id: null,
      village_id: null,
      postal_code: null,
    });
    setDisctricts([]);
    setSubDistricts([]);
    handleGetCities(e.target.value);
  };

  const handleChangeCity = (e) => {
    setState({
      ...state,
      city_id: e.target.value,
      district_id: null,
      village_id: null,
      postal_code: null,
    });
    setSubDistricts([]);
    handleGetDistricts(e.target.value);
  };

  const handleChangeDistrict = (e) => {
    setState({
      ...state,
      district_id: e.target.value,
      village_id: null,
      postal_code: null,
    });
    handleGetSubDistricts(e.target.value);
  };

  const handleChangeSubDistrict = (e) => {
    const value = e.target.value;

    const matchedSubDistrict = subDistricts.find((sub) => sub.id === value);

    setState({
      ...state,
      village_id: value,
      postal_code: matchedSubDistrict ? matchedSubDistrict.kodepos : null,
    });
  };

  const handleGetProvinces = async () => {
    const response = await getProvinces();

    if (response) {
      setProvinces(response.data);
    }
  };

  const handleGetCities = async (id) => {
    const response = await getCitiesByProvinceId(id);

    if (response) {
      setCities(response.data);
    }
  };

  const handleGetDistricts = async (id) => {
    const response = await getDistrictsByCityId(id);

    if (response) {
      setDisctricts(response.data);
    }
  };

  const handleGetSubDistricts = async (id) => {
    const response = await getSubDistrictsByDisctrictId(id);

    if (response) {
      setSubDistricts(response.data);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const fetch = async () => {
      const resCity = await getAllCity();
      const resCourier = await getAllCourier();
      setAllCourier(resCourier.data);
    };
    fetch();
  }, []);

  const handleSetState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const handleChangePhoto = async (event) => {
    if (event.target.files[0].type === "image/jpeg") {
      setState({ ...state, avatar: event.target.files[0] });
      setSelectedGambar(URL.createObjectURL(event.target.files[0]));
    } else swal("Oops!", "Gunakan File dengan exstensi JPG/JPEG", "warning");
  };

  const handleChangeCheckbokCourier = (code) => {
    if (state.couriers.indexOf(code) !== -1) {
      setState({
        ...state,
        couriers: state.couriers.filter((value) => value !== code),
      });
    } else {
      setState({ ...state, couriers: [...state.couriers, code] });
    }
  };

  const handleSimpan = async () => {
    if (!state.village_id) {
      return swal("Oops!", "Kelurahan tidak boleh kosong", "warning");
    }

    const formData = new FormData();
    let is_dropshipp = state.is_dropship == true ? 1 : 0;
    state.name !== dataApi.name && formData.append("name", state.name);
    state.avatar !== null && formData.append("avatar", state.avatar);
    state.description !== dataApi.description &&
      formData.append("description", state.description);
    state.email !== dataApi.email && formData.append("email", state.email);
    state.is_dropship !== dataApi.is_dropship &&
      formData.append("is_dropship", is_dropshipp);
    state.address !== dataApi.location.address &&
      formData.append("address", state.address);
    state.phone !== dataApi.phone && formData.append("phone", state.phone);
    state.postal_code !== dataApi.location.postal_code &&
      formData.append("postal_code", state.postal_code);
    state.password.length !== 0 && formData.append("password", state.password);
    formData.append("village_id", state.village_id);
    formData.append("is_cod", state.is_cod ? 1 : 0);
    formData.append("shipment_type", state.shipment_type);
    formData.append("_method", "PATCH");

    if (
      state.name !== dataApi.name ||
      state.avatar !== null ||
      state.description !== dataApi.description ||
      state.email !== dataApi.email ||
      is_dropshipp !== dataApi.is_dropship ||
      state.address !== dataApi.location.address ||
      state.phone !== dataApi.phone ||
      state.postal_code !== dataApi.location.postal_code ||
      state.password.length !== 0
    ) {
      // state.subdistrict_id !== dataApi.location.subdistrict.id
      const response = await editSupplier(dataApi.slug, formData);
      if (response.updated) {
        localStorage.setItem("DATA", JSON.stringify(response.data));
        setUpdated(true);
      }
    }

    function getMatch(a, b) {
      var matches = false;
      for (var i = 0; i < a.length; i++) {
        for (var e = 0; e < b.length; e++) {
          if (a[i] === b[e]) {
            matches = true;
            i = a.length - 1;
          }
        }
      }
      return matches;
    }

    if (
      getMatch(getCodeCouriers(dataApi.supported_couriers), state.couriers)
        .length !== state.couriers.length
    ) {
      const response = await syncSupplierCourier(dataApi.slug, {
        couriers: state.couriers,
      });
      if (response.message === "Courier preference synced successfuly.")
        setUpdated(true);
    }
    swal("Success", "Info berhasil terupdate", "success").then(() =>
      handleClose()
    );
  };

  return (
    <Fragment>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.flexCenter}>
          <ArrowBackIcon
            style={{ paddingRight: 10, cursor: "pointer" }}
            onClick={() => handleClose()}
          />
          <b>Edit Info</b>
        </Typography>
      </div>

      <Paper style={{ padding: 20, marginTop: 30 }}>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography>
                <b>Logo</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <div
                className={classes.logo}
                style={{ backgroundImage: `url(${selectedGambar})` }}
              >
                <div className={classes.buttonLogo}>
                  <Typography className={classes.buttonLogoText}>
                    Ganti Logo
                    <input
                      type="file"
                      className={classes.inputGambar}
                      onChange={(event) => handleChangePhoto(event)}
                    />
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid item xs={3} className={classes.flexCenter}>
              <Typography>
                <b>Nama Toko</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                value={state.name}
                style={{ marginRight: 20 }}
                className={classes.inputStyle}
                onChange={(e) => handleSetState("name", e.target.value)}
              />
            </Grid>

            <Grid
              item
              xs={2}
              style={{ paddingLeft: 30 }}
              className={classes.flexCenter}
            >
              <Typography>
                <b>Email</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                value={state.email}
                className={classes.inputStyle}
                onChange={(e) => handleSetState("email", e.target.value)}
              />
            </Grid>

            <Grid item xs={3} className={classes.flexCenter}>
              <Typography>
                <b>No. Telp</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                value={state.phone}
                className={classes.inputStyle}
                onChange={(e) => handleSetState("phone", e.target.value)}
              />
            </Grid>

            <Grid
              item
              xs={2}
              style={{ paddingLeft: 30 }}
              className={classes.flexCenter}
            >
              <Typography>
                <b>Komisi Idejualan</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                disabled
                value={
                  (dataApi.platform_fee_type === "FIXED" ? "Rp. " : "") +
                  dataApi.platform_fee_value +
                  (dataApi.platform_fee_type === "PERCENT" && " %")
                }
                className={classes.inputStyle}
              />
            </Grid>

            <Grid item xs={3} className={classes.flexCenter}>
              <Typography>
                <b>Password</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                placeholder="Abaikan bila tidak ingin diubah"
                value={state.password}
                className={classes.inputStyle}
                onChange={(e) => handleSetState("password", e.target.value)}
              />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3} className={classes.flexCenter}>
              <Typography>
                <b>Provinsi</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Select
                fullWidth
                variant="outlined"
                value={state.province_id}
                onChange={handleChangeProvince}
                displayEmpty
              >
                <MenuItem disabled value={null}>
                  --Pilih provinsi--
                </MenuItem>
                {provinces.map((province) => (
                  <MenuItem key={province.id} value={province.id}>
                    {province.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3} className={classes.flexCenter}>
              <Typography>
                <b>Kota / Kabupaten</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Select
                fullWidth
                variant="outlined"
                value={state.city_id}
                onChange={handleChangeCity}
                displayEmpty
              >
                <MenuItem disabled value={null}>
                  --Pilih kota / kabupaten--
                </MenuItem>
                {cities.map((city) => (
                  <MenuItem key={city.id} value={city.id}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3} className={classes.flexCenter}>
              <Typography>
                <b>Kecamatan</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Select
                fullWidth
                variant="outlined"
                value={state.district_id}
                onChange={handleChangeDistrict}
                displayEmpty
              >
                <MenuItem disabled value={null}>
                  --Pilih kecamatan--
                </MenuItem>
                {districts.map((district) => (
                  <MenuItem key={district.id} value={district.id}>
                    {district.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3} className={classes.flexCenter}>
              <Typography>
                <b>kelurahan</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Select
                fullWidth
                variant="outlined"
                value={state.village_id}
                onChange={handleChangeSubDistrict}
                displayEmpty
              >
                <MenuItem disabled value={null}>
                  --Pilih kelurahan--
                </MenuItem>
                {subDistricts.map((subDistrict) => (
                  <MenuItem key={subDistrict.id} value={subDistrict.id}>
                    {subDistrict.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3} className={classes.flexCenter}>
              <Typography>
                <b>Kode Pos</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                value={state.postal_code}
                className={classes.inputStyle}
                onChange={(e) => handleSetState("postal_code", e.target.value)}
              />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3} className={classes.flexCenter}>
              <Typography>
                <b>Alamat Lengkap</b>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                value={state.address}
                className={classes.inputStyle}
                onChange={(e) => handleSetState("address", e.target.value)}
              />
            </Grid>

            <Grid item xs={3}>
              <Typography>
                <b>Deskripsi</b>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                multiline
                rows={10}
                value={state.description}
                style={{ paddingTop: 10 }}
                onChange={(e) => handleSetState("description", e.target.value)}
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                className={classes.inputStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.flexCenter}>
                <Typography style={{ width: "30%" }}>
                  <b>Menerima Dropship Marketplace?</b>
                </Typography>
                <Switch
                  checked={state.is_dropship === true}
                  onChange={() =>
                    setState({
                      ...state,
                      is_dropship: state.is_dropship === false ? true : false,
                    })
                  }
                  color="secondary"
                  name="is_dropship"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.flexCenter}>
                <Typography style={{ width: "30%" }}>
                  <b>Menerima COD?</b>
                </Typography>
                <Switch
                  checked={state.is_cod === true}
                  onChange={() =>
                    setState({
                      ...state,
                      is_cod: state.is_cod === false ? true : false,
                    })
                  }
                  color="secondary"
                  name="is_cod"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.flexCenter}>
                <Typography style={{ width: "30%" }}>
                  <b>Tipe Pengiriman</b>
                </Typography>
                <RadioGroup
                  row
                  value={state.shipment_type}
                  onChange={(e) =>
                    setState({
                      ...state,
                      shipment_type: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value="DROP"
                    control={<Radio />}
                    label="Drop"
                  />
                  <FormControlLabel
                    value="PICKUP"
                    control={<Radio />}
                    label="Pickup"
                  />
                </RadioGroup>
              </div>
            </Grid>
            <Grid item xs={3}>
              <Typography>
                <b>Supported Courier</b>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                {allCourier &&
                  allCourier.map((res, index) => (
                    <Grid item key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              state.couriers[
                                state.couriers.indexOf(res.code)
                              ] === res.code
                            }
                            onChange={() =>
                              handleChangeCheckbokCourier(res.code)
                            }
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label={res.name}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>

          <div
            className={classes.flexCenterHorizontal}
            style={{ paddingTop: 20 }}
          >
            <Button
              variant="contained"
              className={classes.buttonGreen}
              onClick={handleSimpan}
            >
              Simpan
            </Button>
          </div>
        </div>
      </Paper>
    </Fragment>
  );
});

export default EditInfo;
