import { useState, useEffect } from "react";

let listeners = [];

let state = {
  data: [],
  categories: [],
  search: "",
  selectedCategory: 0,
};

const setState = (newState) => {
  state = { ...state, ...newState };

  listeners.forEach((listener) => {
    listener(state);
  });
};

const useECourse = (name) => {
  const newListener = useState()[1];

  useEffect(() => {
    listeners.push(newListener);
  }, []);

  return [
    state[name],
    (value) =>
      setState({
        [name]: value,
      }),
  ];
};

export default useECourse;
