import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { allColumnsFieldsSelector, SearchIcon } from "@material-ui/data-grid";
import { Pagination } from "@material-ui/lab";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  GET_CONNECTED_CHANNEL,
  GET_SYNCED_PRODUCT,
} from "services/marketplace.service";

const ProductList = (props) => {
  const { selectedProducts, handleSelectProduct } = props;
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [availableChannels, setAvailableChannels] = React.useState([]);
  const [meta, setMeta] = useState(null);
  const [params, setParams] = useState({
    per_page: 7,
    channel: "all",
  });

  const fetchData = useCallback(
    _.debounce((params) => {
      GET_SYNCED_PRODUCT({
        ...params,
        channel: params.channel !== "all" ? params.channel : "",
      }).then((response) => {
        setProducts(response.data);
        setMeta({ ...response, data: null });
        setLoading(false);
      });
    }, 500),
    []
  );

  useEffect(() => {
    setLoading(true);

    fetchData(params);
  }, [params, fetchData]);

  useEffect(() => {
    GET_CONNECTED_CHANNEL().then(({ data }) => {
      setAvailableChannels(Object.keys(data));
    });
  }, []);

  const handlePageChange = (event, page) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: page,
    }));
  };

  const handleSearch = (event) => {
    const search = event.target.value;

    setParams((prevParams) => ({
      ...prevParams,
      page: 1,
      name: search,
    }));
  };

  return (
    <form noValidate autoComplete="off">
      <Box display="flex">
        <Box flexGrow={1} mr={2}>
          <FormControl variant="standard" fullWidth>
            <OutlinedInput
              placeholder="Cari Produk"
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Box display="flex">
          <TextField
            select
            label="Marketplace"
            variant="outlined"
            value={params.channel}
            onChange={(event) =>
              setParams((state) => ({ ...state, channel: event.target.value }))
            }
            style={{ width: 200 }}
          >
            <MenuItem value="all">Semua</MenuItem>
            {availableChannels.map((channel) => (
              <MenuItem key={channel} value={channel}>
                {channel[0].toUpperCase() + channel.slice(1)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      {loading ? (
        <Box
          display="flex"
          height={200}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : products.length > 0 ? (
        <List>
          {products.map((item) => (
            <ListItem
              dense
              button
              onClick={
                !item.inventory ? () => handleSelectProduct(item.id) : null
              }
            >
              <ListItemAvatar>
                <Avatar
                  src={item.image_urls.length > 0 ? item.image_urls[0] : null}
                />
              </ListItemAvatar>
              <ListItemText
                primary={item.name}
                secondary={`${
                  item.channel.channel[0].toUpperCase() +
                  item.channel.channel.slice(1)
                } - ${item.skus.length} varian`}
              />
              <ListItemIcon>
                {!item.inventory ? (
                  <Checkbox
                    edge="start"
                    checked={selectedProducts.indexOf(item.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                ) : (
                  "Sudah Terhubung"
                )}
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          height={200}
          alignItems="center"
        >
          Tidak ada data
        </Box>
      )}
      {meta && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <div>
            {selectedProducts.length > 0 &&
              `${selectedProducts.length} dipilih`}
          </div>
          <Pagination
            page={meta.current_page}
            count={meta.last_page}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </form>
  );
};

export default ProductList;
