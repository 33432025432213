import { objToParam } from "utils/params";
import { axiosInstance } from "../config/fetchAxios";
import axios from "axios";

// Authorize
// ===========

export const login = async (data) => {
  const response = axiosInstance.post(`/api/v1/auth/supplier/login`, data);
  return response;
};

export const getSupplierCategories = async (slug) => {
  const response = axiosInstance.get(`/api/v1/suppliers/${slug}/categories`);
  return response;
};

export const getAllCategories = async () => {
  const response = axiosInstance.get(`/api/v1/categories`);
  return response;
};

export const getReportSummary = async () => {
  const response = axiosInstance.get(`api/v1/reports/summary`);
  return response;
};

export const getReportSummaryFilter = async (toDates, endDates) => {
  const response = axiosInstance.get(
    `api/v1/reports/summary/between=${toDates},${endDates}`
  );
  return response;
};

export const getChartDaily = async () => {
  const response = axiosInstance.get(`api/v1/chart/daily`);
  return response;
};

export const getChartMonthly = async () => {
  const response = axiosInstance.get(`api/v1/chart/monthly`);
  return response;
};

export const getChartSummaryGrafik = async () => {
  const response = axiosInstance.get(`api/v1/reports/summary`);
  return response;
};

export const getSupplierProducts = async (slug, filter) => {
  if (typeof filter == "object") {
    filter = "?" + objToParam(filter);
  }

  const response = axiosInstance.get(
    `/api/v1/suppliers/${slug}/products${filter || ""}`
  );
  return response;
};

export const getProducts = async (params) => {
  const slug = JSON.parse(localStorage.getItem("DATA")).slug;

  const response = axiosInstance.get(`/api/v1/suppliers/${slug}/products`, {
    params,
  });

  return response;
};

export const deleteExpenses = async (id) => {
  const response = axiosInstance.delete(`/api/v1/expenses/${id}`);
  return response;
};

export const updateExpenses = async (id, data) => {
  const response = axiosInstance.put(`/api/v1/expenses/${id}`, data);
  return response;
};

export const deleteProducts = async (slug) => {
  const response = axiosInstance.delete(`/api/v1/products/${slug}`);
  return response;
};

export const getAllOrders = async (slug, filter) => {
  const response = axiosInstance.get(
    `/api/v1/suppliers/${slug}/orders${filter || "?page=1"}&include=cart.customer.village.district.city.province`
  );
  return response;
};

export const getAllOrdersCustom = async (filter) => {
  const response = axiosInstance.get(`/api/v2/product-custom`, {
    params: filter,
  });
  return response;
};

export const inputPriceOrderCustom = async (data) => {
  const response = axiosInstance.put(`/api/v2/product-custom/input`, data);
  return response;
};

export const cancelOrderCustom = async (data) => {
  const response = axiosInstance.put(`/api/v2/product-custom/cancel`, data);
  return response;
};

export const downloadLabel = async (code) => {
  const response = axiosInstance.get(`/api/v1/orders/downloadLabel/${code}`, {
    responseType: "arraybuffer",
  });
  return response;
};

export const addProduct = async (data) => {
  const response = axiosInstance.post(`/api/v1/products`, data);
  return response;
};

export const deleteEProduct = async (slug) => {
  const response = axiosInstance.delete(`/api/v2/my-eproducts/${slug}`);
  return response;
};

export const getEProductCategory = async () => {
  const response = axiosInstance.get(`/api/v2/e-categories`);
  return response;
};

export const getEProduct = async (params) => {
  const response = axiosInstance.get(`/api/v2/my-eproducts`, {
    params: params,
  });
  return response;
};

export const addEProduct = async (data) => {
  const response = axiosInstance.post(`/api/v2/my-eproducts`, data);
  return response;
};

export const editEProduct = async (data, slug) => {
  const response = axiosInstance.post(`/api/v2/my-eproducts/${slug}`, data);
  return response;
};

export const editProduct = async (slug, data, isPost) => {
  const response = axiosInstance[isPost ? "post" : "patch"](
    `/api/v1/products/${slug}`,
    data
  );
  return response;
};

export const upProduct = async (slug) => {
  const response = axiosInstance.post(`/api/v2/products/${slug}/up`, {});
  return response;
};

export const editProductCustom = async (slug, data) => {
  const response = axiosInstance.put(`/api/v1/products/${slug}`, data);
  return response;
};

export const editSkus = async (slug, code, data) => {
  const response = axiosInstance.patch(
    `/api/v1/products/${slug}/skus/${code}`,
    data
  );
  return response;
};

export const addSkus = async (slug, data) => {
  const response = axiosInstance.post(`/api/v1/products/${slug}/skus`, data);
  return response;
};

export const deleteSkus = async (slug, code) => {
  const response = axiosInstance.delete(
    `/api/v1/products/${slug}/skus/${code}`
  );
  return response;
};

export const updateOrder = async (slug, code, data) => {
  const response = axiosInstance.post(
    `/api/v1/suppliers/${slug}/orders/${code}`,
    data
  );
  return response;
};

export const uploadExcelProduct = async (data) => {
  const response = axiosInstance.post(`/api/v1/import_product`, data);
  return response;
};

export const exportExcelProduct = async () => {
  const response = axiosInstance.get(`/api/v1/export_product`);
  return response;
};

export const getDetailSupplier = async (slug) => {
  const response = axiosInstance.get(`/api/v1/suppliers/${slug}`);
  return response;
};

export const getSubdistrict = async (city_id) => {
  const response = axiosInstance.get(
    `/api/v1/support/location/subdistricts?city_id=${city_id}`
  );
  return response;
};

export const getAllCity = async () => {
  const response = axiosInstance.get(`/api/v1/support/location/cities`);
  return response;
};

export const getAllCourier = async () => {
  const response = axiosInstance.get(`/api/v1/support/shipping/couriers`);
  return response;
};

export const editSupplier = async (slug, data) => {
  const response = axiosInstance.post(`/api/v1/suppliers/${slug}`, data);
  return response;
};

export const createSupplier = async (data) => {
  const response = axiosInstance.post(`/api/v1/suppliers`, data);
  return response;
};
export const signupSupplier = async (data) => {
  const response = axiosInstance.post(`/api/v1/supplier/signup`, data);
  return response;
};

export const syncSupplierCourier = async (slug, data) => {
  const response = axiosInstance.post(
    `/api/v1/suppliers/${slug}/couriers`,
    data
  );
  return response;
};

export const getSupplierNotification = async (slug) => {
  const response = axiosInstance.get(`/api/v1/suppliers/${slug}/notifications`);
  return response;
};

export const readNotification = async (slug, id) => {
  const response = axiosInstance.patch(
    `/api/v1/suppliers/${slug}/notifications/${id}/read`
  );
  return response;
};

export const getExpenses = async () => {
  const response = axiosInstance.get(`/api/v1/expenses`);
  return response;
};

export const createExpenses = async (data) => {
  const response = axiosInstance.post("/api/v1/expenses", data);

  return response;
};

export const getTracking = async (body) => {
  const response = axiosInstance.post(
    `/api/v1/support/shipping/tracking`,
    body
  );
  return response;
};

export const exportOrderXLS = (params) => {
  const response = axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/export_orders`,
    {
      params: params,
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ` + localStorage.getItem("TOKEN"),
      },
    }
  );

  return response;
};

export const getProvinces = async () => {
  const response = axiosInstance.get(`/api/v2/support/location/provinces`);
  return response;
};

export const getCitiesByProvinceId = async (provinceId) => {
  const response = axiosInstance.get(
    `/api/v2/support/location/cities/?filter[province.id]=${provinceId}`
  );
  return response;
};

export const getDistrictsByCityId = async (cityId) => {
  const response = axiosInstance.get(
    `/api/v2/support/location/districts/?filter[city.id]=${cityId}`
  );
  return response;
};

export const getSubDistrictsByDisctrictId = async (districtId) => {
  const response = axiosInstance.get(
    `/api/v2/support/location/subdistricts/?filter[district.id]=${districtId}`
  );
  return response;
};

export const uploadProductsTestimony = async (slug, data) => {
  const response = axiosInstance.post(
    `/api/v2/product_testimony/${slug}`,
    data
  );
  return response;
};

export const getProductTestimony = async (slug) => {
  const response = axiosInstance.get(`/api/v2/product_testimony/${slug}`);
  return response;
};

export const deleteProductTestimony = async (slug, data) => {
  const response = axiosInstance.post(
    `/api/v2/product_testimony/${slug}/delete`,
    data
  );
  return response;
};

export const getPopupSupplier = async (body) => {
  const response = axiosInstance.get(`/api/v1/options/popup-supplier`);
  return response;
};

// reset password API
export const ReqTokenResetPasswordSupplier = async (data) => {
  const response = axiosInstance.post(
    `/api/v1/auth/supplier/passwordreset/request`,
    data
  );
  return response;
};

export const ResetPasswordSupplier = async (data) => {
  const response = axiosInstance.post(
    `/api/v1/auth/supplier/passwordreset`,
    data
  );
  return response;
};