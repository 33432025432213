import { InputAdornment, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormAddPromoWrap } from "./style";
import iconAdd from "../../assets/icons/ic-add.svg";
import { ActionButton, ButtonCustom } from "components";
import { ModalTableProduct } from "containers";
import _ from "lodash";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { formatRupiah } from "utils/currencyFormat";
import { FormTablePromoWrap } from "components/FormTablePromo/style";
import swal from "sweetalert";
import { CREATE_DISCOUNT, GET_ALL_DISCOUNT } from "services/discount.service";

const FormPotonganHarga = () => {
  const [isOpenModalTable, setIsOpenModalTable] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    GET_ALL_DISCOUNT().then(({ data }) => {
      setItems(data);
    });
  }, []);

  const toggleDiscountType = (product) => {
    setItems((items) => [
      ...items.map((item) => {
        if (item.product_slug === product.slug) {
          item.type = item.type === "PERCENT" ? "FIXED" : "PERCENT";
        }
        return item;
      }),
    ]);
  };

  const changeAmount = (product, amount) => {
    setItems((items) => [
      ...items.map((item) => {
        if (item.product_slug === product.slug) {
          item.amount = amount;
        }
        return item;
      }),
    ]);
  };

  const handleDateStartChange = (product, date) => {
    date = moment(date).format("YYYY-MM-DD HH:MM:SS");
    if (date === "Invalid date") date = null;
    setItems((items) => [
      ...items.map((item) => {
        if (item.product_slug === product.slug) {
          item.start_date = date;
        }
        return item;
      }),
    ]);
  };

  const handleDateEndChange = (product, date) => {
    date = moment(date).format("YYYY-MM-DD HH:MM:SS");
    if (date === "Invalid date") date = null;
    setItems((items) => [
      ...items.map((item) => {
        if (item.product_slug === product.slug) {
          item.end_date = date;
        }
        return item;
      }),
    ]);
  };

  const deleteProduct = (product) => {
    setItems((items) => [
      ...items.filter((item) => item.product_slug !== product.slug),
    ]);
  };

  const _renderForm = () => {
    return (
      <>
        <div style={{ padding: 24 }}>
          <h3>1. Pilih Produk</h3>
          <p className="desc">
            Tambahkan Produk ke Promo ini, lalu atur Potongan Harganya
          </p>
          <ButtonCustom
            icon={iconAdd}
            onClick={() => setIsOpenModalTable(!isOpenModalTable)}
            color="red"
            withBorder={true}
            style={{ marginLeft: 24, marginTop: 16 }}
          >
            Tambah Produk
          </ButtonCustom>
        </div>
        {/* PRODUCTS TABLE */}
        <FormTablePromoWrap>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nama Produk</TableCell>
                  <TableCell>Jumlah Potongan Harga</TableCell>
                  <TableCell>Harga Awal</TableCell>
                  <TableCell>Rentang Tanggal</TableCell>
                  <TableCell>Aksi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(
                  ({ product, type, amount, start_date, end_date }) => (
                    <TableRow key={product.slug}>
                      <TableCell component="th" scope="row" width="15%">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={product.image_url}
                            alt={product.name}
                            width="40"
                            style={{ padding: 4 }}
                          />
                          {product.name}
                        </div>
                      </TableCell>
                      <TableCell width="20%">
                        <TextField
                          variant="outlined"
                          type="number"
                          style={{ height: 38, width: 200 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className="switchPercent"
                                onClick={() => toggleDiscountType(product)}
                              >
                                {type === "PERCENT" ? "%" : "Rp"}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            changeAmount(product, e.target.value)
                          }
                          defaultValue={amount ?? 0}
                        />
                      </TableCell>
                      <TableCell width="10%">
                        Rp.{" "}
                        {formatRupiah(
                          product.skus[0].price.consumer -
                            product.skus[0].price.commission
                        )}
                      </TableCell>
                      <TableCell width="10%">
                        <TextField
                          id="date-local"
                          label="Tanggal Mulai"
                          type="datetime-local"
                          defaultValue={
                            start_date
                              ? moment(start_date).format("YYYY-MM-DDTHH:mm")
                              : null
                          }
                          onChange={(e) =>
                            handleDateStartChange(product, e.target.value)
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <br />
                        <br />
                        <TextField
                          id="date-local"
                          label="Tanggal Selesai"
                          type="datetime-local"
                          defaultValue={
                            end_date
                              ? moment(end_date).format("YYYY-MM-DDTHH:mm")
                              : null
                          }
                          onChange={(e) =>
                            handleDateEndChange(product, e.target.value)
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>
                      <TableCell width="20%">
                        <div style={{ marginTop: 8 }}>
                          <ActionButton
                            circleBorder
                            type="delete"
                            onClick={() => deleteProduct(product)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </FormTablePromoWrap>
        {/* END PRODUCTS TABLE */}
        <div
          style={{
            display: "flex",
            position: "relative",
            float: "right",
            marginTop: 20,
          }}
        >
          <ButtonCustom
            withBorder
            color={"#929292"}
            onClick={() => window.history.back()}
          >
            Batal
          </ButtonCustom>
          <ButtonCustom
            bgColor="red"
            style={{ marginLeft: 16 }}
            onClick={() => handleSubmit()}
          >
            Simpan Promo
          </ButtonCustom>
        </div>
      </>
    );
  };

  const handleSubmit = async () => {
    await CREATE_DISCOUNT({
      data: [...items].map((item) => {
        return _.pick(item, [
          "product_slug",
          "type",
          "amount",
          "start_date",
          "end_date",
        ]);
      }),
    })
      .then(() => {
        swal("Success", "Berhasil menyimpan potongan harga", "success");
      })
      .catch((error) => {
        console.group("Gagal menyimpan potongan harga");
        console.error("Request", JSON.parse(error.config.data));
        console.error("Response", error.response.data);
        console.groupEnd();
        swal("Error", error.response.data.message, "error");
      });
  };

  const onCloseModal = () => {
    setIsOpenModalTable(!isOpenModalTable);
  };

  const handleSubmitAddProduct = async (products) => {
    onCloseModal();
    setItems((items) => [
      ...items.filter((item) =>
        products.some((product) => product.slug === item.product_slug)
      ),
      ...products
        .filter((product) =>
          items.every((item) => product.slug !== item.product_slug)
        )
        .map((product) => ({
          product_slug: product.slug,
          type: "FIXED",
          amount: 0,
          product: product,
        })),
    ]);
  };

  return (
    <FormAddPromoWrap>
      <div>{_renderForm()}</div>
      <ModalTableProduct
        isOpen={isOpenModalTable}
        onClose={onCloseModal}
        selectedProducts={items.map((item) => item.product)}
        handleSubmit={handleSubmitAddProduct}
      />
    </FormAddPromoWrap>
  );
};

export default FormPotonganHarga;
