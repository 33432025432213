import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Delete from "@material-ui/icons/Delete";
import React from "react";

const SkuList = (props) => {
  const { product, inventory, onProductChange, skuLink, onSkuLinkChange } =
    props;

  const connectSku = (skuId, inventoryId) => {
    onSkuLinkChange((linkSkus) => ({
      ...linkSkus,
      [skuId]: inventoryId,
    }));
  };

  const getSkuValue = (sku) => {
    const value = skuLink[sku.id];

    return value || "";
  };

  const handleInventoryChange = (sku, inventoryId) => {
    connectSku(sku.id, inventoryId);
  };

  const handleClearSku = () => {
    onSkuLinkChange({});
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Button startIcon={<ArrowBack />} onClick={onProductChange}>
          {inventory.name}
        </Button>
        {Object.keys(skuLink)
          .map((skuId) => skuLink[skuId])
          .filter((item) => Boolean(item)).length > 0 && (
          <Button onClick={handleClearSku} color="secondary">
            <Delete />
          </Button>
        )}
      </Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>SKU (Marketplace)</TableCell>
              <TableCell>Hubungkan ke SKU (Idejualan)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {product.skus.map((sku) => (
              <TableRow>
                <TableCell component="th" scope="row">
                  {sku.sku}
                </TableCell>
                <TableCell width="300px">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    style={{ maxWidth: 300 }}
                  >
                    <InputLabel>Inventori</InputLabel>
                    <Select
                      value={getSkuValue(sku)}
                      label="Varian"
                      onChange={(event) =>
                        handleInventoryChange(sku, event.target.value)
                      }
                    >
                      <MenuItem value="">
                        <em>Tidak ada</em>
                      </MenuItem>
                      {inventory.skus.map((sku) => (
                        <MenuItem value={sku.id}>
                          {sku.properties.material}, {sku.properties.color},{" "}
                          {sku.properties.size}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SkuList;
