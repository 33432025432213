import {
  Grid,
  TextField,
  FormControlLabel,
  Radio,
  Switch,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ButtonCustom } from "components";
import moment from "moment";
import swal from "sweetalert";
import {
  FormAddPromoWrap,
  InputSaldo,
  RadioInputStyle,
  SwitchStyle,
} from "./style";
import NumberFormat from "react-number-format";
import {
  GET_VOUCHER_ID,
  POST_VOUCHER,
  EDIT_VOUCHER,
} from "services/voucher.service";
import { Alert } from "@material-ui/lab";

const FormVoucher = (props) => {
  const id = props.id;
  const isEdit = id !== undefined;
  const [dataForm, setDataForm] = useState({
    name: "",
    startDate: moment().format("YYYY-MM-DDTHH:mm"),
    endDate: moment().add(3, "days").format("YYYY-MM-DDTHH:mm"),
    minimumPembelian: "",
    typeVoucher: "discount",
    totalDiscount: "",
    totalCashback: "",
    totalPotongan: "",
    quotaClaim: "",
    quotaClaimUser: "",
    viaDompet: false,
    isPercent: false,
    percent: "",
  });
  const [alert, setAlert] = useState(<></>);

  const handleChangeInput = (formName, value) => {
    setDataForm({ ...dataForm, [formName]: value });
  };

  useEffect(() => {
    if (dataForm.typeVoucher === "cashback") {
      dataForm.totalCashback === ""
        ? setAlert(
            <Alert severity="error">
              Masukan Jumlah Cashback, tidak boleh kosong
            </Alert>
          )
        : setAlert(<></>);
    } else if (dataForm.typeVoucher === "ongkir") {
      dataForm.totalPotongan === ""
        ? setAlert(
            <Alert severity="error">
              Masukan Jumlah Potongan Ongkir, tidak boleh kosong
            </Alert>
          )
        : setAlert(<></>);
    } else if (dataForm.typeVoucher === "discount") {
      dataForm.totalDiscount === ""
        ? setAlert(
            <Alert severity="error">
              Masukan Jumlah Potongan Harga, tidak boleh kosong
            </Alert>
          )
        : setAlert(<></>);
    }
  }, [dataForm]);

  useEffect(() => {
    if (isEdit) {
      GET_VOUCHER_ID(id).then(({ data }) => {
        let totalCashback = 0;
        let totalPotongan = 0;
        let totalDiscount = 0;
        if (data.type === "cashback") {
          totalCashback = data.amount;
        } else if (data.type === "ongkir") {
          totalPotongan = data.amount;
        } else if (data.type === "discount") {
          totalDiscount = data.amount;
        }
        setDataForm({
          name: data.name,
          startDate: moment().format("YYYY-MM-DDTHH:mm"),
          endDate: moment().add(3, "days").format("YYYY-MM-DDTHH:mm"),
          minimumPembelian: data.minimum_payments,
          typeVoucher: data.type,
          totalDiscount,
          totalCashback,
          totalPotongan,
          quotaClaim: data.quotas,
          quotaClaimUser: data.max_claims,
          viaDompet: data.dompetjualan,
          isPercent: data.is_percent,
          percent: data.percent,
        });
      });
    }
  }, [id, isEdit]);

  const _renderForm = () => {
    return (
      <>
        <div style={{ padding: 24 }}>
          <h3>1. Informasi Dasar</h3>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Nama Voucher
              <div>
                <span>* tidak ditampilkan di Reseller</span>
              </div>
            </Grid>
            <Grid item xs={8}>
              <TextField
                placeholder={"Nama voucher"}
                variant="outlined"
                size="small"
                fullWidth
                value={dataForm.name}
                onChange={(e) => handleChangeInput("name", e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 14 }}>
            <Grid item xs={3} className="label">
              Periode Voucher
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="datetime-local"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                variant="outlined"
                size="small"
                defaultValue={dataForm.startDate}
                onChange={(e) => handleChangeInput("startDate", e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="datetime-local"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                variant="outlined"
                size="small"
                defaultValue={dataForm.endDate}
                onChange={(e) => handleChangeInput("endDate", e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Minimum Pembelian
            </Grid>
            <Grid item xs={8}>
              <InputSaldo>
                <NumberFormat
                  onValueChange={(values) => {
                    handleChangeInput("minimumPembelian", values.value);
                  }}
                  thousandSeparator="."
                  decimalSeparator=","
                  isNumericString
                  value={dataForm.minimumPembelian}
                  size="small"
                  customInput={TextField}
                  name="saldo"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Masukan Nominal"
                  InputProps={{
                    startAdornment: <div className="before">Rp</div>,
                  }}
                />
              </InputSaldo>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Tipe Voucher
            </Grid>
            <Grid item xs={8}>
              <RadioInputStyle>
                <FormControlLabel
                  value="cashback"
                  control={
                    <Radio
                      checked={dataForm.typeVoucher === "discount"}
                      onChange={(e) =>
                        handleChangeInput("typeVoucher", e.target.value)
                      }
                      value="discount"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                    />
                  }
                  label="Potongan Harga"
                />
              </RadioInputStyle>
              <Grid item xs={12}>
                <InputSaldo>
                  <NumberFormat
                    onValueChange={(values) => {
                      handleChangeInput("totalDiscount", values.value);
                    }}
                    value={dataForm.totalDiscount}
                    thousandSeparator="."
                    decimalSeparator=","
                    isNumericString
                    size="small"
                    customInput={TextField}
                    name="saldo"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Jumlah Potongan Harga"
                    InputProps={{
                      startAdornment: <div className="before">Rp</div>,
                    }}
                  />
                </InputSaldo>
              </Grid>
              <RadioInputStyle>
                <FormControlLabel
                  value="cashback"
                  control={
                    <Radio
                      checked={dataForm.typeVoucher === "cashback"}
                      onChange={(e) =>
                        handleChangeInput("typeVoucher", e.target.value)
                      }
                      value="cashback"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                    />
                  }
                  label="Cashback Coin"
                />
              </RadioInputStyle>
              <Grid item xs={12}>
                <InputSaldo>
                  <NumberFormat
                    onValueChange={(values) => {
                      handleChangeInput("totalCashback", values.value);
                    }}
                    value={dataForm.totalCashback}
                    thousandSeparator="."
                    decimalSeparator=","
                    isNumericString
                    size="small"
                    customInput={TextField}
                    name="saldo"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Jumlah Cashback"
                    InputProps={{
                      startAdornment: <div className="before">Rp</div>,
                    }}
                  />
                </InputSaldo>
              </Grid>
              <RadioInputStyle>
                <FormControlLabel
                  value="ongkir"
                  control={
                    <Radio
                      checked={dataForm.typeVoucher === "ongkir"}
                      onChange={(e) =>
                        handleChangeInput("typeVoucher", e.target.value)
                      }
                      value="ongkir"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                    />
                  }
                  label="Potongan Ongkir"
                />
              </RadioInputStyle>
              <Grid item xs={12}>
                <InputSaldo>
                  <NumberFormat
                    onValueChange={(values) => {
                      handleChangeInput("totalPotongan", values.value);
                    }}
                    value={dataForm.totalPotongan}
                    thousandSeparator="."
                    decimalSeparator=","
                    isNumericString
                    size="small"
                    customInput={TextField}
                    name="saldo"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Jumlah Potongan Ongkir"
                    InputProps={{
                      startAdornment: <div className="before">Rp</div>,
                    }}
                  />
                </InputSaldo>
                {alert}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Kuota Klaim
            </Grid>
            <Grid item xs={8}>
              <TextField
                placeholder={"Jumlah Klaim Voucher"}
                variant="outlined"
                size="small"
                fullWidth
                value={dataForm.quotaClaim}
                onChange={(e) =>
                  handleChangeInput("quotaClaim", e.target.value)
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Jumlah maks User Klaim
            </Grid>
            <Grid item xs={8}>
              <TextField
                placeholder={"Jumlah maksimal user dapat klaim voucher"}
                variant="outlined"
                size="small"
                fullWidth
                value={dataForm.quotaClaimUser}
                onChange={(e) =>
                  handleChangeInput("quotaClaimUser", e.target.value)
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Beli via DompetJualan
            </Grid>
            <Grid item xs={8}>
              <SwitchStyle>
                <Switch
                  checked={dataForm.viaDompet}
                  onChange={(e) =>
                    handleChangeInput("viaDompet", e.target.checked)
                  }
                  name="checkedA"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </SwitchStyle>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Aktif Persen
            </Grid>
            <Grid item xs={8}>
              <SwitchStyle>
                <Switch
                  checked={dataForm.isPercent}
                  onChange={(e) =>
                    handleChangeInput("isPercent", e.target.checked)
                  }
                  name="checkedA"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </SwitchStyle>
            </Grid>
          </Grid>
          {dataForm.isPercent && (
            <Grid container spacing={2}>
              <Grid item xs={3} className="label">
                Persen
              </Grid>
              <Grid item xs={8}>
                <InputSaldo>
                  <NumberFormat
                    onValueChange={(values) => {
                      handleChangeInput("percent", values.value);
                    }}
                    thousandSeparator="."
                    decimalSeparator=","
                    isNumericString
                    value={dataForm.percent}
                    size="small"
                    customInput={TextField}
                    name="percent"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Masukan Persen"
                    InputProps={{
                      startAdornment: <div className="before">%</div>,
                    }}
                    disabled={!dataForm.isPercent}
                  />
                </InputSaldo>
              </Grid>
            </Grid>
          )}
        </div>
        {/* END PRODUCTS TABLE */}
        <div
          style={{
            display: "flex",
            position: "relative",
            float: "right",
            marginTop: 20,
          }}
        >
          <ButtonCustom
            withBorder
            color={"#929292"}
            onClick={() => window.history.back()}
          >
            Batal
          </ButtonCustom>
          <ButtonCustom
            bgColor="red"
            style={{ marginLeft: 16 }}
            onClick={() => handleSubmit()}
          >
            Simpan Promo
          </ButtonCustom>
        </div>
      </>
    );
  };

  const handleSubmit = async () => {
    if (dataForm.name === "") {
      return swal("Error", "Silahkan isi nama", "error");
    }

    const body = {
      name: dataForm.name,
      period_type: "limited",
      start_date: moment(dataForm.startDate).format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment(dataForm.endDate).format("YYYY-MM-DD HH:mm:ss"),
      minimum_payments: dataForm.minimumPembelian,
      type: dataForm.typeVoucher,
      amount:
        dataForm.typeVoucher === "cashback"
          ? dataForm.totalCashback
          : dataForm.typeVoucher === "ongkir"
          ? dataForm.totalPotongan
          : dataForm.totalDiscount,
      max_claims: dataForm.quotaClaimUser,
      quotas: dataForm.quotaClaim,
      dompetjualan: dataForm.viaDompet ? 1 : 0,
      is_percent: dataForm.isPercent ? 1 : 0,
      percent: dataForm.percent,
    };

    if (!isEdit) {
      await POST_VOUCHER(body)
        .then(() => {
          swal("Success", "Berhasil menambahkan voucher", "success");
          props.history.goBack();
        })
        .catch((error) => {
          console.group("Gagal menambah voucher");
          console.error("Request", JSON.parse(error.config.data));
          console.error("Response", error.response.data);
          console.groupEnd();
          swal("Error", error.response.data.message, "error");
        });
    } else {
      await EDIT_VOUCHER(id, body)
        .then(() => {
          swal("Success", "Berhasil merubah voucher", "success");
          props.history.goBack();
        })
        .catch((error) => {
          console.group("Gagal merubah voucher");
          console.error("Request", JSON.parse(error.config.data));
          console.error("Response", error.response.data);
          console.groupEnd();
          swal("Error", error.response.data.message, "error");
        });
    }
  };

  return (
    <FormAddPromoWrap>
      <div>{_renderForm()}</div>
    </FormAddPromoWrap>
  );
};

export default FormVoucher;
