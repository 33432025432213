import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { DataTableBundlingWithTabs, WidgetProfile } from "containers";
import Notification from "../../home/components/notifikasi";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";

const BundlingPage = (props) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={9} style={{ paddingRight: 20 }}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography
                variant="h6"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBack
                  style={{ paddingRight: 10, cursor: "pointer" }}
                  onClick={() => window.history.back()}
                />
                <b>Bundling</b>
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}>
              <Link to="/promo/bundling/new">
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: 20 }}
                >
                  + Tambah Bundling
                </Button>
              </Link>
            </Grid>
          </Grid>
          <DataTableBundlingWithTabs {...props} />
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <WidgetProfile {...props} />
            </Grid>
            <Grid item xs={12}>
              <Notification {...props} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default BundlingPage;
