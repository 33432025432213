import { axiosInstance } from "../config/fetchAxios";

const defaultUrl = `/api/v2/my-bundlings`;

export const GET_ACCOUNT_BUNDLING = async () => {
  const url = defaultUrl;
  const data = axiosInstance.get(url);
  return data;
};

export const GET_ACCOUNT_BUNDLING_ID = async (id) => {
  const url = `${defaultUrl}?filter[id]=${id}&include=items.product.skus`;
  const data = axiosInstance.get(url);
  return data;
};

export const CREATE_BUNDLING = (body) => {
  const url = defaultUrl;
  const response = axiosInstance.post(url, body);
  return response;
};

export const DELETE_BUNDLING = (id) => {
  const url = `${defaultUrl}/${id}`;
  const response = axiosInstance.delete(url);
  return response;
};

export const EDIT_BUNDLING = (id, body) => {
  const url = `${defaultUrl}/${id}`;
  const response = axiosInstance.put(url, body);
  return response;
};
